import { Offer, OfferSetState, OfferType} from "../offerType";
import offerApi from "../../api/offer";
import {
    OFFER_LIST_SUCCESS,
    OFFER_LIST_FAILED,
    OFFER_LIST_PROCESS,

    OFFER_TYPE_SUCCESS,
    OFFER_TYPE_FAILED,
    OFFER_TYPE_PROCESS,

    OFFER_READ_SUCCESS,
    OFFER_READ_FAILED,
    OFFER_READ_PROCESS
    
} from "./types";
import {clearReduxStateInternal} from "../authentication/actions";

export const listOffer = () => (dispatch: any) => {
    offerApi.list((status: number, data: Offer[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: OFFER_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: OFFER_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка'
            });
        }
    });

    return dispatch({
        type: OFFER_LIST_PROCESS
    });
}
export const offerTypeList = () => (dispatch: any) => {
    offerApi.getOfferTypes((status: number, data: OfferType[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: OFFER_TYPE_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: OFFER_TYPE_FAILED,
                errorMessage: data || 'Ошибка получения списка'
            });
        }
    });

    return dispatch({
        type: OFFER_LIST_PROCESS
    });
}
export const newOffer = (offer:any) => (dispatch: any) => {
    offerApi.newOffer(offer, (status: number, data: Offer[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: OFFER_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: OFFER_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка'
            });
        }
    });

    return dispatch({
        type: OFFER_LIST_PROCESS
    });
}
export const setOfferState = (offerSetState: OfferSetState) => (dispatch: any) => {
    offerApi.setOfferState(offerSetState, (status: number, data: Offer[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: OFFER_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: OFFER_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка'
            });
        }
    });

    return dispatch({
        type: OFFER_LIST_PROCESS
    });
}
export const setReadOffer = (type: number, objectId: number) => (dispatch: any) => {
    offerApi.setReadOffer(type, objectId, (status: number, data: any | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: OFFER_READ_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: OFFER_READ_FAILED,
                errorMessage: data || 'Ошибка получения списка'
            });
        }
    });

    return dispatch({
        type: OFFER_READ_PROCESS
    });
}

