import axios from "axios";
import {CurrentConfig} from './config';

const ratingApi = {
    listCamper: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/rating/camper`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    listConsaler: (forParty: number, callback: Function) => { 
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/rating/list?forParty=${forParty}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}

export default ratingApi;