export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAILED = "TASK_LIST_FAILED";
export const TASK_LIST_PROCESS = "TASK_LIST_PROCESS";

export const TASK_CATEGORIES_SUCCESS = "TASK_CATEGORIES_SUCCESS";
export const TASK_CATEGORIES_FAILED = "TASK_CATEGORIES_FAILED";
export const TASK_CATEGORIES_PROCESS = "TASK_CATEGORIES_PROCESS";

export const TASK_SUCCESS = "TASK_SUCCESS";
export const TASK_FAILED = "TASK_FAILED";
export const TASK_PROCESS = "TASK_PROCESS";

export const TASK_PHOTO_REMOVE = "TASK_PHOTO_REMOVE";
export const TASK_PHOTO_SUCCESS = "TASK_PHOTO_SUCCESS";
export const TASK_PHOTO_FAILED = "TASK_PHOTO_FAILED";
export const TASK_PHOTO_PROCESS = "TASK_PHOTO_PROCESS";

export const TASK_OFFER_SUCCESS = "TASK_OFFER_SUCCESS";
export const TASK_OFFER_FAILED = "TASK_OFFER_FAILED";
export const TASK_OFFER_PROCESS = "TASK_OFFER_PROCESS";

export const TASK_SELECT = "TASK_SELECT";