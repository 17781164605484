import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Form, Icon, Modal } from 'semantic-ui-react'
//import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './UserManual.css';
import * as authActions from "../../store/authentication/actions";
import { ManualCommonType, ManualShortType, ManualStore } from "../../store/manualType";
import * as manualActions from "./../../store/manual/actions"
import GuidePDF from './../../docs/guide.pdf';
import QuickGuidePDF from './../../docs/quickguide.pdf';

import { Document, Page, pdfjs } from 'react-pdf';
import { CurrentConfig } from '../../api/config';
import { AuthStore, User } from '../../store/baseType';


/*function importAll(r: any) {
    return r.keys().map(r);
}*/

type UserManualProps = {
    download: Function,
    user: User
}

type UserManualState = {
    guidePage: number;
    guidePageCount: number;
    guideModalOpened: boolean,

    quickGuidePage: number;
    quickGuidPageCount: number;
    quickGuidModalOpened: boolean,
    zoomScale: number;
}

class UserManual extends React.Component<UserManualProps, UserManualState> {
    config = CurrentConfig.get();
    //guid = importAll(require.context('./../../docs/guide/', false, /\.(png|jpe?g|svg)$/));
    //quickGuid = importAll(require.context('./../../docs/quickGuide/', false, /\.(png|jpe?g|svg)$/));

    constructor(props: any) {
        super(props);
        this.state = {
            guidePage: 1,
            guideModalOpened: false,
            quickGuidModalOpened: false,
            quickGuidePage: 1,
            guidePageCount: 62,
            quickGuidPageCount: 1,
            zoomScale: 1
        };
    }

    downloadManual(type: number) {
        let manual: ManualStore = {
            manualType: type,
        };

        this.props.download(manual.manualType);
    }

    setGuidePage(page: number) {
        if (this.state.guidePageCount >= page && page >= 1) {
            this.setState({ guidePage: page });
        }
    }

    addZoomScale(scale: number) {

        scale = this.state.zoomScale + scale;
        
        if (scale > 2)
            scale = 2;
        else if (scale < 0.3)
            scale = 0.3;

        this.setState({ zoomScale: scale });
    }

    setQuickGuidePage(page: number) {
        if (this.state.quickGuidPageCount >= page && page >= 1) {
            this.setState({ quickGuidePage: page });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;   
    }

    render() {
        return (
            <div className={"userManual-block"}>
                <Modal
                    id="guideModal"
                    closeIcon
                    size="large"
                    open={this.state.guideModalOpened}
                    onClose={() => { this.setState({ ...this.state, guideModalOpened: false }) }}
                    onOpen={() => { this.setState({ ...this.state, guideModalOpened: true }) }}
                    trigger={<Button className={"manual-btn"} color={"violet"} fluid>Общее руководство</Button>}>

                    <Modal.Header id="contact-header" className="modal-backgroud">
                        <div className="pager">
                            <div className={"pager-setter"} onClick={() => { this.setGuidePage(this.state.guidePage - 1) }}><Icon name="arrow left" /></div>
                            <label>{this.state.guidePage} / {this.state.guidePageCount}</label>
                            <div className={"pager-setter"} onClick={() => { this.setGuidePage(this.state.guidePage + 1) }}><Icon name="arrow right" /></div>
                            <div className={"pager-setter"} onClick={() => { this.addZoomScale(0.2) }}><Icon name="zoom-in" /></div>
                            <div className={"pager-setter"} onClick={() => { this.addZoomScale(-0.2) }}><Icon name="zoom-out" /></div>
                        </div>
                    </Modal.Header>
                    <Modal.Content image scrolling className="modal-backgroud">
                        <Modal.Description>
                            <Form>
                                <div className="document">
                                    <Document file={GuidePDF} onLoadSuccess={(pdf) => { this.setState({ quickGuidPageCount: pdf.numPages})}} onLoadError={console.error}>
                                        <Page pageNumber={this.state.guidePage} scale={this.state.zoomScale}
                                            width={document.getElementById('guideModal')?.offsetWidth}/>
                                    </Document>
                                </div>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="violet" basic onClick={() => this.downloadManual(ManualCommonType)}><Icon name="arrow down" /> Скачать</Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    id="quickGuidModal"
                    closeIcon
                    size="large"
                    open={this.state.quickGuidModalOpened}
                    onClose={() => { this.setState({ ...this.state, quickGuidModalOpened: false }) }}
                    onOpen={() => { this.setState({ ...this.state, quickGuidModalOpened: true }) }}
                    trigger={<Button className={"manual-btn"} color={"violet"} fluid>Краткое руководство</Button>}>

                    <Modal.Header id="contact-header" className="modal-backgroud">
                        <div className="pager">
                            <div className={"pager-setter"} onClick={() => { this.setQuickGuidePage(this.state.quickGuidePage - 1) }}><Icon name="arrow left" /></div>
                            <label>{this.state.quickGuidePage} / {this.state.quickGuidPageCount}</label>
                            <div className={"pager-setter"} onClick={() => { this.setGuidePage(this.state.quickGuidePage + 1) }}><Icon name="arrow right" /></div>
                            <div className={"pager-setter"} onClick={() => { this.addZoomScale(0.2) }}><Icon name="zoom-in" /></div>
                            <div className={"pager-setter"} onClick={() => { this.addZoomScale(-0.2) }}><Icon name="zoom-out" /></div>
                        </div>
                    </Modal.Header>
                    <Modal.Content image scrolling className="modal-backgroud">
                        <Modal.Description>
                            <Form>
                                <div className="document">
                                    <Document file={QuickGuidePDF} onLoadSuccess={(pdf) => { this.setState({ quickGuidPageCount: pdf.numPages }) }} onLoadError={console.error}>
                                        <Page pageNumber={this.state.quickGuidePage} scale={this.state.zoomScale}
                                            width={document.getElementById('quickGuidModal')?.offsetWidth} />
                                    </Document>
                                </div>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="violet" basic onClick={() => this.downloadManual(ManualShortType)}><Icon name="arrow down" /> Скачать</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )

    }
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication
    return {
        registrationRequested: state.authentication.registrationRequested,
        errorMessage: state.authentication.registrationErrorMessage,
        authIoProcess: state.authentication.authIoProcess,
        user: authStore.user,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(manualActions, dispatch),
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManual);
