import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Dropdown, TextArea, Checkbox, Input } from 'semantic-ui-react'
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthStore, User , CurrentBoutOutcome } from "../../store/baseType";
import './FeedBack.css';
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { Feedback, FeedbackTitle, FeedbackStore, Survay, HandlingFilter } from "../../store/feedbackType";
import * as feedbackActions from "./../../store/feedback/actions"
import FeedbackRow from "./FeedbackRow";
import SurveyRow from "./SurveyRow";
import SurveyCard from "./SurveyCard";
import FeedBackCard from "./FeedBackCard";
import './Survey.css';
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';
import { SelectBar } from '../../components/UI/SelectBar/SelectBar';
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import Popup from '../../components/UI/Errors/ErrorNotification';

const categorysTD = [
    { id: 1, name: 'Питание' },
    { id: 2, name: 'Мероприятия' },
    { id: 3, name: 'Вожатые' },
    { id: 4, name: 'Проживание' },
    { id: 5, name: 'Инфраструктура' },
    { id: 6, name: 'Сотрудники лагеря' },
    { id: 7, name: 'Иное' },
    { id: 8, name: 'Техническая поддержка' },
]

const TabStates = {
    appeal: "Обращение",
    quality: "Оценка качества",
}

type FeedBackState = {
    /*Фильтр*/
    searchName: string;
    isSituational: boolean;
    isQrcode: boolean;
    categorys: string[] | any;

    /* видно фильтр */
    filterView: boolean,

    party: boolean,
    currentTab: string,
    openFeedbackModal: boolean,
    feedbackTitle?: string,
    feedbackText?: string,
    feedbackTitleIsEmpty: boolean,
    feedbackTextIsEmpty: boolean

    /* видно карточку */
    cardMode: boolean,
    IsMode: boolean;

    sendButtonDisabled: boolean,
    msgDeniedCount?: number,

    selectedFile: any | null,
    errorUpload: string,
    inputKey: number
}

type FeedBackProp = {
    user: User;

    selectSurvey: Function,
    selectedItem: Survay | undefined,
    backToList: Function,
    getSurvey: Function,
    sendSurvayInProcess: boolean,
    survey: Survay,

    refreshUserInfo: Function,

    getFeedbacksList: Function,
    feedbacksList: Feedback[] | undefined,
    feedbacksListInProcess: boolean,
    errorInSendFeedback: string,

    getFeedbackTitles: Function,
    feedbackTitlesList: FeedbackTitle[] | undefined,
    feedbackTitlesInProcess: boolean,

    selectFeedBack: Function,

    sendFeedback: Function,
    sendFeedbackInProcess: boolean,

    clearRedirectFromSupport: Function,
    isRedirectFromSupport: boolean,

    getSurveyListTitles: Function,
    sendSurvayTitlesInProcess: boolean
    surveyTitlesList: Survay[]
    surveyTitlesQRList: Survay[]

    notificationSyrvayCount?: number
}

class FeedBackComp extends React.Component<FeedBackProp, FeedBackState> {
    interval: any = undefined;
    upload: HTMLInputElement | null = null;
    dropDownValue: string | number | boolean | (string | number | boolean)[] | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            party: false,
            currentTab: TabStates.appeal,
            openFeedbackModal: this.props.isRedirectFromSupport,
            feedbackTitleIsEmpty: false,
            feedbackTextIsEmpty: false,
            cardMode: false,

            feedbackTitle: (this.props.user.admin || this.props.user.counselor) ? '8' : undefined,
            filterView: false,
            searchName: '',
            isSituational: true,
            isQrcode: true,
            categorys: [],
            IsMode: false,
            sendButtonDisabled: true,
            errorUpload: '',
            selectedFile: null,
            inputKey: 1,
        }
    }

    updateDisableButton() {
        this.setState({ sendButtonDisabled: (this.state.msgDeniedCount ?? 1) > 0 })
    }


    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        if (this.props.isRedirectFromSupport) {
            this.props.getFeedbackTitles(true);
        }
        else {
            this.props.getFeedbackTitles();
        }
        if (this.props.isRedirectFromSupport) {
            this.dropDownValue = 8;
            this.setState({ feedbackTitleIsEmpty: false, feedbackTitle: '8' });
        }
        let filter: HandlingFilter = {
            searchName: this.state.searchName,
            isSituational: this.state.isSituational,
            isQrcode: this.state.isQrcode,
            categorys: categorysTD.filter((item) => {
                if (this.state.categorys.indexOf(item.id) != -1) { return item.name }
            }).map((name) => name.name)
        };
        this.props.getFeedbacksList(filter);
        this.props.getSurveyListTitles(filter, 1);
        this.props.getSurveyListTitles(filter, 2);
    }
    
    onAddPhotoClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };
    
    fileChangedHandler = (event: any) => {
        const file = event.target.files[0];
        const maxSizeImage = 10 * 1024 * 1024; // Максимальный размер изображения в байтах (здесь 10 МБ)
        const maxSizeVideo = 100 * 1024 * 1024; // Максимальный размер видео в байтах (здесь 100 МБ)
        let error = '';
        if (file) {
            if (file.type.startsWith('image/') && file.size >= maxSizeImage) {
                error = "Размер файла превысил ограничение (10 МБ) для файлов изображений.";
                this.upload = null;
                this.setState({selectedFile: null, errorUpload: error });
            } else if (file.type.startsWith('video/') && file.size >= maxSizeVideo) {
               error = "Размер файла превысил ограничение (100 МБ) для файлов видео."; 
                this.upload = null;
               this.setState({selectedFile: null, errorUpload: error });
            } else if(file.type.startsWith('image/') || file.type.startsWith('video/')){
                this.setState({ ...this.state, selectedFile: file, errorUpload: error });   
            } else{
                // Файл не соответствует ожидаемому типу или размеру
                error = "Файл не соответствует ожидаемому типу или размеру";   
                this.setState({ selectedFile: null, errorUpload: error });
              
            }
        }
    }

    checkAndSenfFeedback = (event: any) => {
        if (!this.props.sendFeedbackInProcess) {
            if (!this.props.user.admin && !this.props.user.counselor) {
                if (!this.state.feedbackTitle) {
                    this.setState({ ...this.state, feedbackTitleIsEmpty: true });
                }
                else if (this.state.feedbackTitle.toString().trim().length < 1) {
                    this.setState({ ...this.state, feedbackTitleIsEmpty: true });
                }
            }
            if (!this.state.feedbackText) {
                this.setState({ ...this.state, feedbackTextIsEmpty: true });
            }

            if (this.state.feedbackTitle && this.state.feedbackText && this.state.errorUpload ==='') {
                const data = new FormData();
                if (this.state.selectedFile)
                    data.append('file', this.state.selectedFile);
                    data.append('Description', this.state.feedbackText);
                    data.append('TitleId', this.state.feedbackTitle);
                    this.props.sendFeedback(data);
                    //this.props.getFeedbacksList();
                    //this.setState({ ...this.state, openFeedbackModal: false });
                    if (!this.props.user.admin && !this.props.user.counselor) {
                        this.props.clearRedirectFromSupport();
                        this.setState({ 
                            ...this.state,
                            openFeedbackModal: this.props.sendFeedbackInProcess,
                            errorUpload: '',
                            selectedFile: null,  });
                    } else {
                        this.setState({
                            ...this.state,
                            openFeedbackModal: false,
                            errorUpload: '',
                            selectedFile: null, 
                        })
                    }
                    let filter: HandlingFilter = {
                        searchName: this.state.searchName,
                        isSituational: this.state.isSituational,
                        isQrcode: this.state.isQrcode,
                        categorys: categorysTD.filter((item) => {
                            if (this.state.categorys.indexOf(item.id) != -1) { return item.name }
                        }).map((name) => name.name)
                    };
                    //this.props.getFeedbacksList(filter);
            }
        }
    }
    searchItems() {
        let filter: HandlingFilter = {
            searchName: this.state.searchName,
            isSituational: this.state.isSituational,
            isQrcode: this.state.isQrcode,
            categorys: categorysTD.filter((item) => {
                if (this.state.categorys.indexOf(item.id) != -1) { return item.name }
            }).map((name) => name.name)
        };

        this.setState({ ...this.state, filterView: false });
        this.props.getSurveyListTitles(filter, 1);
        this.props.getFeedbacksList(filter);
    }
    searchIthemSurvey() {
        let filter: HandlingFilter = {
            searchName: this.state.searchName,
            isSituational: this.state.isSituational,
            isQrcode: this.state.isQrcode,
            categorys: categorysTD.filter((item) => {
                if (this.state.categorys.indexOf(item.id) != -1) { return item.name }
            }).map((name) => name.name)
        };
        this.props.getSurveyListTitles(filter, 2);
    }

    render() {
        if (this.state.openFeedbackModal === false && (this.props.user.admin || this.props.user.counselor)) {
            return <Redirect to={"/main"} />
        }
        return (
            <div className="feedbackBlock">
                <div className={this.props.sendSurvayTitlesInProcess || this.props.feedbacksListInProcess || this.props.feedbacksListInProcess || this.props.sendFeedbackInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>
                {(!this.state.cardMode) ?
                    <div>
                        <div style={{marginLeft: "25px", marginRight:"25px"} }>
                            <SelectBar
                                elems={[TabStates.appeal, TabStates.quality,]}
                                selectedElem={this.state.currentTab}
                                elemClick={(elem: string) => this.setState({ currentTab: elem })}
                                notificationOfferChangeTaskCount = {this.props.notificationSyrvayCount}
                            />
                        </div>


                        {(this.state.currentTab === TabStates.appeal) ?
                            <div>
                                <div className='searchForm'>
                                    <SearchBar
                                        action={{
                                            icon: 'filter',
                                            onClick: () => {
                                                this.setState({
                                                    ...this.state,
                                                    filterView: !this.state.filterView
                                                })
                                            }
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                            let text = data.value;
                                            this.setState({
                                                ...this.state,
                                                searchName: text
                                            });

                                            if (this.interval) {
                                                clearInterval(this.interval);
                                                this.interval = undefined;
                                            }

                                            this.interval = setInterval(() => {
                                                clearInterval(this.interval);
                                                this.searchItems();
                                                this.interval = undefined;
                                            }, 600);
                                        }}
                                    />
                                    <div id="searchModal" className={this.state.filterView ? "" : "hidden"}>
                                        <table className={"task-table"}>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className={"task-table-header"}>
                                                        Вид
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Checkbox label={"Ситуативные сообщения"} onChange={((event, data) => {
                                                            this.setState({
                                                                ...this.state,
                                                                isSituational: data.checked || false
                                                            });
                                                        })} checked={this.state.isSituational} />
                                                    </td>
                                                    <td>

                                                        <Checkbox label={"Опрос по Qr-коду"} onChange={((event, data) => {
                                                            this.setState({
                                                                ...this.state,
                                                                isQrcode: data.checked || false
                                                            });
                                                        })} checked={this.state.isQrcode} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className={"task-table-header"}>
                                                        Темы
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <Dropdown placeholder='Все'
                                                            fluid
                                                            multiple
                                                            selection
                                                            options={_.map(categorysTD, (item: any) => { return { key: item.name, text: item.name, value: item.id } })}
                                                            onChange={((event, data) => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    categorys: data.value
                                                                });
                                                            })} value={this.state.categorys} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Button color={"violet"}
                                            onClick={() => {
                                                this.searchItems();
                                            }} fluid>Поиск</Button>
                                    </div>
                                </div> 
                                
                                {(this.props.user.currentBoutState === CurrentBoutOutcome.OnBout) 
                                    ?<div id="create-message-button" onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            openFeedbackModal: true,
                                            feedbackText: ""
                                            })
                                        }}>
                                        <div id="create-message-button-text">
                                                <div id="create-message-button-plus-border"></div>
                                                <div id="create-message-button-plus-horisontal-line"></div>
                                                <div id="create-message-button-plus-vertical-line"></div>
                                                Создать обращение
                                        </div>   
                                    </div> 
                                    : ''
                                }

                                <div id="feedback-segments">
                                    <div 
                                        className={this.props.feedbacksListInProcess && this.props.sendSurvayTitlesInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                        <div className="ui text loader">Загрузка...</div>
                                    </div>
                                    {_.map(this.props.feedbacksList, (item: Feedback) => <FeedbackRow feedback={item} onClick={(item: Feedback) => {
                                        this.props.selectFeedBack(item);
                                        this.setState({
                                            ...this.state,
                                            IsMode: true,
                                            cardMode: true
                                        });
                                    }} />)}
                                    {_.map(this.props.surveyTitlesQRList, (item: Survay) => <SurveyRow survey={item} onClick={(item: Survay) => {
                                        this.props.selectSurvey(item);
                                        this.setState({
                                            ...this.state,
                                            IsMode: false,
                                            cardMode: true
                                        });
                                    }} />)}
                                </div>

                                <Modal
                                    closeIcon
                                    open={this.state.openFeedbackModal || this.props.sendFeedbackInProcess  /* || this.props.isRedirectFromSupport*/}
                                    dimmer={"blurring"}
                                    centered={false}
                                    onClose={() => {
                                        if (!this.props.user.admin && !this.props.user.counselor) {
                                            this.props.clearRedirectFromSupport();
                                            this.setState({
                                                ...this.state,
                                                openFeedbackModal: false,
                                                errorUpload: '',
                                                selectedFile: null, 
                                            });
                                        } else {
                                            this.setState({
                                                openFeedbackModal: false,
                                                errorUpload: '',
                                                selectedFile: null,     
                                            });
                                        }
                                    }}
                                >
                                    <Modal.Content>
                                        <Modal.Description>
                                            
                                            <div className={this.props.sendFeedbackInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                                <div className="ui text loader">Загрузка...</div>
                                            </div>
                                            <div className={"modal-header-text"}>
                                                Ситуативное обращение
                                            </div>
                                            <br /><br />
                                            <div className={"modal-subject-text"}>
                                                Тема обращения
                                            </div>
                                            <Dropdown className={"feedback-dropdown"} placeholder='' selection value={this.dropDownValue}
                                                options={(!this.props.user.admin && !this.props.user.counselor) ?
                                                    _.map(this.props.feedbackTitlesList, (item: any) => { return { key: item.title, text: item.title, value: item.id } })
                                                    : this.props.feedbackTitlesList?.filter((items) => items.id === 8).map((item) => { return { key: item.title, text: item.title, value: item.id } })
                                                }
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        ...this.state,
                                                        feedbackTitle: (data.value || '').toString(),
                                                        feedbackTitleIsEmpty: false
                                                    });
                                                    this.dropDownValue = data.value;
                                                }} />
                                            <br />
                                            {this.state.feedbackTitleIsEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Не выбрана тема сообщения
                                                </div>
                                                : ""
                                            }
                                            <br />
                                            <div className={"modal-subject-text"}>
                                                Сообщение
                                            </div>
                                            <MarkableTextArea
                                                className="feedback-textarea"
                                                text={this.state.feedbackText ?? ""}
                                                type="area"
                                                verifyDelay={500}
                                                onChange={(value: any) => {
                                                    this.setState({
                                                        sendButtonDisabled: true,
                                                        feedbackText: (value || '').toString(),
                                                        feedbackTextIsEmpty: false
                                                    })
                                                }}
                                                onRangesUpdated={(ranges: []) => {
                                                    this.setState({ msgDeniedCount: ranges.length });
                                                    this.updateDisableButton();
                                                }}
                                            />
                                            <br />
                                            {this.state.feedbackTextIsEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Не заполнен текст сообщения
                                                </div>
                                                : ""
                                            }
                                            <br />
                                            {
                                            this.state.selectedFile? 
                                                <FileViewer url = {URL.createObjectURL(this.state.selectedFile)}/>
                                                : ""
                                            } 
                                            
                                            {
                                            <div onClick={this.onAddPhotoClick}>
                                                <div id="feedback-add-photo-button">
                                                    <div id="feedback-add-photo-button-text">
                                                        Добавить фото
                                                    </div>
                                                    <div id="add-photo-button-plus-border"></div>
                                                    <div id="add-photo-button-plus-horisontal-line"></div>
                                                    <div id="add-photo-button-plus-vertical-line"></div>
                                                </div>
                                                <input type="file" ref={((ref) =>  this.upload = ref)} onChange={this.fileChangedHandler}
                                                    style={{ display: "none" }} />
                                            </div>
                                            }
                                            
                                            <br />
                                            {this.state.errorUpload !== '' 
                                                ?   <div className={"modal-error-text"}>
                                                        {this.state.errorUpload}
                                                    </div>
                                                : ""
                                            }
                                            <br /><br /><br />
                                            <div className={"send-feedback-button " + (this.state.sendButtonDisabled ? "disabled" : "")} onClick={this.state.sendButtonDisabled ? undefined : this.checkAndSenfFeedback}>Отправить</div>
                                        </Modal.Description>
                                    </Modal.Content>
                                </Modal>
                            </div>
                            :
                            <div className="ui items segments content-survey">
                                <div className={this.props.sendSurvayTitlesInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                    <div className="ui text loader">Загрузка...</div>
                                </div>
                                <div>
                                    {_.map(this.props.surveyTitlesList, (item: Survay) => <SurveyRow survey={item} onClick={(item: Survay) => {
                                        this.props.selectSurvey(item);
                                        this.setState({
                                            ...this.state,
                                            IsMode: false,
                                            cardMode: true
                                        });
                                    }} />)}
                                </div>
                                {!this.props.surveyTitlesList || this.props.surveyTitlesList?.length === 0 ?
                                    <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
                            </div>

                        }
                    </div>
                    :
                    <div>
                        {(this.state.IsMode)
                            ?
                            <FeedBackCard backToList={() => {
                                this.setState({
                                    ...this.state,
                                    cardMode: false,
                                });
                            }} />
                            :
                            <SurveyCard backToList={() => {
                                this.props.refreshUserInfo();
                                this.setState({
                                    ...this.state,
                                    cardMode: false,
                                });
                            }} />
                        }
                    </div>

                }
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    /*
     <SurveyCard backToList={()=>{
                                this.setState({
                                    ...this.state,
                                    cardMode: false, 
                                });
                            }}
                            />*/
                                
    let feedbackStore: FeedbackStore = state.feedback;
    let auth: AuthStore = state.authentication;
    return {
        user: auth.user ?? {},

        feedbacksList: feedbackStore.feedbacksList,
        feedbacksListInProcess: feedbackStore.feedbacksListInProcess,

        feedbackTitlesList: feedbackStore.feedbackTitles,
        feedbackTitlesInProcess: feedbackStore.feedbackTitlesInProcess,
        errorInSendFeedback: feedbackStore.errorInSendFeedback,
        sendFeedbackInProcess: feedbackStore.sendFeedbackInProcess,

        isRedirectFromSupport: feedbackStore.isRedirectFromSupport,

        registrationRequested: state.authentication.registrationRequested,
        errorMessage: state.authentication.registrationErrorMessage,
        authIoProcess: state.authentication.authIoProcess,

        surveyTitlesList: feedbackStore.surveyTitlesList,
        surveyTitlesQRList: feedbackStore.surveyTitlesQRList,
        sendSurvayInProcess: feedbackStore.sendSurvayTitlesInProcess,
        notificationSyrvayCount: state.notification.notificationSyrvayCount
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(feedbackActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackComp);
