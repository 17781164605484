import {
    TASK_LIST_FAILED,
    TASK_LIST_SUCCESS,
    TASK_LIST_PROCESS,
    TASK_SELECT,
    TASK_FAILED,
    TASK_SUCCESS,
    TASK_PROCESS, TASK_PHOTO_PROCESS, TASK_PHOTO_FAILED, TASK_PHOTO_SUCCESS, TASK_PHOTO_REMOVE,
    TASK_CATEGORIES_SUCCESS,
    TASK_CATEGORIES_FAILED,
    TASK_CATEGORIES_PROCESS,
    TASK_OFFER_SUCCESS,
    TASK_OFFER_FAILED,
    TASK_OFFER_PROCESS
} from "./types";

import {Reducer} from "redux";
import {CamperTask, TaskCategory, TaskStore,OfferTask} from "../taskType";

const initialState = () => {
    return {
        list: [] as CamperTask[],
        errorInList: '',
        listInProcess: false,

        typeInProcess: false,
        taskCategoriesTTTS: [] as TaskCategory[],
        errorInType: '',
        offerTask: {} as OfferTask,
        errorInTaskOffer:'',
        typeInProcessOffer: false,
        isDoneOffer: false
    } as TaskStore;
};

const taskStore: Reducer = (state: TaskStore = initialState(), action: any) => {
    switch (action.type) {
        case TASK_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcess: false
            };
        }
        case TASK_LIST_SUCCESS: {
            return {
                ...state,
                listInProcess: false,
                list: action.data
            };
        }
        case TASK_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcess: true
            };
        }

        case TASK_SELECT: {
            return {
                ...state,
                errorInList: '',
                errorInTask: '',
                selectedItem: action.data,
                loadedPhotos: [],
                isDoneOffer:false
            };
        }

        case TASK_FAILED: {
            return {
                ...state,
                errorInTask: action.errorMessage,
                operationInProcess: false
            };
        }
        case TASK_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data :
                    todo
                ),
                selectedItem: action.data
            };
        }
        case TASK_PROCESS: {
            return {
                ...state,
                errorInTask: '',
                operationInProcess: true,
                loadedPhotos: []
            };
        }

        case TASK_PHOTO_PROCESS: {
            return {
                ...state,
                errorInTask: '',
                operationInProcess: true
            };
        }
        case TASK_PHOTO_FAILED: {
            return {
                ...state,
                errorInTask: action.errorMessage,
                operationInProcess: false
            };
        }
        case TASK_PHOTO_SUCCESS: {
            return {
                ...state,
                errorInTask: '',
                operationInProcess: false,
                loadedPhotos: state.loadedPhotos.concat(action.data)
            };
        }

        case TASK_PHOTO_REMOVE: {
            return {
                ...state,
                errorInTask: '',
                operationInProcess: false,
                loadedPhotos: state.loadedPhotos.filter(item => item.fileUrl !== action.data.fileUrl),
            };
        }
        case TASK_CATEGORIES_FAILED: {
            return {
                ...state,
                errorInType: action.errorMessage,
                typeInProcess: false
            };
        }
        case TASK_CATEGORIES_SUCCESS: {
            return {
                ...state,
                typeInProcess: false,
                taskCategoriesTTTS: action.data
            };
        }
        case TASK_CATEGORIES_PROCESS: {
            return {
                ...state,
                errorInType: '',
                typeInProcess: true
            };
        }
        case TASK_OFFER_FAILED: {
            return {
                ...state,
                errorInTaskOffer: action.errorMessage,
                typeInProcessOffer: false,
                isDoneOffer:false
            };
        }
        case TASK_OFFER_SUCCESS: {
            return {
                ...state,
                typeInProcessOffer: false,
                errorInTaskOffer:'',
                isDoneOffer:true
               
            };
        }
        case TASK_OFFER_PROCESS: {
            return {
                ...state,
                errorInTaskOffer: '',
                typeInProcessOffer: true,
                isDoneOffer:false
            };
        }
        default:
            return state;
    }
};

export default taskStore;
