export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILED = "NOTIFICATION_LIST_FAILED";
export const NOTIFICATION_LIST_PROCESS = "NOTIFICATION_LIST_PROCESS";

export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILED = "NOTIFICATION_FAILED";
export const NOTIFICATION_PROCESS = "NOTIFICATION_PROCESS";

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";

export const NOTIFICATION_SELECT = "NOTIFICATION_SELECT";

export const NOTIFICATION_SEND_SELECT = "NOTIFICATION_SEND_SELECT";

export const NOTIFICATION_VIEW_SUCCESS = "NOTIFICATION_VIEW_SUCCESS";

export const NOTIFICATION_LIST_SEND_SUCCESS = "NOTIFICATION_LIST_SEND_SUCCESS";
export const NOTIFICATION_LIST_SEND_FAILED = "NOTIFICATION_LIST_SEND_FAILED";
export const NOTIFICATION_LIST_SEND_PROCESS = "NOTIFICATION_LIST_SEND_PROCESS";

export const NOTIFICATION_OFFER_TASK_COUNT = "NOTIFICATION_OFFER_TASK_COUNT";
export const NOTIFICATION_OFFER_CHANGE_TASK_COUNT = "NOTIFICATION_OFFER_CHANGE_TASK_COUNT";

export const NOTIFICATION_SYRVEY_COUNT = "NOTIFICATION_SYRVEY_COUNT";

