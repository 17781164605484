import {Gift, GiftCategory, GiftReserved, ListGiftFilter, ListGiftReservedFilter, ProcessGiftReserve, ProcessSuperGiftReserve} from "../giftType";
import giftApi from "../../api/gift";
import {
    GIFT_LIST_FAILED,
    GIFT_LIST_SUCCESS,
    GIFT_LIST_PROCESS,
    GIFT_SELECT,
    GIFT_FAILED,
    GIFT_SUCCESS,
    GIFT_PROCESS,
    GIFT_FAILED_OVERBOOKED,
    GIFT_RESERVED_LIST_PROCESS,
    GIFT_RESERVED_LIST_SUCCESS,
    GIFT_RESERVED_LIST_FAILED,
    GIFT_CANCEL_PROCESS,
    GIFT_CANCEL_SUCCESS,
    GIFT_CANCEL_FAILED,
    GIFT_RATING_FAILED,
    GIFT_RATING_SUCCESS,
    GIFT_RATING_PROCESS,
    GIFT_WISHED_SUCCESS,
    GIFT_CATEGORIES_SUCCESS,
    GIFT_CATEGORIES_PROCESS,
    GIFT_CATEGORIES_FAILED,
    GIFT_HISTORY_SUCCESS,
    SUPER_GIFT_SUCCESS,
    SUPER_GIFT_FAILED_OVERBOOKED,
    SUPER_GIFT_FAILED, SUPER_GIFT_PROCESS,

    CONFIRM_GIFT_SUCCESS,
    CONFIRM_GIFT_FAILED,
    CONFIRM_GIFT_PROCESS,

    UPDATE_ERROR

} from "./types";
import {clearReduxStateInternal, refreshUserInfo} from "../authentication/actions";

// получение списка подарков
export const listGifts = (filter: ListGiftFilter) => (dispatch: any) => {
    giftApi.list(filter, (status: number, data: Gift[] | string | undefined) => {
        if (status === 200) {

            dispatch({
                type: GIFT_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка подарков'
            });
        }
    });

    return dispatch({
        type: GIFT_LIST_PROCESS
    });
}

export const listReservedGifts = (filter: ListGiftReservedFilter) => (dispatch: any) => {
    giftApi.listReserved(filter, (status: number, data: Gift[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_RESERVED_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_RESERVED_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка зарезервированных подарков'
            });
        }
    });

    return dispatch({
        type: GIFT_RESERVED_LIST_PROCESS
    });
}

/// отмена резервирования подарка
export const cancelGift = (id: number) => (dispatch: any) => {
    giftApi.cancel(id, (status: number, data: GiftReserved | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_CANCEL_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_CANCEL_FAILED,
                errorMessage: data || 'Ошибка отмены резервирования подарка'
            });
        }
    });

    return dispatch({
        type: GIFT_CANCEL_PROCESS
    });
}

export const addLookToHistory = (giftId: number) => (dispatch: any) => {
    giftApi.addLookToHistory(giftId, (status: number, data: Gift | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_HISTORY_SUCCESS,
                data
            });            
        } else {
            return dispatch({
                type: GIFT_FAILED,
                errorMessage: data || 'Ошибка добавления истории просмотра к подарку'
            });
        }
    });
}

export const addToWishList = (giftId: number) => (dispatch: any) => {
    giftApi.addToWishList(giftId, (status: number, data: Gift | string | undefined) =>{
        if (status === 200) {
            dispatch({
                type: GIFT_WISHED_SUCCESS,
                data
            });
        } else {
            return dispatch({
                type: GIFT_FAILED,
                errorMessage: data || 'Ошибка добавления подарка в список желаний'
            });
        }        
    });
}

export const deleteFromWishList = (giftId: number) => (dispatch: any) => {
    giftApi.deleteFromWishList(giftId, (status: number, data: Gift | string | undefined) =>{
        if (status === 200) {
            dispatch({
                type: GIFT_WISHED_SUCCESS,
                data
            });
        } else {
            return dispatch({
                type: GIFT_FAILED,
                errorMessage: data || 'Ошибка удаления подарка из списка желаний'
            });
        }
        
    });
}

/// резервирование подарка
export const reserve = (model: ProcessGiftReserve) => (dispatch: any) => {
    giftApi.reserve(model, (status: number, data: Gift | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_SUCCESS,
                data
            });
        } else if (status === 406) {
            dispatch({
                type: GIFT_FAILED_OVERBOOKED,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_FAILED,
                errorMessage: data || 'Ошибка резервирование подарка'
            });
        }
    });

    return dispatch({
        type: GIFT_PROCESS
    });
}

/// оценка подарка
export const ratingGift = (id: number, rating: number) => (dispatch: any) => {
    giftApi.rating(id, rating, (status: number, data: GiftReserved | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_RATING_SUCCESS,
                data: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_RATING_FAILED,
                errorMessage: data || 'Ошибка оценки подарка'
            });
        }
    });

    return dispatch({
        type: GIFT_RATING_PROCESS
    });
}

/// выбрать подарок
export const selectGift = (model: Gift) => (dispatch: any) => {
    return dispatch({
        type: GIFT_SELECT,
        data: model
    });
}

// получение типов подарка
export const getGiftCategories = () => (dispatch: any) => {
    giftApi.getGiftCategories((status: number, data: GiftCategory[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: GIFT_CATEGORIES_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: GIFT_CATEGORIES_FAILED,
                errorMessage: data || 'Ошибка получения типов подарка'
            });
        }
    });

    return dispatch({
        type: GIFT_CATEGORIES_PROCESS
    });
}

/// резервирование супер-приза
export const reserveSuperGift = (model: ProcessSuperGiftReserve) => (dispatch: any) => {
    giftApi.reserveSuperGift(model, (status: number, data: Gift | string | undefined) => {
        if (status === 200) {
            dispatch(refreshUserInfo());
            dispatch({
                type: SUPER_GIFT_SUCCESS,
                data
            });
        } else if (status === 406) {
            dispatch(refreshUserInfo());
            dispatch({
                type: SUPER_GIFT_FAILED_OVERBOOKED,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            dispatch(refreshUserInfo());
            return dispatch({
                type: SUPER_GIFT_FAILED,
                errorMessage: data || 'Ошибка резервирование подарка'
            });
        }
    });

    return dispatch({
        type: SUPER_GIFT_PROCESS
    });
}


/// подтверждение на получение
export const confirmReceiptOfGifts = (model: number[]) => (dispatch: any) => {
    giftApi.confirmReceiptOfGifts(model, (status: number, data:  any | string | undefined) => {
        if (status === 200) {
            dispatch(refreshUserInfo());
            dispatch({
                type: CONFIRM_GIFT_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            dispatch(refreshUserInfo());
            return dispatch({
                type: CONFIRM_GIFT_FAILED,
                errorMessage: data || 'Ошибка подтверждения подарков'
            });
        }
    });

    return dispatch({
        type: CONFIRM_GIFT_PROCESS
    });
}
/// обновить стейт ошибки 
export const updateError = (model: string) => (dispatch: any) => {
    return dispatch({
        type: UPDATE_ERROR,
        data: model
    });
}
/// подтверждение на получение по аккаунту
export const confirmReceiptOfCamper = (model: number) => (dispatch: any) => {
    giftApi.confirmReceiptOfCamper(model, (status: number, data:  Gift[] | string | undefined) => {
        if (status === 200) {
            dispatch(refreshUserInfo());
            dispatch({
                type: CONFIRM_GIFT_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            dispatch(refreshUserInfo());
            return dispatch({
                type: CONFIRM_GIFT_FAILED,
                errorMessage: data || 'Ошибка подтверждения подарков'
            });
        }
    });

    return dispatch({
        type: CONFIRM_GIFT_PROCESS
    });
}


