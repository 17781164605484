import axios from "axios";
import {CurrentConfig} from './config';
import { LikeFilter } from "../store/homeType";

const likeApi =  {
    Add: (filter: LikeFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/like/add`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    Delete: (filter: LikeFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/like/Delete`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
   
}

export default likeApi;