import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import error from '../../../images/error.png';
import maket from '../../../images/maket.png';

function ImageLightbox({ imageUrl, linkId, imageClassName, description   }: { imageUrl: string, linkId: any, imageClassName: string, description: string }) {
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <div>
        <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = maket;
            }} 
            src={(linkId == null) 
            ? error 
            : imageUrl} alt="Картинка"
            onClick={openLightbox}
            className={imageClassName}>  
        </img>
        {isOpen && (
            <Lightbox
            mainSrc={imageUrl}
            onCloseRequest={closeLightbox}
            imageCaption={description}
            imageLoadErrorMessage = {"Не удалось загрузить изображение"}
            />
        )}
    </div>
  );
}

export default ImageLightbox;