import { CurrentConfig } from '../../api/config';
import WebPushApi from '../../api/webpush';
import {
  clearReduxStateInternal,
  refreshUserInfo,
} from '../authentication/actions';
import { WebPushDevice } from '../webPushType';
import {
  WEB_PUSH_DEL_DEV_FAILED,
  WEB_PUSH_DEL_DEV_PROCESS,
  WEB_PUSH_DEL_DEV_SUCCESS,
  WEB_PUSH_GET_KEY_FAILED,
  WEB_PUSH_GET_KEY_PROCESS,
  WEB_PUSH_GET_KEY_SUCCESS,
  WEB_PUSH_REG_DEV_FAILED,
  WEB_PUSH_REG_DEV_PROCESS,
  WEB_PUSH_REG_DEV_SUCCESS,
  WEB_PUSH_REG_SW_FAILED,
  WEB_PUSH_REG_SW_SUCCESS,
} from './types';

export const regDevice = (webPushDevice: WebPushDevice) => (dispatch: any) => {
  console.log('0000000000000');
  WebPushApi.regDevice(
    webPushDevice,
    (status: number, data: any | string | undefined) => {
      if (status === 200) {
        dispatch(refreshUserInfo());
        dispatch({
          type: WEB_PUSH_REG_DEV_SUCCESS,
          data,
        });
      } else if (status === 401) {
        dispatch(clearReduxStateInternal());
      } else {
        return dispatch({
          type: WEB_PUSH_REG_DEV_FAILED,
          errorMessage: data || 'Ошибка ...',
        });
      }
    }
  );

  return dispatch({
    type: WEB_PUSH_REG_DEV_PROCESS,
  });
};

export const delDevice = () => (dispatch: any) => {
  WebPushApi.delDevice((status: number, data: any | string | undefined) => {
    if (status === 200) {
      dispatch(refreshUserInfo());
      dispatch({
        type: WEB_PUSH_DEL_DEV_SUCCESS,
      });
    } else if (status === 401) {
      dispatch(clearReduxStateInternal());
    } else {
      return dispatch({
        type: WEB_PUSH_DEL_DEV_FAILED,
        errorMessage: data || 'Ошибка ...',
      });
    }
  });

  return dispatch({
    type: WEB_PUSH_DEL_DEV_PROCESS,
  });
};

export const getPublicKey = (dispatch: any) => {
  WebPushApi.getPublicKey((status: number, data: any | string | undefined) => {
    if (status === 200) {
      dispatch({
        type: WEB_PUSH_GET_KEY_SUCCESS,
        publicKey: data,
      });
    } else if (status === 401) {
      dispatch(clearReduxStateInternal());
    } else {
      return dispatch({
        type: WEB_PUSH_GET_KEY_FAILED,
        errorMessage: data || 'Ошибка получения ...',
      });
    }
  });

  return dispatch({
    type: WEB_PUSH_GET_KEY_PROCESS,
  });
};

export const regSW = () => (dispatch: any) => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${CurrentConfig.currentConfig.PublicUrl}/ServiceWorker.js`)
        .then(reg => {
          if (Notification.permission === 'granted') {
            console.log('Notification.permission === "granted"');
            getSubscription(reg);
          } else if (Notification.permission === 'denied') {
            console.log('Notification.permission === "blocked"');
          } else {
            console.log(' } else {');
            requestNotificationAccess(reg);
          }
        });
    });
    return dispatch({
      type: WEB_PUSH_REG_SW_SUCCESS,
      data: true,
    });
  } else {
    console.log('Push Notifications not support!');
    return dispatch({
      type: WEB_PUSH_REG_SW_FAILED,
      data: false,
      errorMsg: 'Push Notifications not support!',
    });
  }
};

function requestNotificationAccess(reg: any) {
  Notification.requestPermission(function (status) {
    console.log('Try have permission!');
    if (status === 'granted') {
      getSubscription(reg);
    } else {
      console.log('Push Notifications not support!');
    }
  });
}

function getSubscription(reg: any) {
  reg.pushManager.getSubscription().then(function (sub: any) {
    console.log(sub);
    if (sub === null) {
      reg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BK6bgssfRNMO_XSPMU0Ok2Iw9D_x3qUJXgZREp5dEwJentLC3VQ6mY5Ik1KtI1oCOu__QjM63qUf9YCsJQo4pV8',
        })
        .then(function (sub: any) {
          fillSubscribeFields(sub);
        })
        .catch(function (e: any) {
          console.error('Unable to subscribe to push', e);
        });
    } else {
      fillSubscribeFields(sub);
    }
  });
  console.log('+++');
  console.log(reg);
}

function fillSubscribeFields(sub: any) {
  console.log(sub.endpoint);
  console.log(arrayBufferToBase64(sub.getKey('p256dh')));
  console.log(arrayBufferToBase64(sub.getKey('auth')));
}

function arrayBufferToBase64(buffer: any) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
