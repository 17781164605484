import "./types";
import {
    APPROVING_CHILD_FAILED,
    APPROVING_CHILD_PROCESS,
    APPROVING_CHILD_SUCCESS,
    APPROVING_LIST_FAILED,
    APPROVING_LIST_PROCESS,
    APPROVING_LIST_SUCCESS,
    CHILD_SELECT,
    REGISTER_CHILD_FAILED,
    REGISTER_CHILD_PROCESS,
    REGISTER_CHILD_SUCCESS
} from "./types";
import {ApprovingStore, Camper} from '../approvingType'
import {Reducer} from "redux";
import {data} from "jquery";

const initialState = () => {
    return {
        listInProcess: false,
        list: [] as Camper[]
    } as ApprovingStore;
};

const authentication: Reducer = (state: ApprovingStore = initialState(), action: any) => {
    switch (action.type) {
        case APPROVING_LIST_PROCESS: {
            return {
                ...state,
                list: undefined,
                listInProcess: true
            };
        }
        case APPROVING_LIST_SUCCESS: {
            return {
                ...state,
                list: action.data,
                listInProcess: false
            };
        }
        case APPROVING_LIST_FAILED: {
            return {
                ...state,
                listInProcess: false,
                errorInList: action.errorMessage
            };
        }

        case APPROVING_CHILD_PROCESS: {
            console.log("APPROVING_CHILD_PROCESS");
            return {
                ...state,
                list: state.list.map((todo, index) => index === action.index ?
                    { ...todo, errorMessage: '', approving: true  }   :
                    todo
                )
            };
            
        }
        case APPROVING_CHILD_SUCCESS: {
            let items = (state ? state.list : undefined) || [];
            action.data.errorMessage = '';
            action.data.approving = false;
            items[action.index] = action.data;
            console.log("APPROVING_CHILD_SUCCESS");
            return {
                ...state,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data  :
                    todo
                )
            };
        }
        case APPROVING_CHILD_FAILED: {
            return {
                ...state,
                list: state.list.map(todo => todo.id === action.errorMessage.id ?
                    action.errorMessage :
                    todo
                )
            };
        }
        case CHILD_SELECT: {
            return {
                ...state,
                //errorInList: '',
                updateSelectedChild: true,
                selectedChild: action.data
            };
        }
        case REGISTER_CHILD_PROCESS: {
            return {
                ...state,
            };
            
        }
        case REGISTER_CHILD_SUCCESS: {
            return {
                ...state,
                registerDone: true
            };
        }
        case REGISTER_CHILD_FAILED: {
            //console.log(action.errRegChild);
            return {
                ...state,
                list: state.list.map(todo => todo.id === action.errorMessage.id ?
                    action.errorMessage :
                    todo
                )
            };
        }
        default:
            return state;
    }
};

export default authentication;
