import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Checkbox, Dropdown, Input } from 'semantic-ui-react'
import * as taskActions from "./../../store/task/actions"
import { clearListState, clearListStateClear, refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { CamperTask, TaskCategory, TaskListFilter, TaskStore, OfferTask } from "../../store/taskType";
import _ from "lodash";
import TaskRow from "./TaskRow";
import './Task.css';
import { CurrentConfig } from '../../api/config';
import { AuthStore, User } from "../../store/baseType";
import TaskCard from "./TaskCard";
import TaskCardOffer from "./TaskCardOffer";

import notification from './../../images/notification.png';
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';

type TaskState = {
    filter: string,
    showed: any,
    /* в статусе новое */
    n: boolean,
    /* в статусе в работе */
    w: boolean,
    /* в статусе на утверждении */
    ap: boolean,
    /* в статусе сделана */
    d: boolean,
    /* в статусе не выполнении */
    nd: boolean,
    /* в статусе отменено */
    c: boolean,
    /* стомость от */
    pf?: number
    /* стоимость до */
    pt?: number,
    /* видно фильтр */
    filterView: boolean,
    /* видно карточку */
    cardMode: boolean,
    /*Какая карточка*/
    cardOption: boolean,
    /* категории заданий */
    taskCategoriesFilter: number[] | any,
    /* продолжительность задания */
    taskDurations: number[]
}

type TaskProp = {
    listTask: Function,
    refreshUserInfo: Function,
    beginTask: Function,
    cancelTask: Function,
    done: Function,
    notDone: Function,
    onApproving: Function,
    onAsk: Function,
    uploadPhoto: Function,
    selectTask: Function,
    listInProcess: boolean,
    clearCardState: boolean,
    clearListStateClear: Function,
    list: CamperTask[] | undefined,
    errorInList: string | undefined,
    counselor: boolean,
    selectedItem: CamperTask | undefined,
    errorInTask: string,
    operationInProcess: boolean,

    getTaskCategory: Function,
    typeInProcessTP: boolean,
    errorInType: string | undefined,
    taskCategoriesTP: TaskCategory[] | undefined
    offerTask: OfferTask,

    user: User;
}

class Task extends React.Component<TaskProp, TaskState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;


    constructor(props: any) {
        super(props);
        this.state = {
            filter: '',
            showed: {},
            ap: true,
            n: true,
            w: true,
            c: false,
            nd: false,
            d: false,
            filterView: false,
            cardMode: false,
            cardOption: false,
            taskCategoriesFilter: [],
            taskDurations: []
        }
    }

    searchItems() {
        let filter: TaskListFilter = {
            skip: 0,
            take: 1000,
            ap: this.state.ap,
            n: this.state.n,
            pf: this.state.pf,
            pt: this.state.pt,
            c: this.state.c,
            nd: this.state.nd,
            d: this.state.d,
            t: this.state.filter,
            w: this.state.w,
            taskCategoriesFilter: this.state.taskCategoriesFilter,
            taskDurationsFilter: this.state.taskDurations
        };

        this.setState({ ...this.state, filterView: false });
        this.props.listTask(filter);

        this.props.getTaskCategory();
    }

    resize = () => this.forceUpdate()

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        this.searchItems();
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidUpdate(prevProps: Readonly<TaskProp>, prevState: Readonly<TaskState>, snapshot?: any) {
        if (!prevProps.clearCardState && this.props.clearCardState) {
            this.setState({
                ...this.state,
                cardMode: false
            });
        }

        if (this.props.clearCardState) {
            this.props.clearListStateClear();
        }
        if (this.state.filterView) {
            Resize(true);
        }
        else {
            Resize(false)
        }
    }

    render() {
        return (
            <div className="taskBlock">
                {(!this.state.cardMode) ?
                    <div className='search-input-btn'>
                        <SearchBar
                            action={{
                                icon: 'filter',
                                onClick: () => {
                                    this.setState({
                                        ...this.state,
                                        filterView: !this.state.filterView
                                    })
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                let text = data.value;
                                this.setState({
                                    ...this.state,
                                    filter: text
                                });

                                if (this.interval) {
                                    clearInterval(this.interval);
                                    this.interval = undefined;
                                }

                                this.interval = setInterval(() => {
                                    clearInterval(this.interval);
                                    this.searchItems();
                                    this.interval = undefined;
                                }, 600);
                            }}
                        />
                        <div className={this.state.filterView ? "" : "hidden"}>
                            <div className={"task-table-header"}>
                                Статус задания
                            </div>
                            <div className='task-tho task-checbox'>
                                <Checkbox label={"Доступно"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        n: data.checked || false
                                    });
                                })} checked={this.state.n} />


                                <Checkbox label={"В работе"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        w: data.checked || false
                                    });
                                })} checked={this.state.w} />

                                <Checkbox label={"На проверке"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        ap: data.checked || false
                                    });
                                })} checked={this.state.ap} />


                                <Checkbox label={"Отменено"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        c: data.checked || false
                                    });
                                })} checked={this.state.c} />

                                <Checkbox label={"Не выполнено"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        nd: data.checked || false
                                    });
                                })} checked={this.state.nd} />

                                <Checkbox label={"Выполнено"} onChange={((event, data) => {
                                    this.setState({
                                        ...this.state,
                                        d: data.checked || false
                                    });
                                })} checked={this.state.d} />
                            </div>
                            <div className={"task-table-header"}>
                                Темы задания
                            </div>
                            <div className='task-dropdown'>
                                <Dropdown placeholder='Все'
                                    fluid
                                    multiple
                                    selection
                                    options={_.map(this.props.taskCategoriesTP, (item: any) => { return { key: item.name, text: item.name, value: item.id } })}
                                    onChange={((event, data) => {
                                        this.setState({
                                            ...this.state,
                                            taskCategoriesFilter: data.value
                                        });
                                    })} value={this.state.taskCategoriesFilter} />

                            </div>
                            <div className={"task-table-header"}>
                                Стоимость задания
                            </div>
                            <div className='task-tho gift-input'>
                                <Input type={'text'} maxLength={5} pattern="[0-9]*" placeholder={"от"}
                                    onChange={(e, data: any) => {
                                        this.setState({
                                            ...this.state,
                                            pf: data.value.replace(/\D/, '')
                                        });
                                    }} value={this.state.pf} className="ui-background" />


                                <Input type={'text'} maxLength={5} pattern="[0-9]*" placeholder={"до"}
                                    onChange={(e, data: any) => {
                                        this.setState({
                                            ...this.state,
                                            pt: data.value.replace(/\D/, '')
                                        });
                                    }} value={this.state.pt} className="ui-background" />
                            </div>
                            <div className={"task-table-header"}>
                                Продолжительность
                            </div>

                            <div className='task-tho task-checbox'>
                                <Checkbox label={"1 день"} onChange={((event, data) => { //1440
                                    var mas = Array.from(this.state.taskDurations);
                                    this.setState({
                                        ...this.state,
                                        //n: data.checked || false
                                        taskDurations: data.checked ? this.state.taskDurations.concat([1440]) : this.state.taskDurations.filter(i => i != 1440)
                                    });
                                })} checked={this.state.taskDurations.includes(1440)} />

                                <Checkbox label={"3 дня"} onChange={((event, data) => { //4320
                                    this.setState({
                                        ...this.state,
                                        taskDurations: data.checked ? this.state.taskDurations.concat([4320]) : this.state.taskDurations.filter(i => i != 4320)
                                    });
                                })} checked={this.state.taskDurations.includes(4320)} />

                                <Checkbox label={"7 дней"} onChange={((event, data) => { //10080
                                    this.setState({
                                        ...this.state,
                                        taskDurations: data.checked ? this.state.taskDurations.concat([10080]) : this.state.taskDurations.filter(i => i != 10080)
                                    });
                                })} checked={this.state.taskDurations.includes(10080)} />
                            </div>
                            <Button
                                style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive}
                                onClick={() => {
                                    this.searchItems();
                                }} fluid>Поиск</Button>
                        </div>
                        <div className="ui items segments" style={{ margin: "20px" }}>
                            <div
                                className={this.props.listInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                <div className="ui text loader">Загрузка...</div>
                            </div>
                            {_.map(this.props.list, (item: CamperTask) => <TaskRow task={item}
                                onClick={(item: CamperTask) => {
                                    this.props.selectTask(item);
                                    this.setState({
                                        ...this.state,
                                        cardMode: true,
                                        cardOption: false
                                    });
                                }}
                                onEdit={(item: CamperTask) => {
                                    this.props.selectTask(item);
                                    this.setState({
                                        ...this.state,
                                        cardMode: true,
                                        cardOption: true
                                    });
                                }}

                            />)}
                            {!this.props.list || this.props.list?.length === 0 ?
                                <div className="row-salute">
                                    <div className='column-salute-icon'>
                                        <img alt="QR-код" src={notification} height='28px' width='28px' />
                                    </div>

                                    <div className='column-salute-text'>
                                        В результате поиска ничего не найдено
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    :
                    <div>
                        {(!this.state.cardOption) ?
                            <TaskCard backToList={() => {
                                this.setState({
                                    ...this.state,
                                    cardMode: false
                                });
                            }} />
                            :
                            <TaskCardOffer
                                backToList={() => {

                                    this.setState({
                                        ...this.state,

                                        cardMode: false
                                    });
                                }} />
                        }
                    </div>


                }
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let taskStore: TaskStore = state.task;
    let authStore: AuthStore = state.authentication
    return {
        listInProcess: taskStore.listInProcess,
        list: taskStore.list,
        errorInList: taskStore.errorInList,
        counselor: authStore.counselor,
        clearCardState: authStore.clearCardState,
        taskCategoriesTP: taskStore.taskCategoriesTTTS,
        taskInProcessTP: taskStore.typeInProcess,
        user: authStore.user ?? {},
    }
}

function Resize(cond: boolean) {
    var aa = document.getElementById("searchModal")
    var taskRowsContainer = document.querySelector(".taskBlock .items") as HTMLElement
    if (cond) {
        if (aa != null) {
            if (taskRowsContainer != null) {
                if (window.innerHeight > window.innerWidth) {
                    taskRowsContainer.style.maxHeight = "75vh";
                }
                if (window.innerHeight < window.innerWidth) {
                    taskRowsContainer.style.maxHeight = "35vh";
                }
            }
        }
    }
    else {
        if (aa != null) {
            if (taskRowsContainer != null) {
                if (window.innerHeight > window.innerWidth) {
                    taskRowsContainer.style.maxHeight = "95vh";
                }
                if (window.innerHeight < window.innerWidth) {
                    taskRowsContainer.style.maxHeight = "85vh";
                }
            }
        }
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
    ...bindActionCreators({ refreshUserInfo, clearListStateClear }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Task);