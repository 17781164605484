import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as giftActions from "./../../store/gift/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Gift } from "../../store/giftType";
import { CurrentConfig } from "../../api/config";

import heart_pressed from './../../images/heart_pressed.png';
import heart_onpressed from './../../images/heart_onpressed.png';
import viewed from './../../images/viewed.png';
import { Icon } from 'semantic-ui-react';
import { GiftPosterComponent } from '../../components/UI/GiftPosterComponent';

type GiftRowState = {}

type GiftRowProp = {
    gift: Gift,
    onClick: Function,
    addToWishList: Function,
    deleteFromWishList: Function,
    inGiftLent: boolean,
    
    addWishedInProcess: boolean
}

class GiftRow extends React.Component<GiftRowProp, GiftRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    handleClick() {
    }

    addToWishList() {
        this.props.addToWishList(this.props.gift.id);
    }

    deleteFromWishList() {
        this.props.deleteFromWishList(this.props.gift.id);
    }

    addToWishListA = () => { this.props.addToWishList(this.props.gift.id) }
    deleteFromWishListA = () => { this.props.deleteFromWishList(this.props.gift.id) }

    render() {
        return (
            <div className={"gift-viewed "}>
                <div className={!this.props.inGiftLent ? "gift-row" : "gift-row-lent" || this.props.gift.superPrize ? "gift-row-slider" : ""}>
                    <div className='image-gift'>
                        <GiftPosterComponent pointAvailabilityLighting={this.props.gift.pointAvailable}
                            wished={this.props.gift.inWishList}
                            giftWishOnClick= {this.props.gift.inWishList ? () => this.deleteFromWishList() : () => this.addToWishList()}
                            shopCard={true}
                            price = {this.props.gift.price}
                            giftPhotoUrl={this.basePath + '/downloadFileByLink?id=' + this.props.gift.linkId}
                            onClick={(e: any) => {
                                if (e.button === 0) {
                                    this.props.onClick(this.props.gift)
                                }
                            }}
                        />
                    </div>
                    
                    <div className='gift-row-info'>
                        <div className={"gift-name"}>
                            {this.props.gift.nameForShop ?? this.props.gift.name}
                        </div>

                        {!this.props.gift.superPrize ? (
                           ''
                        ) : (<div className={"gift-point"}>
                            Суперприз
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let giftStore: Gift = state.gift;
    return {
        addWishedInProcess: giftStore.addWishedInProcess,
        
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(giftActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftRow);