export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAILED =  "OFFER_LIST_FAILED";
export const OFFER_LIST_PROCESS = "OFFER_LIST_PROCESS";

export const OFFER_TYPE_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_TYPE_FAILED = "OFFER_LIST_FAILED";
export const OFFER_TYPE_PROCESS = "OFFER_LIST_PROCESS";

export const OFFER_READ_SUCCESS = "OFFER_READ_SUCCESS";
export const OFFER_READ_PROCESS = "OFFER_READ_PROCESS";
export const OFFER_READ_FAILED = "OFFER_READ_FAILED";


