import {Input} from "semantic-ui-react";
import React from "react";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import _ from "lodash";

type PasswordInputProps = {
    password: string,
    confirm: string,
    oldPassword: string
    changePassword: Function
    changeConfirm: Function
    changeOldPassword: Function
}   

type PasswordInputState = {
    errors: string[]
}

// проверка пароля
function checkPassword(password: string) {
    
    if(password.length > 0) {
        document.querySelector(".password-indicators")?.classList.remove("hidden");
    }
    else{
        document.querySelector(".password-indicators")?.classList.add("hidden");
    }
    /*
    if (!password.match(/[0-9]+/)) {
        document.querySelector(".indicator.c")?.classList.remove("success");
        document.querySelector(".indicator.c")?.classList.add("danger");
    }
    else {
        document.querySelector(".indicator.c")?.classList.remove("danger");
        document.querySelector(".indicator.c")?.classList.add("success");
    }
    */
    if (password.length >= Math.min(6, 9) && password.length <= Math.max(6, 9)) {
        document.querySelector(".indicator.e")?.classList.remove("danger");
        document.querySelector(".indicator.e")?.classList.add("success");
    }
    else {
        document.querySelector(".indicator.e")?.classList.remove("success");
        document.querySelector(".indicator.e")?.classList.add("danger");
    }
}

class PasswordInput extends React.Component<PasswordInputProps, PasswordInputState> {
    constructor(props: any) {
        super(props);
        this.state = {
            errors: []
        }
    }

    componentDidUpdate(prevProps: Readonly<PasswordInputProps>, prevState: Readonly<PasswordInputState>, snapshot?: any) {
        if (this.props.password != prevProps.password) {
            if (!this.props.password) {
                this.setState({
                    ...this.state,
                    errors: []
                });
            }else {
                checkPassword(this.props.password)
                this.setState({
                    ...this.state
                });
            }

        }
    }

    render() {
        return <div className={"login-input"}>
            <Input icon='key' iconPosition='left' placeholder='Текущий пароль'
                   className={this.state.errors.length === 0 ? "login-input" : "login-input error"}
                   type="password"
                   value={this.props.oldPassword}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                   this.setState({
                        ...this.state
                    });
                    if (this.props.changePassword) {
                        this.props.changeOldPassword(data.value)
                    }
                   }}/>
            <Input icon='key' iconPosition='left' placeholder='Придумайте новый пароль'
                   className={this.state.errors.length === 0 ? "login-input" : "login-input error"}
                   type="password"
                   value={this.props.password}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                    checkPassword(data.value)
                       this.setState({
                           ...this.state
                       });
                       if (this.props.changePassword) {
                           this.props.changePassword(data.value)
                       }
                   }}/>
            <Input icon='key' iconPosition='left' placeholder='Повторите пароль'
                   className={this.props.password === this.props.confirm ? "login-input password-confirm" : "login-input error password-confirm"}
                   type="password"
                   value={this.props.confirm}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                       if (this.props.changeConfirm) {
                           this.props.changeConfirm(data.value)
                       }
                   }}/>
            <div className="password-indicators hidden">
                <span className="indicator e">6-9</span>
            </div>
            {this.props.password === this.props.confirm ? "" : <ul>
                <li className={"error-class small"}>Пароль и подтверждение должны совпадать</li>
            </ul>}
        </div>;
    }
}


export default PasswordInput;