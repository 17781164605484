import { CurrentConfig } from './api/config';

export function unRegServicesWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
export function regServicesWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${CurrentConfig.currentConfig.PublicUrl}/ServiceWorker.js`)
        .then(reg => {
          if (Notification.permission === 'granted') {
            console.log('Notification.permission: granted');
            getSubscription(reg);
          } else if (Notification.permission === 'denied') {
            console.log('Notification.permission: blocked');
          } else {
            console.log('Notification.permission: default');
            //requestNotificationAccess(reg);
          }
        });
    });
  } else {
    console.log('Push Notifications not support!');
  }
}

function getSubscription(reg: any) {
  reg.pushManager.getSubscription().then(function (sub: any) {
    console.log(sub);
    if (sub === null) {
      reg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BK6bgssfRNMO_XSPMU0Ok2Iw9D_x3qUJXgZREp5dEwJentLC3VQ6mY5Ik1KtI1oCOu__QjM63qUf9YCsJQo4pV8',
        })
        .then(function (sub: any) {
          fillSubscribeFields(sub);
        })
        .catch(function (e: any) {
          console.error('Unable to subscribe to push', e);
        });
    } else {
      fillSubscribeFields(sub);
    }
  });
  console.log('+++');
  console.log(reg);
}

function fillSubscribeFields(sub: any) {
  console.log(sub.endpoint);
  localStorage.setItem('push_endpoint', sub.endpoint);
  console.log(arrayBufferToBase64(sub.getKey('p256dh')));
  localStorage.setItem(
    'push_p256dh',
    arrayBufferToBase64(sub.getKey('p256dh'))
  );
  console.log(arrayBufferToBase64(sub.getKey('auth')));
  localStorage.setItem('push_auth', arrayBufferToBase64(sub.getKey('auth')));
}

function arrayBufferToBase64(buffer: any) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
