import axios from "axios";
import {CurrentConfig} from './config';
import {Survay,HandlingFilter} from "../store/feedbackType";

const surveyApi = {
    getSurveyList: (filter: HandlingFilter, id: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/survey/list?id=${id}`,filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    setSurvey: (data: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/survey/set`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }, 
    
    surveyQRCode: (data: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/surveyQRCode/set`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }, 

    getSurvey: (id: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/survey/get?id=${id}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },   
}

export default surveyApi;