import axios from "axios";
import {CurrentConfig} from './config';

const notificationNewsApi = {
    list: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/listNews`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    
}

export default notificationNewsApi;