import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CurrentConfig} from "../../api/config";
import {AuthStore} from "../../store/baseType";
import * as chatActions from "../../store/chat/actions"
import {ConversationMobileService, ChatStory} from "../../store/chatType";
import avatarError from './../../images/avatarError.png';
//let moment = require('moment');

type RoomRowState = {}

type RoomRowProp = {
    selectConversation: ConversationMobileService,
    onChat: Function,
}

class RoomRow extends React.Component<RoomRowProp, RoomRowState> {

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div id="RoomRow-Container" onClick={(e) => {
                if (e.button === 0) {
                    this.props.onChat(this.props.selectConversation)
                }
            }}>
            <img src={(this.props.selectConversation.isGroupChat) ? avatarError : CurrentConfig.get().Api + '/downloadFileByLink?id=' + this.props.selectConversation.members.find((value)=>{ 
                if(value.uid !== this.props.selectConversation.myMember.uid){
                    return value.linkId;
                }
            })?.linkId} 
            className="RoomRow-Photo" 
            alt=""
            onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src=avatarError;
            }}/>
            <div id={"RoomRow-body"}>
                <div className={"RoomRow-name"}>
                    {(this.props.selectConversation?.isGroupChat)? this.props.selectConversation?.title: this.props.selectConversation?.members.find((value)=>{return value.uid !== this.props.selectConversation?.myMember?.uid})?.name}
                </div>
                    <span className={"RoomRow-consumer"}>{this.props.selectConversation?.сonsumer}</span>
                {(this.props.selectConversation?.theme!='')?
                    <div className={"RoomRow-theme"}>
                        Тема: {this.props.selectConversation?.theme}
                    </div>     
                    :''
                }               
            </div>
            {this.props.selectConversation.unread !==0
            ? <div className='unread'>
                {this.props.selectConversation.unread}
             </div>
            : ''
            }
           
        </div>
        );
    }
}

function mapStateToProps(state: any) {
    let chatStore: ChatStory = state.messeger;
    return {
       
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(chatActions, dispatch),
//    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomRow);