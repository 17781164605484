import "./types";
import {
    NOTIFICATION_LIST_PROCESS,
    NOTIFICATION_LIST_FAILED,
    NOTIFICATION_FAILED,
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_PROCESS,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_SELECT, NOTIFICATION_COUNT,
    NOTIFICATION_LIST_SEND_SUCCESS,
    NOTIFICATION_LIST_SEND_FAILED,
    NOTIFICATION_LIST_SEND_PROCESS,
    NOTIFICATION_SEND_SELECT,
    NOTIFICATION_OFFER_TASK_COUNT,
    NOTIFICATION_OFFER_CHANGE_TASK_COUNT,
    NOTIFICATION_SYRVEY_COUNT
} from "./types";
import {Reducer} from "redux";
import {NotificationStore, NotificationSend} from "../notificationType";

const initialState = () => {
    return {
        list: [],
        selectedItem: undefined,
        listInProcess: false,
        operationInProcess: false,
        errorInList: '',
        errorInTask: '',
        notificationCount: 0,
        notificationOfferTaskCount: 0,
        notificationOfferChangeTaskCount: 0,
        notificationSyrvayCount : 0,

        selectedItemSend: {} as NotificationSend,
        listSend: [] as NotificationSend [],
        errorInListSend: '',
        operationInProcessSend: false,
    } as NotificationStore
};

const authentication: Reducer = (state: NotificationStore = initialState(), action: any) => {
    switch (action.type) {
        case NOTIFICATION_COUNT: {
            return {
                ...state,
                notificationCount: action.count
            };
        }

        case NOTIFICATION_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcess: false
            };
        }
        case NOTIFICATION_LIST_SUCCESS: {
            return {
                ...state,
                listInProcess: false,
                list: action.data
            };
        }
        case NOTIFICATION_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcess: true
            };
        }

        case NOTIFICATION_SELECT: {
            return {
                ...state,
                errorInList: '',
                errorInTask: '',
                selectedItem: action.data,
                loadedPhotos: []
            };
        }

        case NOTIFICATION_FAILED: {
            return {
                ...state,
                errorInTask: action.errorMessage,
                operationInProcess: false
            };
        }
        case NOTIFICATION_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data :
                    todo
                ),
                selectedItem: action.data
            };
        }
        case NOTIFICATION_PROCESS: {
            return {
                ...state,
                errorInTask: '',
                operationInProcess: true,
                loadedPhotos: []
            };
        }        
        case NOTIFICATION_LIST_SEND_FAILED: {
            return {
                ...state,
                errorInListSend: action.errorMessage,
                operationInProcessSend: false
            };
        }
        case NOTIFICATION_LIST_SEND_SUCCESS: {
            return {
                ...state,
                operationInProcessSend: false,
                listSend: action.data  
            };
        }
        case NOTIFICATION_LIST_SEND_PROCESS: {
            return {
                ...state,
                errorInListSend: '',
                operationInProcessSend: true,
            };
        }      
        case NOTIFICATION_SEND_SELECT: {
            return {
                ...state,
                selectedItemSend: action.data,
            };
        }
        case NOTIFICATION_OFFER_TASK_COUNT: {
            return {
                ...state,
                notificationOfferTaskCount: action.count
            };
        }
        case NOTIFICATION_OFFER_CHANGE_TASK_COUNT: {
            return {
                ...state,
                notificationOfferChangeTaskCount: action.count
            };
        }
        case NOTIFICATION_SYRVEY_COUNT: {
            return {
                ...state,
                notificationSyrvayCount: action.count
            };
        }
       
        default:
            return state;
    }
};

export default authentication;
