import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { CurrentConfig } from '../../api/config';
import Rating from '../../components/UI/Rating';
import { refreshUserInfo } from '../../store/authentication/actions';
import { AuthStore, CamperState } from '../../store/baseType';
import { CamperTask, FileItem, TaskStore } from '../../store/taskType';
import notification_icon from './../../images/notification.svg';
import * as taskActions from './../../store/task/actions';
import './Task.css';

import { ImageGroup } from 'react-fullscreen-image';
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';

type TaskState = {
  filter: string;
  showed: any;
  /* в статусе новое */
  n: boolean;

  /* в статусе в работе */
  w: boolean;

  /* в статусе на утверждении */
  ap: boolean;

  /* в статусе сделана */
  d: boolean;

  /* в статусе не выполнении */
  nd: boolean;

  /* в статусе отменено */
  c: boolean;

  /* стомость от */
  pf?: number;

  /* стоимость до */
  pt?: number;

  /* видно фильтр */
  filterView: boolean;

  /* видно карточку */
  cardMode: boolean;

  /* комментарий */
  commentary?: string;

  /* рейтинг */
  rating?: number;

  /* файлы */
  files: any | undefined;

  /* отправка запроса на выполнение */
  requestToExecute: boolean;

  /* отправка запроса уведомление */
  requestToNotification: boolean;

  /* отправка запроса на отмену */
  requestToCancel: boolean;

  /* отправка запроса на проверку */
  requestToCheck: boolean;

  /* отправка запроса на выполнено */
  requestToDone: boolean;

  /* отправка запроса на выполнено */
  requestToNotDone: boolean;

  /* нужна перезагрузка страницы */
  needPageReload: boolean;

  completeSendButtonDisabled: boolean;

  commentDeniedCount?: number;
};

type TaskCardProp = {
  beginTask: Function;
  cancelTask: Function;
  done: Function;
  notDone: Function;
  onApproving: Function;
  onAsk: Function;
  uploadPhoto: Function;
  backToList: Function;
  removePhoto: Function;
  counselor: boolean;
  admin: boolean;
  selectedItem: CamperTask | undefined;
  files: FileItem[];
  errorInTask: string;
  operationInProcess: boolean;
};

class TaskCard extends React.Component<TaskCardProp, TaskState> {
  interval: any = undefined;
  basePath: string = CurrentConfig.get().Api;
  upload: HTMLInputElement | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      filter: '',
      showed: {},
      ap: true,
      n: true,
      w: true,
      c: false,
      nd: false,
      d: false,
      filterView: false,
      cardMode: false,
      files: undefined,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      needPageReload: false,
      completeSendButtonDisabled: true,
    };
  }

  updateDisableButton() {
    this.setState({
      completeSendButtonDisabled: (this.state.commentDeniedCount ?? 1) > 0,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<TaskCardProp>,
    prevState: Readonly<TaskState>,
    snapshot?: any
  ) {
    if (
      prevState.requestToCheck &&
      prevProps.operationInProcess &&
      !this.props.operationInProcess &&
      this.props.files.length <= 0 &&
      !this.props.errorInTask
    ) {
      this.setState({
        ...this.state,
        requestToExecute: false,
        requestToCancel: false,
        requestToCheck: false,
        requestToDone: false,
        requestToNotDone: false,
        requestToNotification: false,
        commentary: '',
      });
    }
    if (this.state.needPageReload) {
      window.location.reload();
      this.setState({
        needPageReload: false,
      });
    }
  }

  /* отправка запроса на выполнение */
  sendRequestToExecute() {
    this.props.beginTask(this.props.selectedItem?.id);
    this.setState({
      ...this.state,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      commentary: '',
      rating: 0,
      needPageReload: false,
    });
  }

  /* отправка запроса уведомление */
  sendRequestToNotification() {
    this.props.onAsk({
      id: this.props.selectedItem?.id,
      commentary: this.state.commentary,
    });

    this.setState({
      ...this.state,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      commentary: '',
    });
  }

  /* отправка запроса на отмену */
  sendRequestToCancel() {
    this.props.cancelTask({
      id: this.props.selectedItem?.id,
      commentary: this.state.commentary,
    });

    this.setState({
      ...this.state,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      commentary: '',
    });
  }

  /* отправка запроса на проверку */
  sendRequestToCheck() {
    this.props.onApproving({
      id: this.props.selectedItem?.id,
      commentary: this.state.commentary,
      rating: this.state.rating,
      files: _.map(this.props.files, item => item.id),
    });
  }

  /* отправка запроса на выполнено */
  sendRequestToDone() {
    this.props.done({
      id: this.props.selectedItem?.id,
      commentary: this.state.commentary,
    });

    this.setState({
      ...this.state,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      commentary: '',
    });
  }

  /* отправка запроса на выполнено */
  sendRequestToNotDone() {
    this.props.notDone({
      id: this.props.selectedItem?.id,
      commentary: this.state.commentary,
    });

    this.setState({
      ...this.state,
      requestToExecute: false,
      requestToCancel: false,
      requestToCheck: false,
      requestToDone: false,
      requestToNotDone: false,
      requestToNotification: false,
      commentary: '',
    });
  }

  onButtonClick = () => {
    if (this.upload && this.upload.click) {
      this.upload.value = '';
      this.upload.click();
    }
  };

  onRemoveClick = (item: FileItem) => {
    if (this.upload) {
      this.upload.value = '';
    }
    this.props.removePhoto(item);
    this.updateDisableButton();
  };

  fileChangedHandler = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const data = new FormData();
      data.append('file', event.target.files[0]);
      this.props.uploadPhoto(data);
    }
    this.updateDisableButton();
  };

  render() {
    let moment = require('moment');
    return (
      <div>
        <div
          className={
            this.props.operationInProcess
              ? 'ui active inverted dimmer'
              : 'ui inverted dimmer'
          }
        >
          <div className="ui text loader">Загрузка...</div>
        </div>

        <div
          className="task-card-back-btn"
          onClick={() => {
            this.setState({
              ...this.state,
              requestToExecute: false,
              requestToCancel: false,
              requestToCheck: false,
              requestToDone: false,
              requestToNotDone: false,
              requestToNotification: false,
            });

            this.props.backToList();
          }}
        >
          <Icon name="arrow left" />
          назад
        </div>

        <div style={{ margin: '8%' }}>
          <img
            src={
              this.basePath +
              '/downloadFileByLink?id=' +
              this.props.selectedItem?.boutTask?.linkId
            }
            className={'task-card-photo2'}
            alt=""
          />
        </div>

        <div className={'task-card-period2'}>
          Выполнить задание до: {this.props.selectedItem?.taskFinishDate}
          <div>Продолжительность: {this.props.selectedItem?.taskDuration}</div>
        </div>

        <div className={'task-card-description2'}>
          <div className={'task-card-description-title2'}>
            {this.props.selectedItem?.taskSnapshot
              ? JSON.parse(this.props.selectedItem?.taskSnapshot ?? null)?.Name
              : this.props.selectedItem?.boutTask.name}
          </div>

          <div className={'task-card-label2'}>Статус</div>
          <div className={'task-card-value2'}>
            {this.props.selectedItem?.state?.name}
          </div>

          <div className={'task-card-label2'}>Количество баллов</div>
          <div className={'task-card-value2'}>
            {(this.props.selectedItem?.price ?? -1) < 0
              ? this.props.selectedItem?.boutTask.price
              : this.props.selectedItem?.price}
          </div>

          <div className={'task-card-label2'}>Описание</div>
          <div className={'task-card-value2'}>
            {this.props.selectedItem?.taskSnapshot
              ? JSON.parse(this.props.selectedItem?.taskSnapshot ?? null)
                  ?.Description
              : this.props.selectedItem?.boutTask.description}
          </div>

          {this.props.selectedItem?.boutTask?.taskCategory ? (
            <div className={'task-card-label2'}>
              Тип: {this.props.selectedItem?.boutTask?.taskCategory?.name}
            </div>
          ) : (
            ''
          )}

          {this.props.selectedItem?.stateId === CamperState.OnApproving ||
          this.props.selectedItem?.stateId === CamperState.Done ||
          this.props.selectedItem?.stateId === CamperState.NotDone ? (
            <div className={'task-card-label2'}>
              Дата и время передачи на проверку
            </div>
          ) : (
            ''
          )}

          {this.props.selectedItem?.stateId === CamperState.OnApproving ||
          this.props.selectedItem?.stateId === CamperState.Done ||
          this.props.selectedItem?.stateId === CamperState.NotDone ? (
            <div className={'task-card-value2'}>
              {moment(this.props.selectedItem?.compliteDate).format(
                'HH:mm DD.MM.YYYY'
              )}
            </div>
          ) : (
            ''
          )}

          {this.props.errorInTask ? (
            <div id="task-card-notification-text">
              Ошибка выполнения операции: {this.props.errorInTask}
              <div id="task-card-notification-icon">
                <img alt="" src={notification_icon} />
              </div>
            </div>
          ) : (
            ''
          )}

          {!this.props.counselor &&
          !this.props.admin &&
          this.props.selectedItem?.stateId === CamperState.UnAssign ? (
            <Button
              id="task-card-action-button"
              color={'violet'}
              fluid
              onClick={() => {
                this.sendRequestToExecute();
              }}
            >
              Выполнить
            </Button>
          ) : (
            ''
          )}
          {!this.props.counselor &&
          !this.props.admin &&
          this.props.selectedItem?.stateId === CamperState.OnExecution ? (
            <Button
              id="task-card-action-button"
              color={'violet'}
              fluid
              onClick={() => {
                this.setState({
                  ...this.state,
                  requestToExecute: false,
                  requestToCancel: false,
                  requestToCheck: true,
                  requestToDone: false,
                  requestToNotDone: false,
                  requestToNotification: false,
                });
              }}
            >
              Подтвердить исполнение
            </Button>
          ) : (
            ''
          )}
          {(this.props.counselor || this.props.admin) &&
          this.props.selectedItem?.stateId === CamperState.OnApproving ? (
            <Button
              id="task-card-action-button"
              color={'violet'}
              fluid
              onClick={() => {
                this.setState({
                  ...this.state,
                  requestToExecute: false,
                  requestToCancel: false,
                  requestToCheck: false,
                  requestToDone: true,
                  requestToNotDone: false,
                  requestToNotification: false,
                });
              }}
            >
              Подтвердить исполнение
            </Button>
          ) : (
            ''
          )}

          {!this.props.counselor &&
          !this.props.admin &&
          (this.props.selectedItem?.stateId === CamperState.UnAssign ||
            this.props.selectedItem?.stateId === CamperState.OnExecution) ? (
            <Button
              id="task-card-get-info-button"
              basic
              color="violet"
              fluid
              onClick={() => {
                this.setState({
                  ...this.state,
                  requestToExecute: false,
                  requestToCancel: false,
                  requestToCheck: false,
                  requestToDone: false,
                  requestToNotDone: false,
                  requestToNotification: true,
                });
              }}
            >
              Уточнить условия выполнения
            </Button>
          ) : (
            ''
          )}
          {!this.props.counselor &&
          !this.props.admin &&
          this.props.selectedItem?.stateId === CamperState.OnExecution &&
          moment(this.props.selectedItem?.acceptDate).add(
            this.props.selectedItem?.durationToDeclaine,
            'minutes'
          ) > moment() ? (
            <Button
              id="task-card-get-info-button"
              basic
              color="violet"
              fluid
              onClick={() => {
                this.setState({
                  ...this.state,
                  requestToExecute: false,
                  requestToCancel: true,
                  requestToCheck: false,
                  requestToDone: false,
                  requestToNotDone: false,
                  requestToNotification: false,
                });
              }}
            >
              Отказаться
            </Button>
          ) : (
            ''
          )}
          {(this.props.counselor || this.props.admin) &&
          this.props.selectedItem?.stateId === CamperState.OnApproving ? (
            <Button
              id="task-card-get-info-button"
              basic
              color="violet"
              fluid
              onClick={() => {
                this.setState({
                  ...this.state,
                  requestToExecute: false,
                  requestToCancel: false,
                  requestToCheck: false,
                  requestToDone: false,
                  requestToNotDone: true,
                  requestToNotification: false,
                });
              }}
            >
              Не выполнено
            </Button>
          ) : (
            ''
          )}

          {this.props.selectedItem ? (
            <div>
              {this.props.selectedItem?.commentary ? (
                <div className={'task-card-label2'}>Комментарий</div>
              ) : (
                ''
              )}
              {this.props.selectedItem?.commentary}

              {this.props.selectedItem?.link?.files?.length > 0 ? (
                <ImageGroup>
                  <div className={'task-photo-blocks'}>
                    <ul className="images">
                      {_.map(this.props.selectedItem?.link?.files, item => (
                        <FileViewer
                          url={`${this.basePath}/downloadFileById?id=${item.id}`}
                        />
                      ))}
                    </ul>
                  </div>
                </ImageGroup>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          <Modal
            id="task-card-modal-window"
            size="large"
            dimmer={'blurring'}
            centered={false}
            open={
              this.state.requestToCheck ||
              this.state.requestToDone ||
              this.state.requestToNotDone ||
              this.state.requestToNotification ||
              this.state.requestToCancel
            }
            onClose={() => {
              this.setState({
                ...this.state,
                requestToExecute: false,
                requestToCancel: false,
                requestToCheck: false,
                requestToDone: false,
                requestToNotDone: false,
                requestToNotification: false,
              });
            }}
          >
            {/*<Modal.Header id="contact-header" className="modal-backgroud">*/}

            {/*</Modal.Header>*/}
            <Modal.Content className="task-card-modal-backgroud">
              <Modal.Description>
                <div id="task-card-modal-form">
                  <div
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        requestToExecute: false,
                        requestToCancel: false,
                        requestToCheck: false,
                        requestToDone: false,
                        requestToNotDone: false,
                        requestToNotification: false,
                      });
                    }}
                    className="task-card-modal-back-btn"
                  >
                    <Icon name="arrow left" />
                    назад
                  </div>

                  <div className="task-card-modal-label2">
                    {this.state.requestToCheck ? (
                      <h4>Комментарий по выполненному заданию:</h4>
                    ) : (
                      ''
                    )}
                    {this.state.requestToDone ? (
                      <h4>Комментарий по выполенному заданию:</h4>
                    ) : (
                      ''
                    )}
                    {this.state.requestToNotDone ? (
                      <h4>Комментарий по не выполенному заданию:</h4>
                    ) : (
                      ''
                    )}
                    {this.state.requestToNotification ? (
                      <h4>Задайте Ваш запрос:</h4>
                    ) : (
                      ''
                    )}
                    {this.state.requestToCancel ? (
                      <h4>Комментарий по отказу от выполнения</h4>
                    ) : (
                      ''
                    )}
                  </div>

                  {this.state.requestToNotification ||
                  this.state.requestToNotDone ||
                  this.state.requestToDone ||
                  this.state.requestToCheck ||
                  this.state.requestToCancel ? (
                    <div>
                      <MarkableTextArea
                        className="task-commentary"
                        text={this.state.commentary ?? ''}
                        type="area"
                        verifyDelay={500}
                        onChange={(value: string) => {
                          this.setState({
                            completeSendButtonDisabled: true,
                            commentary: (value || '').toString(),
                          });
                        }}
                        onRangesUpdated={(ranges: []) => {
                          this.setState({ commentDeniedCount: ranges.length });
                          this.updateDisableButton();
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {this.state.requestToCheck ||
                  this.props.selectedItem?.rating ? (
                    <div className={'task-card-modal-label-right'}>
                      Оценка задания
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.requestToCheck ? (
                    <div className="task-card-modal-rating-right">
                      <Rating
                        rating={this.state.rating}
                        OnChange={(r: number) => {
                          this.setState({
                            ...this.state,
                            rating: r,
                          });
                        }}
                      />
                      <div className={'task-card-modal-label2'}>&nbsp;</div>
                    </div>
                  ) : (
                    ''
                  )}

                  {this.state.requestToCheck ? (
                    <div
                      className={
                        'task-photo-blocks task-card-modal-rating-right'
                      }
                    >
                      <div
                        className={
                          this.props.files.length > 0
                            ? 'ui button disabled'
                            : 'ui button'
                        }
                        onClick={this.onButtonClick}
                      >
                        <i className="plus icon" />
                        Добавить файл<span style={{ color: 'red' }}>*</span>
                        <input
                          type="file"
                          ref={ref => (this.upload = ref)}
                          onChange={this.fileChangedHandler}
                          style={{ display: 'none' }}
                          accept="image/*"
                        />
                      </div>
                      <div className={'file-container'}>
                        {_.map(this.props.files, item => (
                          <div className={'task-photo-block2'}>
                            <div>
                              <FileViewer
                                url={`${this.basePath}/downloadFileById?id=${item.id}`}
                              />
                            </div>
                            <div
                              className="task-photo-remove"
                              onClick={() => this.onRemoveClick(item)}
                            >
                              <i className="remove icon" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {this.props.selectedItem?.rating ? (
                    <div>
                      <Rating rating={this.props.selectedItem.rating} />
                      <div className={'task-card-modal-label2'}>&nbsp;</div>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.props.selectedItem?.answer ? (
                    <div>
                      <div className={'task-card-modal-label2'}>
                        Комментарий вожатого
                      </div>
                      {this.props.selectedItem?.answer}
                    </div>
                  ) : (
                    ''
                  )}

                  {this.state.requestToCheck ? (
                    <Button
                      disabled={
                        this.state.completeSendButtonDisabled ||
                        (this.state.requestToCheck && !this.props.files.length)
                      }
                      id="task-card-modal-action-button"
                      color={'violet'}
                      fluid
                      onClick={() => {
                        this.sendRequestToCheck();
                      }}
                    >
                      Прошу подтвердить выполнение
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.state.requestToDone ? (
                    <Button
                      id="task-card-modal-action-button"
                      color={'violet'}
                      fluid
                      onClick={() => {
                        this.sendRequestToDone();
                      }}
                    >
                      Отправить
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.state.requestToNotDone ? (
                    <Button
                      id="task-card-modal-action-button"
                      color={'violet'}
                      fluid
                      onClick={() => {
                        this.sendRequestToNotDone();
                      }}
                    >
                      Отправить
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.state.requestToNotification ? (
                    <Button
                      disabled={this.state.completeSendButtonDisabled}
                      id="task-card-modal-action-button"
                      color={'violet'}
                      fluid
                      onClick={() => {
                        this.sendRequestToNotification();
                      }}
                    >
                      Отправить
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.state.requestToCancel ? (
                    <Button
                      id="task-card-modal-action-button"
                      color={'violet'}
                      fluid
                      onClick={() => {
                        this.sendRequestToCancel();
                      }}
                    >
                      Отказаться
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Modal.Description>
            </Modal.Content>
            {/*<ModalActions className="task-card-modal-backgroud">*/}

            {/*</ModalActions>*/}
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  let taskStore: TaskStore = state.task;
  let authStore: AuthStore = state.authentication;
  return {
    counselor: authStore.counselor,
    admin: authStore.admin,
    selectedItem: taskStore.selectedItem,
    errorInTask: taskStore.errorInTask,
    operationInProcess: taskStore.operationInProcess,
    files: taskStore.loadedPhotos,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(taskActions, dispatch),
  ...bindActionCreators({ refreshUserInfo }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
