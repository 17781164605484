import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as taskActions from "./../../store/task/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Survay} from "../../store/feedbackType";
import {CurrentConfig} from "../../api/config";
import {AuthStore} from "../../store/baseType";
import {Label } from 'semantic-ui-react'
import Survey from './../../images/Survey.png';


type SurveyRowState = {}

type SurveyRowProp = {
    survey: Survay,
    onClick: Function
}


class SurveyRow extends React.Component<SurveyRowProp, SurveyRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
        
    }

    handleClick() {
    }

    render() {
        return (
            <div id='feedback-row' onClick={(e) => {
                if (e.button === 0) {
                    this.props.onClick(this.props.survey)
                }
            }}>
                {this.props.survey.state === 'Доступно'
                    ?  <Label circular color={"blue"} empty key={"blue"} className={"counterRow"} />
                    :''}
                {(this.props.survey.typeId === 1)
                ?
                <img src={Survey}
                     className="task-photo" alt=""/>
                :
                 <img src={this.basePath + '/downloadFileFeedbackByLink?id=' + this.props.survey.fileInterviewId}
                     className="task-photo" alt=""/>
                }
               
                <div className={"task-body"}>
                    <div className={"task-name"}>
                        {this.props.survey.name}
                    </div>
                   
                    <div>
                        <span className={"task-date"}>Тема: {this.props.survey.surveyType}</span>
                        <span className={"task-state"}>{this.props.survey.state}</span>
                    </div>
                    {(this.props.survey.typeId !== 1)?
                     <div className={"task-point"}>
                        {this.props.survey.point} баллов
                    </div>
                    :''
                    }
                   
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication
    return {
        counselor: authStore.counselor,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRow);