import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Icon } from 'semantic-ui-react'

import heart_pressed from './../../images/heart_pressed.svg';
import heart_onpressed from './../../images/heart_onpressed.svg';

type GiftWishHeartProp ={
    pressed: boolean,
    onClick?: Function
    insidePoster:boolean
}

type GiftWishHeartState = {

}

export class GiftWishHeart extends React.Component<GiftWishHeartProp, GiftWishHeartState>{
    constructor(props: GiftWishHeartProp) {
        super(props);

    }

    render() {
        return (
            <div className={"gift-wish-background " + (this.props.insidePoster ? "inside" : "outside") }>
                <div className="gift-wish-icon">
                    
                    <img src={this.props.pressed ? heart_pressed : heart_onpressed} 
                    style={{width:'28px', height: '28px'}}
                    onClick={() => { this.props.onClick?.(); }} />
                </div>                
            </div>);
    }
}