import React from "react";
import {Icon} from "semantic-ui-react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

type RatingProps = {
    rating?: number,
    OnChange?: Function,
    size?:IconSizeProp,
}

const Rating = (props: RatingProps) => {
    let size: IconSizeProp = props.size ?? 'big';

    return <div style={{display:"inline-flex", paddingTop: "0.3vh"}}>
        <Icon name={!props.rating || props.rating < 1 ? "star outline" : "star"}
              color={"yellow"}
              size={size} onClick={() => {
            if (props.OnChange) {
                props.OnChange(1);
            }
        }}/>
        <Icon name={!props.rating || props.rating < 2 ? "star outline" : "star"}
              color={"yellow"}
              size={size} onClick={() => {
            if (props.OnChange) {
                props.OnChange(2);
            }
        }}/>
        <Icon name={!props.rating || props.rating < 3 ? "star outline" : "star"}
              color={"yellow"}
              size={size} onClick={() => {
            if (props.OnChange) {
                props.OnChange(3);
            }
        }}/>
        <Icon name={!props.rating || props.rating < 4 ? "star outline" : "star"}
              color={"yellow"}
              size={size} onClick={() => {
            if (props.OnChange) {
                props.OnChange(4);
            }
        }}/>
        <Icon name={!props.rating || props.rating < 5 ? "star outline" : "star"}
              color={"yellow"}
              size={size} onClick={() => {
            if (props.OnChange) {
                props.OnChange(5);
            }
        }}/>
    </div>;
};

export default Rating;