import "./types";
import {
    AUTH_USER_SUCCESS,
    AUTH_USER_PROCESS,
    AUTH_USER_FAIL,
    RESTORE_USER_EMAIL,
    RESTORE_ERROR,
    RESTORE_USER_SMS,
    REGISTER_USER,
    REGISTER_ERROR,
    USER_CHANGE_PASSWORD,
    USER_CHANGE_PASSWORD_PROCESS,
    USER_CHANGE_PASSWORD_ERROR,
    PHOTO_UPLOADED,
    PHOTO_UPLOADED_ERROR,
    PHOTO_UPLOADED_PROCESS,
    PHOTO_DOWNLOADED,
    REGISTER_PROCESS,
    SAVE_USER,
    SAVE_ERROR,
    SAVE_PROCESS, CLEAR_CARD_STATE, CLEAR_CARD_STATE_CLEAR, RESET_WELCOME_MODE, LOGIN_USER,
    UPDATE_THEME_ERROR,
    UPDATE_THEME_SUCCESS,
    UPDATE_THEME_PROCESS,

    AUTHORIZE_CHAT_SUCCES,
    AUTHORIZE_CHAT_ERROR,
    AUTHORIZE_CHAT_PROCESS,
    APPROVE_RULE,
    APPROVE_RULE_ERROR,
    APPROVE_RULE_PROCESS

} from "./types";
import {AuthStore, User, TokenInfo} from '../baseType'
import {Reducer} from "redux";
import {initAxios} from '../../api/auth'

const initialState = () => {
    let user : User = JSON.parse(localStorage.getItem("user") || '{}') || {};
    let tokenInfo: TokenInfo = JSON.parse(localStorage.getItem("token") || '{}') || {};
    initAxios(tokenInfo.access_token);
    return {
        user: user,
        isAuthUser: !(!(tokenInfo.access_token)),
        counselor: user.counselor || false,
        admin: user.admin || false,
        isLoading: false,
        authIoProcess: false,

        tokenInfo: {} as TokenInfo,
        isTokenInfoProcess:false,
        isTokenInfoError: ''
    } as AuthStore | undefined;
};

const authentication: Reducer = (state: AuthStore | undefined = initialState(), action: any) => {
    switch (action.type) {

        case AUTHORIZE_CHAT_SUCCES: {
            return {
                ...state,
                tokenInfo: action.data,
                isTokenInfoProcess: false,
                authenticated: action.authenticated,
                errorMessage: action.errorMessage,
                authIoProcess: false,
                clearCardState: false,
                isAfterLogin: true
            };
        }
        case AUTHORIZE_CHAT_PROCESS: {
            return {
                ...state,
                isTokenInfoProcess: true
            };
        }
        case AUTHORIZE_CHAT_ERROR: {
            return {
                ...state,
                isTokenInfoProcess: false,
                isTokenInfoError: action.auth.errorMessage,
                authenticated: action.auth.authenticated,
                errorMessage: action.auth.errorMessage,
                currentUser: action.auth.currentUser
            };
        }

        case CLEAR_CARD_STATE: {
            return {
                ...state,
                clearCardState: true
            };
        }
        case CLEAR_CARD_STATE_CLEAR: {
            return {
                ...state,
                clearCardState: false
            };
        }

        case AUTH_USER_PROCESS: {
            return {
                ...state,
                isAuthUser: false,
                user: undefined,
                photoUser: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                restoreRequested: undefined,
                errorMessage: undefined,
                restoreErrorMessage: undefined,
                registrationErrorMessage: undefined,
                authIoProcess: true,
                clearCardState: false
            };
        }
        case AUTH_USER_FAIL: {
            return {
                ...state,
                isAuthUser: false,
                errorMessage: action.auth.errorMessage,
                user: undefined,
                photoUser: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                restoreErrorMessage: undefined,
                registrationErrorMessage: undefined,
                restoreRequested: undefined,
                authIoProcess: false
            };
        }
        case AUTH_USER_SUCCESS: {
            return {
                ...state,
                isAuthUser: action.auth.authenticated,
                user: action.auth.currentUser,
                roles: action.auth.roles,
                counselor: action.auth.currentUser.counselor,
                admin: action.auth.currentUser.admin,
                photoUser: action.auth.currentUser.photo,
                restoreRequested: undefined,
                errorMessage: undefined,
                restoreErrorMessage: undefined,
                registrationErrorMessage: undefined,
                authIoProcess: false,
                clearCardState: false,
            };
        }

        case RESET_WELCOME_MODE: {
            return {
                ...state,
                isAfterLogin: false
            };
        }

        case LOGIN_USER: {
            return {
                ...state,
                isAfterLogin: true
            };
        }

        case SAVE_USER: {
            return {
                ...state,
                isAuthUser: action.auth.authenticated,
                user: action.auth.currentUser,
                counselor: action.auth.currentUser.counselor,
                admin: action.auth.currentUser.admin,
                photoUser: action.auth.currentUser.photo,
                saveErrorMessage: undefined,
                saveRequested: false,
            };
        }
        case SAVE_ERROR: {
            return {
                ...state,
                saveErrorMessage: action.auth.errorMessage,
                saveRequested: false,
            };
        }
        case SAVE_PROCESS: {
            return {
                ...state,
                saveErrorMessage: undefined,
                saveRequested: true,
            };
        }

        case USER_CHANGE_PASSWORD_ERROR: {
            return {
                ...state,
                errorMessage: action.auth.errorMessage,
                authIoProcess: false
            };
        }
        case USER_CHANGE_PASSWORD: {
            return {
                ...state,
                errorMessage: undefined,
                authIoProcess: false
            };
        }
        case USER_CHANGE_PASSWORD_PROCESS: {
            return {
                ...state,
                errorMessage: undefined,
                authIoProcess: true
            };
        }
        
        case RESTORE_ERROR: {
            return {
                ...state,
                isAuthUser: false,
                user: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                restoreRequested: false,
                restoreErrorMessage: action.auth.errorMessage,
                registrationErrorMessage: undefined,
                errorMessage: undefined,
                authIoProcess: false

            };
        }
        case RESTORE_USER_EMAIL: {
            return {
                ...state,
                isAuthUser: false,
                user: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                restoreRequested: true,
                registrationErrorMessage: undefined,
                restoreErrorMessage: undefined,
                errorMessage: undefined,
                authIoProcess: false
            };
        }
        case RESTORE_USER_SMS: {
            return {
                ...state,
                isAuthUser: false,
                user: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                restoreRequested: true,
                restoreErrorMessage: undefined,
                errorMessage: undefined,
                authIoProcess: false
            };
        }

        case PHOTO_UPLOADED: {
            return {
                ...state,
                user: {
                    ...state?.user,
                    photo: action.auth.photo + '&__t=' + new Date().getTime().toString(),
                },
                authIoProcess: false
            };
        }
        case PHOTO_UPLOADED_PROCESS: {
            return {
                ...state,
                user: {
                    ...state?.user,
                    photo: undefined,
                },
                authIoProcess: true
            };
        }
        case PHOTO_UPLOADED_ERROR: {
            return {
                ...state,
                photoUser: undefined,
                authIoProcess: false
            };
        }
        case PHOTO_DOWNLOADED: {
            return {
                ...state,
                photoUser: action.auth.photoUrl
            };
        }

        case REGISTER_ERROR: {
            return {
                ...state,
                isAuthUser: false,
                user: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                registrationRequested: false,
                registrationErrorMessage: action.auth.errorMessage,
                restoreErrorMessage: undefined,
                errorMessage: undefined,
                authIoProcess: false
            };
        }
        case REGISTER_PROCESS: {
            return {
                ...state,
                authIoProcess: true
            };
        }
        case REGISTER_USER: {
            return {
                ...state,
                isAuthUser: false,
                message: action.auth.message,
                user: undefined,
                roles: undefined,
                counselor: undefined,
                admin: undefined,
                registrationRequested: true,
                registrationErrorMessage: undefined,
                restoreErrorMessage: undefined,
                errorMessage: undefined,
                authIoProcess: false,
                isAfterLogin: true
            };
        }
        case UPDATE_THEME_SUCCESS: {
            return {
                ...state,
                authIoProcess: false,    
            };
        }
        case UPDATE_THEME_ERROR: {
            return {
                ...state,
                authIoProcess: false
            };
        }
        case UPDATE_THEME_PROCESS: {
            return {
                ...state,
                authIoProcess: true
                
            };
        }

        case APPROVE_RULE: {
            return {
                ...state,
                isAuthUser: action.auth.authenticated,
                user: action.auth.currentUser,
                counselor: action.auth.currentUser.counselor,
                admin: action.auth.currentUser.admin,
                photoUser: action.auth.currentUser.photo,
                saveErrorMessage: undefined,
                saveRequested: false,
            };
        }
        case APPROVE_RULE_ERROR: {
            return {
                ...state,
                saveErrorMessage: action.auth.errorMessage,
                saveRequested: false,
            };
        }
        case APPROVE_RULE_PROCESS: {
            return {
                ...state,
                saveErrorMessage: undefined,
                saveRequested: true,
            };
        }

        default:
            return state;
    }
};

export default authentication;
