import axios from "axios";
import {CurrentConfig} from './config';
import {Feedback, FeedbackListFilter,HandlingFilter} from "../store/feedbackType";

const feedbackApi = {
    getFeedbacksList: (filter: HandlingFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/feedback/list`,filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    getFeedbackTitles: (onlySupport: boolean, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/feedback/getFeedbackTitles?onlySupport=${onlySupport}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    // отправка сообщения
    sendFeedback: (data: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/feedback/Save`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}

export default feedbackApi;