import {
    RATING_LIST_SUCCESS,
    RATING_LIST_FAILED, 
    RATING_LIST_PROCESS,
    RATING_SUCCESS,
    RATING_FAILED, 
    RATING_PROCESS,
    RATING_PARTY_LIST_SUCCESS,
    RATING_PARTY_LIST_FAILED, RATING_PARTY_LIST_PROCESS, RATING_USER_SELECT
} from "./types";

import {Reducer} from "redux";
import {RatingUser,RatingStore} from "../ratingType";

const initialState = () => {
    return {
        ratingUser:{
            likeCount: 0,
            superLikeCount: 0
        } as RatingUser,
        errorInList: '',
        listInProcess: false, 
    } as RatingStore;
};

const ratingStore: Reducer = (state: RatingStore = initialState(), action: any) => {
    switch (action.type) {
        case RATING_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcessRating: false
            };
        }
        case RATING_SUCCESS: {
            
            return {
               
                ...state,
                listInProcessRating: false,
                ratingUser: action.data
            };
        }
        case RATING_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcessRating: true
            };
        }
        case RATING_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcessRating: false
            };
        }
        case RATING_LIST_SUCCESS: {
            return {
                ...state,
                listInProcessRating: false,
                list: action.list
            };
        }
        case RATING_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcessRating: true
            };
        }
        
        case RATING_PARTY_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInPartyProcess: false
            };
        }
        case RATING_PARTY_LIST_SUCCESS: {
            return {
                ...state,
                listInPartyProcess: false,
                listParty: action.list
            };
        }
        case RATING_PARTY_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInPartyProcess: true
            };
        }
        case RATING_USER_SELECT: {
            return {
                ...state,
                selectedUser: action.data
            };
        }         
        
        default:
            return state;
    }
};

export default ratingStore;