import React from "react";
import {connect} from "react-redux";
import {Redirect, Route} from "react-router";

const AuthRoute = (props: any) => {
    const {isAuthUser} = props;
    if (isAuthUser) return <Redirect to="/main"/>;

    return <Route {...props} />;
};

const mapStateToProps = (state: any) => {
    return {
        isAuthUser: state.authentication.isAuthUser
    }
}

export default connect(mapStateToProps)(AuthRoute);