
import notificationApi from "../../api/notification";
import notificationSendApi from "../../api/notificationSend";
import {clearReduxStateInternal} from "../authentication/actions";
import {ListFilter} from "../approvingType";
import {Notification, NotificationSend} from "../notificationType";
import {CamperTask, ProcessOperationDto} from "../taskType";
import {
    NOTIFICATION_COUNT,
    NOTIFICATION_FAILED, NOTIFICATION_LIST_FAILED,
    NOTIFICATION_LIST_PROCESS, NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_PROCESS,
    NOTIFICATION_SELECT,
    NOTIFICATION_SEND_SELECT,
    NOTIFICATION_SUCCESS, NOTIFICATION_VIEW_SUCCESS,
    NOTIFICATION_LIST_SEND_SUCCESS,
    NOTIFICATION_LIST_SEND_FAILED,
    NOTIFICATION_LIST_SEND_PROCESS,
    NOTIFICATION_OFFER_TASK_COUNT,
    NOTIFICATION_OFFER_CHANGE_TASK_COUNT,
    NOTIFICATION_SYRVEY_COUNT
} from "./types";

// получение списка уведомлений
export const listNotification = (filter: ListFilter) => (dispatch: any) => {
    notificationApi.list(filter, (status: number, data: Notification[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: NOTIFICATION_LIST_SUCCESS,
                data
            });
            
            notificationCountInternal(dispatch);
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: NOTIFICATION_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка уведомлений'
            });
        }
    });

    return dispatch({
        type: NOTIFICATION_LIST_PROCESS
    });
}

// получение списка уведомлений
export const notificationCountInternal = (dispatch: any) =>  {
    notificationApi.notificationCount((status: number, data: Notification[] | string | undefined) => {
        if (status === 200) {
            return dispatch({
                type: NOTIFICATION_COUNT,
                count: data
            });
        } else {
            return dispatch({
                type: NOTIFICATION_COUNT,
                count: 0
            });
        }
    });
}

export const notificationOfferTaskCountInternal = (dispatch: any) =>  {
    notificationApi.newNotificationCount(0,(status: number, data: Notification[] | string | undefined) => {
        if (status === 200) {
            return dispatch({
                type: NOTIFICATION_OFFER_TASK_COUNT,
                count: data
            });
        } else {
            return dispatch({
                type: NOTIFICATION_OFFER_TASK_COUNT,
                count: 0
            });
        }
    });
}

export const notificationOfferTaskChangeCountInternal = (dispatch: any) =>  {
    notificationApi.newNotificationCount(1,(status: number, data: Notification[] | string | undefined) => {
        if (status === 200) {
            return dispatch({
                type: NOTIFICATION_OFFER_CHANGE_TASK_COUNT,
                count: data
            });
        } else {
            return dispatch({
                type: NOTIFICATION_OFFER_CHANGE_TASK_COUNT,
                count: 0
            });
        }
    });
}

export const notificationgSurveyCount = (dispatch: any) => {
    notificationApi.notificationSurveyCount((status: number, data: number | string ) => {
        if (status === 200) {
            return dispatch({
                type: NOTIFICATION_SYRVEY_COUNT,
                count: data
            });
        } else {
            return dispatch({
                type: NOTIFICATION_SYRVEY_COUNT,
                count: 0
            });
        }
    });
}

/// ответ на уведомление
export const answerOnNotification = (model: ProcessOperationDto) => (dispatch: any) => {
    notificationApi.answerNotification(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: NOTIFICATION_SUCCESS,
                data
            });

            notificationCountInternal(dispatch);
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: NOTIFICATION_FAILED,
                errorMessage: data || 'Ошибка сохранение ответа на уведомление'
            });
        }
    });

    return dispatch({
        type: NOTIFICATION_PROCESS
    });
}

/// выбрать уведомление
export const selectNotification = (model: Notification) => (dispatch: any) => {
    return dispatch({
        type: NOTIFICATION_SELECT,
        data: model
    });
}
/// выбрать уведомление (пуш)
export const selectNotificationSend = (model: NotificationSend) => (dispatch: any) => {
    return dispatch({
        type: NOTIFICATION_SEND_SELECT,
        data: model
    });
}
/// просмотреть ответ от сопровождающего
export const viewCounselorAnswer = (notificationId: number) => (dispatch: any) => {
    notificationApi.viewAnswer(notificationId, (status: number) => {
        if (status === 200) {
            dispatch({
                type: NOTIFICATION_VIEW_SUCCESS
            });
            notificationCountInternal(dispatch);
            
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: NOTIFICATION_FAILED,
                errorMessage: 'Ошибка при просмотре ответа'
            });
        }
    });
}

// получение уведомлений (Пушей)
export const getNotificationSend = (filter: ListFilter) => (dispatch: any) => {
    notificationSendApi.list(filter,(status: number, data: NotificationSend[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: NOTIFICATION_LIST_SEND_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: NOTIFICATION_LIST_SEND_FAILED,
                errorMessage: data || 'Ошибка получения уведомлений'
            });
        }
    });

    return dispatch({
        type: NOTIFICATION_LIST_SEND_PROCESS
    });
}