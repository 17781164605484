import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Input} from 'semantic-ui-react'
import * as notificationActions from "./../../store/notification/actions"
import {clearListStateClear, refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import _ from "lodash";
import NotificationRow from "./NotificationRow";
import NotificationSendRow from "./NotificationSendRow";
import './Notification.css';
import NotificationCard from "./NotificationCard";
import NotificationSendCard from "./NotificationSendCard";
import {Notification, NotificationStore, NotificationSend} from "../../store/notificationType";
import {ListFilter} from "../../store/approvingType";
import {AuthStore, User} from "../../store/baseType";
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';

type NotificationState = {
    filter: string,
    /* видно карточку */
    cardMode: boolean,
    cardNotification: boolean
}

type NotificationProp = {
    listNotification: Function,
    refreshUserInfo: Function,
    selectNotification: Function,
    clearCardState: boolean,
    clearListStateClear: Function,
    listInProcess: boolean,
    list: Notification[] | undefined,
    errorInList: string | undefined,
    viewCounselorAnswer: Function,

    selectNotificationSend: Function,
    getNotificationSend: Function,
    listSend: NotificationSend[] | undefined,
    errorInListSend: string | undefined,
    operationInProcessSend: boolean,

    user: User;
}


class NotificationList extends React.Component<NotificationProp, NotificationState> {
    interval: any = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            cardNotification:false,
            filter: '',
            cardMode: false
        }
    }

    searchItems() {
        let filter: ListFilter = {
            t: this.state.filter,
        };

        this.setState({...this.state});
        this.props.listNotification(filter);
        this.props.getNotificationSend(filter);
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        this.searchItems();
    }

    componentDidUpdate(prevProps: Readonly<NotificationProp>, prevState: Readonly<NotificationState>, snapshot?: any) {
        if (!prevProps.clearCardState && this.props.clearCardState){
            this.setState({
                ...this.state,
                cardMode: false
            });
        }

        if(this.props.clearCardState){
            this.props.clearListStateClear();
        }
    }
    
    render() {
        return (
            <div className="notificationBlock">
                {(!this.state.cardMode) ?
                    <div>
                        <SearchBar value={this.state.filter} onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                            let text = data.value;
                            this.setState({
                                ...this.state,
                                filter: text
                            });

                            if (this.interval) {
                                clearInterval(this.interval);
                                this.interval = undefined;
                            }

                            this.interval = setInterval(() => {
                                clearInterval(this.interval);
                                this.searchItems();
                                this.interval = undefined;
                            }, 600);
                        }} />
                        <div className="ui items segments" style={{ margin: "20px" }}>
                            <div
                                className={this.props.listInProcess && this.props.operationInProcessSend ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                <div className="ui text loader">Загрузка...</div>
                            </div>
                            {_.map(this.props.list, (item: any) => <NotificationRow notification={item} onClick={(item: Notification) => {
                                this.props.selectNotification(item);
                                this.props.viewCounselorAnswer(item.id)
                                this.setState({
                                    ...this.state,
                                    cardMode: true,
                                    cardNotification: false
                                });
                            }}/>)}
                            {_.map(this.props.listSend, (item: any) => <NotificationSendRow notificationSend={item} onClick={(item: NotificationSend) => {
                                this.props.selectNotificationSend(item);
                                this.setState({
                                    ...this.state,
                                    cardMode: true,
                                    cardNotification: true
                                });
                            }}/>)}
                            {(!this.props.list || this.props.list?.length === 0 && this.props.listSend?.length === 0) ?
                                <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
                        </div>
                    </div>
                    :
                    <div>
                        {(!this.state.cardNotification)
                        ?
                            <NotificationCard backToList={()=>{
                                this.setState({
                                    ...this.state,
                                    cardMode: false,
                                    cardNotification: false
                                });
                            }} />
                        :
                            <NotificationSendCard backToList={()=>{
                                this.setState({
                                    ...this.state,
                                    cardMode: false,
                                    cardNotification:true
                                });
                            }} />
                        }
                       
                    </div>  
                }
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let notificationStore: NotificationStore = state.notification;
    let authStore: AuthStore = state.authentication;
    return {
        listInProcess: notificationStore.listInProcess,
        list: notificationStore.list,
        errorInList: notificationStore.errorInList,
        clearCardState: authStore.clearCardState,
        listSend: notificationStore.listSend,
        errorInListSend: notificationStore.errorInListSend,
        operationInProcessSend: notificationStore.operationInProcessSend,
        user: authStore.user ?? {},
    }
}

const mapDispatchToProps = (dispatch: any) => ({  
    ...bindActionCreators(notificationActions, dispatch),
    ...bindActionCreators({refreshUserInfo, clearListStateClear}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);