import axios from "axios";
import {CurrentConfig} from './config';
import {ListFilter} from "../store/approvingType";

const notificationSendApi = {
    list: (filter: ListFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/listSend`,filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    
}

export default notificationSendApi;