import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { regServicesWorker } from "./registerServicesWorker";
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';

import { SemanticToastContainer, toast } from 'react-semantic-toasts-2';
import 'react-semantic-toasts-2/styles/react-semantic-alert.css';

ReactDOM.render(
    <React.StrictMode>
        {/*<Notifications />*/}
        <SemanticToastContainer position="top-right" maxToasts={3} />
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

regServicesWorker();


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
        console.log(event);
        //addNotification({
        //    title: '',
        //    message: event.data,
        //    theme: 'light',
        //    duration: 2000,
        //});

        toast({
            type: 'info',
            title: '',
            description: event.data,
            animation: 'bounce',
            time: 4000,
            color: 'violet',
            size: 'small',
        });
    });
}
