import {
    TASK_LIST_COMPLITED_FAILED,
    TASK_LIST_COMPLITED_SUCCESS,
    TASK_LIST_COMPLITED_PROCESS,
    LIKE_LIST_COMPLITED_SUCCESS,
    LIKE_LIST_SUCCESS,
    LIKE_LIST_FAILED,
    LIKE_LIST_PROCESS,
    NOTIFICATIONNEWS_LIST_SUCCESS,
    NOTIFICATIONNEWS_LIST_FAILED,
    NOTIFICATIONNEWS_LIST_PROCESS
} from "./types";

import {Reducer} from "redux";
import {TaskComplited,TaskStoreMenu,MassNotificationNew } from "../homeType";

const initialState = () => {
    return {
        list: [] as TaskComplited[],
        listNotificationNews: [] as MassNotificationNew[],
        errorlistNotificationNews: '',
        errorInList: '',
        listInProcess: false,
        likeProcess: false, 
        listInProcessNotificationNews:false
     
    } as TaskStoreMenu;
};

const menuStore: Reducer = (state: TaskStoreMenu = initialState(), action: any) => {
    switch (action.type) {
        case TASK_LIST_COMPLITED_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcess: false
            };
        }
        case TASK_LIST_COMPLITED_SUCCESS: {
            return {
                ...state,
                listInProcess: false,
                list: state.list.concat(action.data) 
            };
        }
        case TASK_LIST_COMPLITED_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcess: true
            };
        }
        case LIKE_LIST_COMPLITED_SUCCESS:{
            return {
            ...state,
            list: state.list.map(todo => todo.id === action.data.Id ?
                action.data:
                todo),
                likeProcess: true
            };
        }
        case LIKE_LIST_FAILED: {
            return {
                ...state,
                likeProcess:false
            };
        }
        case LIKE_LIST_SUCCESS: {
            return {
                ...state,
                //countLike: (action.data.IsActive)?state.countLike+=1:state.countLike-=1,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data:
                    todo),
                    likeProcess: true
            };
        }
        case LIKE_LIST_PROCESS: {
            return {
                ...state,
                likeProcess:false
            };
        }
        case NOTIFICATIONNEWS_LIST_FAILED: {
            return {
                ...state,
                errorlistNotificationNews: action.errorMessage,
                listInProcessNotificationNews: false
            };
        }
        case NOTIFICATIONNEWS_LIST_SUCCESS: {
            return {
                ...state,
                listInProcessNotificationNews: false,
                listNotificationNews: action.data
            };
        }
        case NOTIFICATIONNEWS_LIST_PROCESS: {
            return {
                ...state,
                errorlistNotificationNews: '',
                listInProcessNotificationNews: true
            };
        }
        default:
            return state;
    }
};

export default menuStore;