import React from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import { CustomObject, Highlight, Range } from 'react-highlight-within-textarea/lib/esm/highlightToStrategyAndComponents';
import { Selection } from 'react-highlight-within-textarea/lib/esm/Selection';
import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Dropdown, TextArea, Input, Label, ModalActions, Form, Icon, Segment, Dimmer, Loader, Image, SemanticICONS } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import { CurrentConfig } from "../../../api/config";
import deniedWordsApi from '../../../api/deniedWords';
import { deniedWord } from '../../../store/deniedWord';
import './MarkableTextArea.css'

type MarkableTextAreaType = 'area' | 'input'

export type MarkableTextAreaState = {
    highlights?: Highlight[],
}

type MarkableTextAreaProp = {
    placeholder?: string
    text: string,
    onChange?: Function,
    onRangesUpdated?: Function,
    type: MarkableTextAreaType
    className?: string,
    verifyDelay: number,
    icon?: SemanticICONS,
    iconSize?: IconSizeProp
}

export class MarkableTextArea extends React.Component<MarkableTextAreaProp, MarkableTextAreaState> {
    delayedVerification: any;


    constructor(props: MarkableTextAreaProp) {
        super(props);

        //let regexes = new Array();

        //props.wordsToMark?.forEach((w) => {
        //    //regexes.push({ highlight: new RegExp('(^|\\s|[^\\u0400-\\u04FF]|\\b)' + w + '(?=\\s|[^\\u0400-\\u04FF]|\\b|$)', 'gu'), className: "red-highlight" } as CustomObject)
        //});
        this.state = {
        }

    }

    updateHighlights(status: number, data: deniedWord[]) {
        if (status === 200) {
            let ranges: Highlight[] = data.map(word => ({ highlight: [word.range.start, word.range.end], className: "red-highlight" }) as CustomObject);
            this.setState({ highlights: ranges });
            this.props.onRangesUpdated?.(ranges);
        }
    }

    componentDidMount() {
        let formData = new FormData();
        formData.append('text', this.props.text);
        deniedWordsApi.verify(formData, (status: number, data: deniedWord[]) => this.updateHighlights(status, data));
    }


    render() {
        return (
            <div className={"main-area" + " " + (this.props.className ?? "")}>

                {this.props.icon ? <Icon size={this.props.iconSize ?? undefined} className="inner-icon" name={this.props.icon} /> : ""}
                <div className={this.props.type} >
                    <HighlightWithinTextarea
                        placeholder={this.props.placeholder ?? ""}
                        value={this.props.text}
                        onChange={(value: string, selection: Selection | undefined) => {

                            if ((this.props.text.length > value.length) && this.state.highlights) {
                                this.setState({ highlights: undefined });
                            }

                            if (this.props.text != value) {
                                if (this.props.type === "input") {
                                    value = value.replace('\n', '').replace('\r', '');
                                }

                                if (this.delayedVerification) {
                                    clearTimeout(this.delayedVerification);
                                }

                                this.delayedVerification = setTimeout(() => {
                                    let formData = new FormData();
                                    formData.append('text', value);
                                    deniedWordsApi.verify(formData, (status: number, data: deniedWord[]) => this.updateHighlights(status, data));
                                }, this.props.verifyDelay);

                                this.props.onChange?.(value)
                            }
                        }}
                        highlight={this.state.highlights}
                    />
                    <textarea  className="opacity-input" value={this.props.text} onChange={(e) => {
                        let value = e.target.value;
                        if ((this.props.text.length > value.length) && this.state.highlights) {
                            this.setState({ highlights: undefined });
                        }

                        if (this.props.text != value) {
                            if (this.props.type === "input") {
                                value = value.replace('\n', '').replace('\r', '');
                            }

                            if (this.delayedVerification) {
                                clearTimeout(this.delayedVerification);
                            }

                            this.delayedVerification = setTimeout(() => {
                                let formData = new FormData();
                                formData.append('text', value);
                                deniedWordsApi.verify(formData, (status: number, data: deniedWord[]) => this.updateHighlights(status, data));
                            }, this.props.verifyDelay);

                            this.props.onChange?.(value)
                        }
                    }} />
                </div>
            </div>
        );
    }
}