export const CHATTITLE_GET_SUCCESS = "CHATTITLE_GET_SUCCESS";
export const CHATTITLE_GET_FAILED = "CHATTITLE_GET_FAILED";
export const CHATTITLE_GET_PROCESS = "CHATTITLE_GET_PROCESS";
export const CHATTITLEONE_GET_SUCCESS = "CHATTITLEONE_GET_SUCCESS";

export const SELECT_CONVERSATION_SUCCESS = "SELECT_CONVERSATION_SUCCESS";
export const CLEAR_CONVERSATION_SUCCESS = "CLEAR_CONVERSATION_SUCCESS";
//Получить учасников
export const MEMBERS_GET_SUCCESS = "MEMBERS_GET_SUCCESS";
export const MEMBERS_GET_FAILED = "MEMBERS_GET_FAILED";
export const MEMBERS_GET_PROCESS = "MEMBERS_GET_PROCESS";

//Типы для чат сервиса
export const CONVERSATION_GET_SUCCESS = "CONVERSATION_GET_SUCCESS";
export const CONVERSATION_GET_FAILED = "CONVERSATION_GET_FAILED";
export const CONVERSATION_GET_PROCESS = "CONVERSATION_GET_PROCESS";

//Создать комнату
export const CONVERSATION_CREATE_SUCCESS = "CONVERSATION_CREATE_SUCCESS";
export const CONVERSATION_CREATE_FAILED = "CONVERSATION_CREATE_FAILED";
export const CONVERSATION_CREATE_PROCESS = "CONVERSATION_CREATE_PROCESS";
//Закрыть комнату
export const CONVERSATION_CLOSE_SUCCESS = "CONVERSATION_CLOSE_SUCCESS";
//Получение сообщений по чату
export const MESSAGE_GET_SUCCESS = "MESSAGE_GET_SUCCESS";
export const MESSAGE_GET_FAILED = "MESSAGE_GET_FAILED";
export const MESSAGE_GET_PROCESS = "MESSAGE_GET_PROCESS";
//Получение сообщения по чату
export const MESSAGE_SET_SUCCESS = "MESSAGE_SET_SUCCESS";
export const MESSAGE_SET_FAILED = "MESSAGE_SET_FAILED";
export const MESSAGE_SET_PROCESS = "MESSAGE_SET_PROCESS";

export const MESSAGE_READED_SET_SUCCESS = "MESSAGE_READED_SET_SUCCESS";
export const MESSAGE_READED_SET_FAILED = "MESSAGE_READED_SET_FAILED";

//Подключение к хабу сообщений
export const HUB_CONNECTION_SUCCESS = "HUB_CONNECTION_SUCCESS";
export const HUB_CONNECTION_FAILED = "HUB_CONNECTION_FAILED";
export const HUB_CONNECTION_PROCESS = "HUB_CONNECTION_PROCESS";
//Отключение от хаба сообщений
export const HUB_DISCONNECT_SUCCESS = "HUB_DISCONNECT_SUCCESS";
export const HUB_DISCONNECT_FAILED = "HUB_DISCONNECT_FAILED";
export const HUB_DISCONNECT_PROCESS = "HUB_DISCONNECT_PROCESS";
export const HUB_CONNECTION_MESSAGE_SUCCESS = "HUB_CONNECTION_MESSAGE_SUCCESS";
//Загрузка Файлов
export const FILE_SET_SUCCESS = "FILE_SET_SUCCESS";
export const FILE_SET_FAILED = "FILE_SET_FAILED";
export const FILE_SET_PROCESS  = "FILE_SET_PROCESS";
//Подгрузка сообщений
export const MESSAGE_GET_UPLOAD_SUCCESS = "MESSAGE_GET_UPLOAD_SUCCESS";
//Получение количества сообщений
export const MSSAGE_GET_COUNT_UNREAD_SUCCESS = "MSSAGE_GET_COUNT_UNREAD_SUCCESS"


