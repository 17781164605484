import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as taskActions from "./../../store/task/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CamperTask, TaskStore} from "../../store/taskType";
import {CurrentConfig} from "../../api/config";
import {AuthStore} from "../../store/baseType";

import * as ratingActions from "./../../store/rating/actions"
import Rectangle from './../../images/Rectangle.svg';
import rectangle from './../../images/rectangle.png';

let moment = require('moment');


type TaskRowState = {}

type TaskRowProp = {
    task: CamperTask,
    counselor?: boolean,
    onClick: Function,
    onEdit: Function,
    admin: boolean,
}


class TaskRow extends React.Component<TaskRowProp, TaskRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
    }

    handleClick() {
    }

    render() {
        return (
            <div className="ui segment ui-container">
                {(this.props.admin && this.props.task.stateId === 6)?
                    <span>
                        <i className="big blue edit outline icon" style={{float: 'right'}} onClick={(e) => {
                            if (e.button === 0) {
                                this.props.onEdit(this.props.task)
                            }
                        }}></i>
                    </span> 
                    :"" 
                }
                
                <div  onClick={(e) => {
                    if (e.button === 0) {
                        this.props.onClick(this.props.task)
                    }
                }}>
                     <img src={this.basePath + '/downloadFileByLink?id=' + this.props.task.boutTask.linkId}
                     className="task-photo ui-image" alt=""/>
                    <div className={"task-body"}>
                        <div>
                                <span className={"task-name"}>
                                    {this.props.task?.taskSnapshot ?
                                            JSON.parse(this.props.task?.taskSnapshot ?? null)?.Name
                                            :this.props.task.boutTask.name}
                                </span>
                        </div>
                   
                    
                        {this.props.counselor === true ? <div>
                            {this.props.task?.taskSnapshot ?
                                JSON.parse(this.props.task?.taskSnapshot ?? null)?.Description
                                :
                                this.props.task.boutTask.description}
                        </div> : ""}

                        <div>{this.props.task.camper?.name}</div>

                        {this.props.counselor === true && this.props.task.camper && this.props.task.camper.party ? <div>
                            Отряд {this.props.task.camper.party.sortOrder}
                        </div> : ""}

                        <div>
                            <span
                                className={"task-date"}>Начало: {this.props.task.taskDate}
                            </span>
                            <span className={"task-state"}>{this.props.task.state.name}
                            </span>
                        </div>
                        <div className={"task-point task-row-point"}>
                            {this.props.task?.price < 0 ? this.props.task.boutTask.price : this.props.task?.price} баллов
                        </div>
                    </div>
                    
                </div>    
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication
    return {
        counselor: authStore.counselor,
        admin: authStore.admin,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskRow);