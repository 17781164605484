import axios from "axios";
import {CurrentConfig} from './config';
import {ListGiftFilter, ListGiftReservedFilter, ProcessGiftReserve, ProcessSuperGiftReserve} from "../store/giftType";

const giftApi = {
    list: (filter: ListGiftFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/list`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    reserve: (model: ProcessGiftReserve, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/reserve`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    reserveSuperGift: (model: ProcessSuperGiftReserve, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/superGiftReserve`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    addToWishList: (giftId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/addGiftToWishList/${giftId}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    addLookToHistory: (giftId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/addGiftLookToHistory/${giftId}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    deleteFromWishList: (giftParameterId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/deleteGiftFromWishList/${giftParameterId}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    rating: (id: number, rating: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/rating?id=${id}&rating=${rating}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },    
    cancel: (id: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/cancel?id=${id}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    listReserved: (filter: ListGiftReservedFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/listReserved`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    getGiftCategories: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/getGiftCategories`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    confirmReceiptOfGifts: (model: number[], callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/confirmReceiptOfGifts`, model).then(
        response => {
            callback(response.status, response.data);
        },
        (error) => {
            let response = error || {};
            response = response.response || {};
            callback(response.status, response.data);
        });
    },
    confirmReceiptOfCamper: (accountId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/gift/confirmReceiptOfCamper/${accountId}`).then(
        response => {
            callback(response.status, response.data);
        },
        (error) => {
            let response = error || {};
            response = response.response || {};
            callback(response.status, response.data);
        });
    }
}

export default giftApi;