import axios from "axios";
import {ApprovingModel, ListFilter} from "../store/approvingType";
import { RegisterChildInfo } from "../store/baseType";
import {CurrentConfig} from './config';

const approvingApi = {

    list: (filter: ListFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/approving/list`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    approving: (model: ApprovingModel, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/approving/approve`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    registerChild: (model: RegisterChildInfo, callback: Function) => {
        let basePath = CurrentConfig.get().Api;
        axios.post(`${basePath}/approving/registerChild`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}

export default approvingApi;