export const CLEAR_REDUX_STATE = "CLEAR_REDUX_STATE";

export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAIL = "AUTH_USER_FAIL";
export const AUTH_USER_PROCESS = "AUTH_USER_PROCESS";

export const RESTORE_USER_SMS = "RESTORE_USER_SMS";
export const RESTORE_USER_EMAIL = "RESTORE_USER_EMAIL";
export const RESTORE_ERROR = "RESTORE_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_PROCESS = "REGISTER_PROCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const PHOTO_UPLOADED_PROCESS = "PHOTO_UPLOADED_PROCESS";
export const PHOTO_UPLOADED = "PHOTO_UPLOADED";
export const PHOTO_UPLOADED_ERROR = "PHOTO_UPLOADED_ERROR";
export const PHOTO_DOWNLOADED = "PHOTO_DOWNLOADED";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";
export const USER_CHANGE_PASSWORD_PROCESS = "USER_CHANGE_PASSWORD_PROCESS";

export const SAVE_USER = "SAVE_USER";
export const SAVE_PROCESS = "SAVE_PROCESS";
export const SAVE_ERROR = "SAVE_ERROR";

export const CLEAR_CARD_STATE= "CLEAR_CARD_STATE";
export const CLEAR_CARD_STATE_CLEAR= "CLEAR_CARD_STATE_CLEAR";

export const RESET_WELCOME_MODE= "RESET_WELCOME_MODE";
export const LOGIN_USER= "LOGIN_USER";

export const UPDATE_THEME_SUCCESS= "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_ERROR= "UPDATE_THEME_ERROR";
export const UPDATE_THEME_PROCESS="UPDATE_THEME_PROCESS";


export const AUTHORIZE_CHAT_SUCCES = "AUTHORIZE_CHAT_SUCCES";
export const AUTHORIZE_CHAT_ERROR = "AUTHORIZE_CHAT_ERROR";
export const AUTHORIZE_CHAT_PROCESS = "AUTHORIZE_CHAT_PROCESS";

export const APPROVE_RULE = "APPROVE_RULE";
export const APPROVE_RULE_PROCESS = "APPROVE_RULE_PROCESS";
export const APPROVE_RULE_ERROR = "APPROVE_RULE_ERROR";