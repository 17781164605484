import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Icon, Label, TextArea } from 'semantic-ui-react'
import * as taskActions from "./../../store/task/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CamperTask, FileItem, TaskStore } from "../../store/taskType";
import { CurrentConfig } from '../../api/config';
import { AuthStore, CamperState, User } from "../../store/baseType";
import _ from "lodash";
import Rating from "../../components/UI/Rating";
import { Survay, FeedbackStore } from "../../store/feedbackType";
import * as feedbackActions from "./../../store/feedback/actions"
import { ImageGroup, Image } from 'react-fullscreen-image'
import './Survey.css';
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';
import FileViewer from '../../components/UI/FileViwer/FileViewer';

type SurveyCardState = {
    /* видно карточку */
    cardMode: boolean,
    /* рейтинг */
    rating?: number[] | undefined,
    /* комментарий */
    commentary: string,
    /* файлы */
    files: any | undefined
    /*ошибки*/
    errors?: any
    /*Тип файла */
    typeFile: string

    sendButtonDisabled: boolean,
    commentDeniedCount?: number,

    selectedFile: any | null,
    errorUpload: string,
}

type SurveyCardProp = {
    user: User,
    setSurvey: Function,
    backToList: Function,
    sendSurvayTitlesInProcess: boolean,
    selectedSurvayItem: Survay,
    errorInSendSurvay: String,

    refreshUserInfo: Function,
}


class SurveyCard extends React.Component<SurveyCardProp, SurveyCardState> {
    upload: HTMLInputElement | null = null;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            cardMode: false,
            files: '',
            commentary: '',
            sendButtonDisabled: true,
            typeFile: '',
            errorUpload: '',
            selectedFile: null
        }
    }

    updateDisableButton() {
        this.setState({ sendButtonDisabled: (this.state.commentDeniedCount ?? 1) > 0 })
    }
    //Проверка формы
    validationSave = () => {
        let errors = [];

        if (!(this.props.selectedSurvayItem.surveyQuestions.every((item) => item.quality > 0))) {
            errors.push('Необходимо сделать выбор!');
        }
        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }
    async componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
    }
    onButtonClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };
    onRemoveClick = (item: FileItem) => {
        /*this.props.removePhoto(item);*/
    };
    fileChangedHandler = (event: any) => {
        const file = event.target.files[0];
        const maxSizeImage = 10 * 1024 * 1024; 
        const maxSizeVideo = 100 * 1024 * 1024;
        let error = '';
        if (file) {
            if (file.type.startsWith('image/') && file.size >= maxSizeImage) {
                error = "Размер файла превысил ограничение (10 МБ) для файлов изображений.";
                this.upload = null;
                this.setState({selectedFile: null, errorUpload: error });
            } else if (file.type.startsWith('video/') && file.size >= maxSizeVideo) {
               error = "Размер файла превысил ограничение (100 МБ) для файлов видео."; 
                this.upload = null;
               this.setState({selectedFile: null, errorUpload: error });
            } else if(file.type.startsWith('image/') || file.type.startsWith('video/')){
                this.setState({ ...this.state, selectedFile: file, errorUpload: error });   
            } else{
                error = "Файл не соответствует ожидаемому типу или размеру";   
                this.setState({ selectedFile: null, errorUpload: error });
            }
        }
    }
    takeToWorkRequest = () => {
        const data = new FormData();
        
        data.append('id', this.props.selectedSurvayItem.id.toString());
        data.append('state', this.props.selectedSurvayItem.state);
       
        this.props.setSurvey(data);
    }
    sendRequest = () => {
        const data = new FormData();
        if (!this.validationSave()) {
            return;
        }
        if (this.state.errorUpload === ''){

            data.append('file', this.state.selectedFile);
            data.append('id', this.props.selectedSurvayItem.id.toString());
            data.append('state', this.props.selectedSurvayItem.state);
            data.append('comment', this.state.commentary);
            data.append('surveyTaskId', this.props.selectedSurvayItem.surveyTaskId.toString());
            data.append('point', this.props.selectedSurvayItem.point.toString());
            data.append('SurveyQuestions', JSON.stringify(this.props.selectedSurvayItem.surveyQuestions));
            
            this.props.setSurvey(data);
            
        }
    }
    render() {
        return (
            <div>
                <div className={this.props.sendSurvayTitlesInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>
                
                <div className="task-card-back-btn" onClick={() => {
                    this.setState({ ...this.state });
                    this.props.backToList();
                }}><Icon name="arrow left" />назад</div>
              
                {this.props.selectedSurvayItem.typeId === 2 && (
                    <div className='survey-heder'>
                        Задание-опрос
                    </div>
                )}
                {this.props.selectedSurvayItem.typeId === 1 && (
                    <div className='survey-heder'>
                        Опрос по QR-коду
                    </div>
                )}
                <div style={{ margin: "8%" }}>
                    <img src={this.basePath + '/downloadFileFeedbackByLink?id=' + this.props.selectedSurvayItem?.fileInterviewId}
                        className={"survey-card-photo2"} alt="" />
                </div>
                
                <div className={"survey-card-description2"}>
                    <div className='survey-card-description-title2' >{this.props.selectedSurvayItem?.name}</div>
                    <div>
                        {(this.props.selectedSurvayItem.state === "Доступно" || this.props.selectedSurvayItem.state === "В работе") ?
                            <div>
                                <div className={"survey-card-label2"}>Тема опроса:</div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.surveyType}</div>
                                <div className={"survey-card-label2"}>Название лагеря и смены:</div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.boutName}</div>
                                <div className={"survey-card-label2"}>Количество баллов:</div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.point}</div>
                            </div> : ""
                        }
                        {this.props.selectedSurvayItem.state === "Доступно" && (
                            <Button disabled={this.state.sendButtonDisabled} className="submitTaskBtn survey-btn" color={"orange"} fluid onClick={() => {
                                this.takeToWorkRequest();
                            }}>Взять в работу</Button>
                        )}
                        {(this.props.selectedSurvayItem.state === "Выполнено") ?
                            <div style={{ marginTop: '20px' }}>
                                <div className={"survey-card-label2"}>Статус:</div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem.state}</div>
                                <div className={"survey-card-label2"}>Дата и время выполнения: </div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem.createTime}</div>
                                {(this.props.selectedSurvayItem.typeId === 1)
                                    ? ''
                                    :
                                    <div>
                                        <div className={"survey-card-label2"}>Количество баллов: </div>
                                        <div className={"task-card-value2"}>{this.props.selectedSurvayItem.point}</div>
                                    </div>
                                }
                                <div className={"survey-card-label2"}>Наименование лагеря и смена:</div>
                                <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.boutName} </div>
                            </div>
                            : ""
                        }
                        <div style={{ marginTop: '30px', marginBottom: '20px' }}>
                            {this.props.selectedSurvayItem?.surveyQuestions.map((quest, index) => {
                                return (
                                    <div style={(this.props.selectedSurvayItem.state === "Доступно" || this.props.selectedSurvayItem.state === "Выполнено" ) ? { pointerEvents: 'none'} :{ pointerEvents: 'visible' }}>
                                        <tr>
                                            <td className={"task-value"} style={{ marginBottom: '5px', marginTop: '10px', fontSize: '13px' }}>{quest.name}</td>
                                        </tr>
                                        <tr style={{ fontSize: '24px' }}>
                                            <Rating rating={quest.quality} OnChange={(r: number) => {
                                                this.props.selectedSurvayItem.surveyQuestions[index].quality = r
                                                this.setState({
                                                    ...this.state,
                                                })
                                            }} />
                                        </tr>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={"survey-card-label2"}>Комментарий:</div>
                        <div className='qr-texteria' style={(this.props.selectedSurvayItem.state === "Доступно" || this.props.selectedSurvayItem.state === "Выполнено" ) ? { pointerEvents: 'none'} :{ pointerEvents: 'visible' }}>
                            {this.props.selectedSurvayItem.state === "Выполнено" 
                            ?   <div className='task-card-value2'>{this.props.selectedSurvayItem.comment ?? "Комментарии отсутствуют"}</div> 
                            :   <MarkableTextArea
                                    className="task-commentary"
                                    text={this.state.commentary}
                                    type="area"
                                    verifyDelay={500}
                                    onChange={(value: any) => {
                                        this.setState({
                                            sendButtonDisabled: true,
                                            commentary: (value || '').toString()
                                        })
                                    }}
                                    onRangesUpdated={(ranges: []) => {
                                        this.setState({ commentDeniedCount: ranges.length });
                                        this.updateDisableButton();
                                    }}
                                />
                            }
                        </div>
                        <div style={(this.props.selectedSurvayItem.state === "Доступно" || this.props.selectedSurvayItem.state === "Выполнено" ) ? { pointerEvents: 'none'} :{ pointerEvents: 'visible' }}>
                            {(this.props.selectedSurvayItem.state === "Доступно" || this.props.selectedSurvayItem.state === "В работе") ?
                                <div onClick={this.onButtonClick}>
                                    <div style={{ left: '0%', marginTop: '20px' }} id="feedback-add-photo-button">
                                        <div id="feedback-add-photo-button-text">
                                            Добавить фото
                                        </div>
                                        <div id="add-photo-button-plus-border"></div>
                                        <div id="add-photo-button-plus-horisontal-line"></div>
                                        <div id="add-photo-button-plus-vertical-line"></div>
                                    </div>
                                    <input type="file" ref={((ref) => this.upload = ref)} onChange={this.fileChangedHandler}
                                        style={{ display: "none" }} />
                                </div>
                                : ""
                            }
                            <br />
                            {this.state.errorUpload !== '' 
                                ?   <div className={"modal-error-text"}>
                                        {this.state.errorUpload}
                                    </div>
                                : ""
                            }
                        </div>
                        <div>
                        {(this.props.selectedSurvayItem.fileInterviewTaskId != null) 
                        ?   <div>
                                <br/>
                                <FileViewer url = {`${this.basePath}/downloadFeedbackFileById?id=${this.props.selectedSurvayItem.fileInterviewTaskId}`}/>
                            </div>
                        :   <div>
                                <br/>
                                {
                                    this.state.selectedFile? 
                                    <FileViewer url = {URL.createObjectURL(this.state.selectedFile)}/>
                                    : ""
                                }
                            </div>
                        }
                        </div>
                        {(this.props.selectedSurvayItem.state === "Доступно") 
                        ?   <div>
                                {(this.props.errorInSendSurvay) ?
                                <ul>
                                    <li className={"error-class"}>{this.props.errorInSendSurvay}</li>
                                </ul>
                                : ""}
                                {((this.state.errors && this.state.errors.length > 0)) ?
                                    <ul>
                                        {_.map(this.state.errors, (item: any) =>
                                            <li className={"error-class"}>{item}</li>)}
                                    </ul>
                                    :   ""
                                }
                            </div>
                        : (this.props.selectedSurvayItem.state === "В работе")
                        ? <div>
                                <Button disabled={this.state.sendButtonDisabled} className="submitTaskBtn survey-btn" color={"orange"} fluid onClick={() => {
                                    this.sendRequest();
                                    
                                }}>Подтвердить</Button>

                                {(this.props.errorInSendSurvay) ?
                                <ul>
                                    <li className={"error-class"}>{this.props.errorInSendSurvay}</li>
                                </ul>
                                : ""}
                                {((this.state.errors && this.state.errors.length > 0)) ?
                                    <ul>
                                        {_.map(this.state.errors, (item: any) =>
                                            <li className={"error-class"}>{item}</li>)}
                                    </ul>
                                    :   ""
                                }
                            </div>
                        : ""
                        }
                    </div>

                </div>
            </div>

        );
    }
}

function mapStateToProps(state: any) {

    let authStore: AuthStore = state.authentication
    let feedbackStore: FeedbackStore = state.feedback;
    return {
        counselor: authStore.counselor,
        selectedSurvayItem: feedbackStore.selectedSurvayItem,
        sendSurvayTitlesInProcess: feedbackStore.sendSurvayTitlesInProcess,
        errorInSendSurvay: feedbackStore.errorInSendSurvay,
        user: authStore.user
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(feedbackActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCard);