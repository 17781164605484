import axios from "axios";
import {CurrentConfig} from './config';
import {ManualCommonType, ManualShortType, ManualCommonName, ManualShortName} from "../store/manualType";

const manualApi = {
    download: (manualType: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios({
            url: `${config.Api}/manual/download?manualType=${manualType}`,
            method: 'GET',
            responseType: 'blob'
        }).then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                var fileName = "manual.pdf"
                if (manualType == ManualCommonType){
                    fileName = ManualCommonName;
                }
                else if(manualType == ManualShortType){
                    fileName = ManualShortName;
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}

export default manualApi;