import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Checkbox, Input } from 'semantic-ui-react'
import * as taskActions from "./../../store/gift/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { Gift, GiftStore, GiftReserved, ListGiftReservedFilter,GiftByConfirm } from "../../store/giftType";
import { AuthStore, User } from "../../store/baseType";
import _ from "lodash";
import './Gift.css';
import { CurrentConfig } from '../../api/config';
import GiftReservedCard from "./GiftReserved";
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';
import { QRScanner } from '../../components/UI/QRScanner/QRScanner';
//import QRcode from 'qrcode.react'
import qrCode from './../../images/qrCode.png';
import SuccesPopup from '../../components/UI/Success/SuccessNotification';

type GiftState = {
    filter: string,

    /* видно фильтр */
    filterView: boolean,

    reserved: boolean,

    issued: boolean,

    canceled: boolean,

    refusal: boolean,

    //openQrModal: boolean
    qrErrorMsg?: string,
    qrModalOpened: boolean,

    inputKey: number,
    succesOpen: boolean
}

type GiftProp = {
    listReservedGifts: Function,
    refreshUserInfo: Function,
    selectGift: Function,
    listInProcess: boolean,
    operationInProcess: boolean,
    listReserved: GiftReserved[] | undefined,
    errorInList: string | undefined,
    selectedItem: Gift | undefined,
    user: User,

    confirmReceiptOfGifts: Function,
    confirmReceiptOfCamper: Function,
    errorconfirmgift: string,
    updateError: Function,

    confirmedGift: boolean,
    confirmgiftProcess: boolean
    //stringForQrCode?: string
}


class GiftReservedList extends React.Component<GiftProp, GiftState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            filter: '',
            filterView: false,
            reserved: true,
            canceled: false,
            issued: false,
            refusal: false,
            qrModalOpened: false,
            inputKey: 1,
            succesOpen: false

            //openQrModal: true
        }
    }

    searchItems() {
        let filter: ListGiftReservedFilter = {
            pf: undefined,
            pt: undefined,
            t: this.state.filter,
            canceled: this.state.canceled,
            issued: this.state.issued,
            refusal: this.state.refusal,
            reserved: this.state.reserved
        };

        this.props.listReservedGifts(filter);
        this.setState({
            ...this.state,
            filterView: false
        })
    }
    componentDidUpdate(prevProps: GiftProp) {
        if (!prevProps.confirmedGift && this.props.confirmedGift) {
          this.setState({ qrModalOpened: false, inputKey: this.state.inputKey + 1, succesOpen: true });
        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        this.searchItems();
    }
    render() {
        return (
            <div>
                 {this.state.succesOpen && <SuccesPopup key={this.state.inputKey} message={"Подарки подтверждены"}  />}  
                <div className="simple-nav-bar">
                    <div className="left-nav-elems">
                        <div style={{ width: "60px" }}></div>
                    </div>
                    <div className="right-nav-elems">
                        
                        <div className="nav-elem">
                            <QRScanner
                                error={this.state.qrErrorMsg || this.props.errorconfirmgift}
                                open={this.state.qrModalOpened}
                                onOpen={() => { 
                                    this.props.updateError('');
                                    this.setState({  qrModalOpened: true,
                                                     qrErrorMsg: '',
                                                     succesOpen: false })     
                                }}
                                onClose={() => { this.setState({ qrModalOpened: false }) }}
                                onError={() => { }}
                                onScan={(data: string | null) => {
                                    if (data) {
                                        try {
                                            const parsedObj = JSON.parse(data) as GiftByConfirm;
                                            if (
                                              parsedObj.childId !== undefined &&
                                              parsedObj.giftIds !== undefined &&
                                              parsedObj.commandType !== undefined 
                                            ) {
                                                if(parsedObj.commandType === 'confirmReceiptOfGifts'){
                                                    
                                                    this.setState({ qrErrorMsg: '' })
                                                    this.props.confirmReceiptOfGifts(parsedObj.giftIds);
                                                }
                                                if(parsedObj.commandType === 'confirmReceiptOfCamper'){
                                                    this.setState({ qrErrorMsg: '' })
                                                    this.props.confirmReceiptOfCamper(parsedObj.childId);
                                                }
                                            }
                                          } catch (error) {
                                            this.props.updateError('');
                                            this.setState({ qrErrorMsg: 'QR-код не подходит' })
                                        }   
                                    }
                                }}
                                trigger={<img src={qrCode} style={{width:'35px'}} alt="Картинка"/>}
                            />
                        </div>
                        <div className='nav-elem' style={{width:'70px', color: 'black', cursor:'auto'}}>
                            Получить по Qr-коду
                        </div>
                    </div>
                </div>  
                <div className="taskBlock">
                    <div className="search-input-btn">
                        <SearchBar
                            action={{
                                icon: 'filter',
                                onClick: () => {
                                    this.setState({
                                        ...this.state,
                                        filterView: !this.state.filterView
                                    })
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                let text = data.value;
                                this.setState({
                                    ...this.state,
                                    filter: text
                                });

                                if (this.interval) {
                                    clearInterval(this.interval);
                                    this.interval = undefined;
                                }

                                this.interval = setInterval(() => {
                                    clearInterval(this.interval);
                                    this.searchItems();
                                    this.interval = undefined;
                                }, 600);
                            }}
                        />

                        <div className={this.state.filterView ? "" : "hidden"}>
                            <table className={"task-table"}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className={"task-table-header"}>
                                            Статус подарка
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Checkbox label={"Зарезервирован"} onChange={((event, data) => {
                                                this.setState({
                                                    ...this.state,
                                                    reserved: data.checked || false
                                                });
                                            })} checked={this.state.reserved} />
                                        </td>
                                        <td>

                                            <Checkbox label={"Выдан"} onChange={((event, data) => {
                                                this.setState({
                                                    ...this.state,
                                                    issued: data.checked || false
                                                });
                                            })} checked={this.state.issued} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Checkbox label={"Отменён"} onChange={((event, data) => {
                                                this.setState({
                                                    ...this.state,
                                                    canceled: data.checked || false
                                                });
                                            })} checked={this.state.canceled} />
                                        </td>
                                        <td>
                                            <Checkbox label={"Отклонено"} onChange={((event, data) => {
                                                this.setState({
                                                    ...this.state,
                                                    refusal: data.checked || false
                                                });
                                            })} checked={this.state.refusal} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button
                                style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive}
                                onClick={() => {
                                    this.searchItems();
                                }} fluid>Поиск</Button>
                        </div>



                        <div className={"ui segments"}>
                            <div
                                className={this.props.listInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                <div className="ui text loader">Загрузка...</div>
                            </div>
                            {_.map(this.props.listReserved, (item: any) => <GiftReservedCard gift={item} />)}
                            {!this.props.listReserved || this.props.listReserved?.length === 0 ?
                                <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let giftStore: GiftStore = state.gift;
    let authStore: AuthStore = state.authentication;
    return {
        listInProcess: giftStore.listInProcess,
        listReserved: giftStore.listReserved,
        errorInList: giftStore.errorInList,
        user: authStore.user,
        errorconfirmgift: giftStore.errorconfirmgift,
        confirmedGift: giftStore.confirmedGift,
        confirmgiftProcess: giftStore.confirmgiftProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftReservedList);