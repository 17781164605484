import exp from "constants";

export const GIFT_SELECT = "GIFT_SELECT";

export const GIFT_LIST_SUCCESS = "GIFT_LIST_SUCCESS";
export const GIFT_LIST_FAILED = "GIFT_LIST_FAILED";
export const GIFT_LIST_PROCESS = "GIFT_LIST_PROCESS";

export const GIFT_SUCCESS = "GIFT_SUCCESS";
export const GIFT_FAILED_OVERBOOKED = "GIFT_FAILED_OVERBOOKED";
export const GIFT_FAILED = "GIFT_FAILED";
export const GIFT_PROCESS = "GIFT_PROCESS";

export const GIFT_WISHED_SUCCESS = "GIFT_WISHED_SUCCESS";

export const GIFT_HISTORY_SUCCESS = "GIFT_HISTORY_SUCCESS";

export const GIFT_RESERVED_LIST_SUCCESS = "GIFT_RESERVEDLIST_SUCCESS";
export const GIFT_RESERVED_LIST_FAILED = "GIFT_RESERVEDLIST_FAILED";
export const GIFT_RESERVED_LIST_PROCESS = "GIFT_RESERVEDLIST_PROCESS";

export const GIFT_CANCEL_SUCCESS = "GIFT_CANCEL_SUCCESS";
export const GIFT_CANCEL_FAILED = "GIFT_CANCEL_FAILED";
export const GIFT_CANCEL_PROCESS = "GIFT_CANCEL_PROCESS";

export const GIFT_RATING_SUCCESS = "GIFT_RATING_SUCCESS";
export const GIFT_RATING_FAILED = "GIFT_RATING_FAILED";
export const GIFT_RATING_PROCESS = "GIFT_RATING_PROCESS";

export const GIFT_CATEGORIES_SUCCESS = "GIFT_CATEGORIES_SUCCESS";
export const GIFT_CATEGORIES_FAILED = "GIFT_CATEGORIES_FAILED";
export const GIFT_CATEGORIES_PROCESS = "GIFT_CATEGORIES_PROCESS";

export const SUPER_GIFT_SUCCESS = "SUPER_GIFT_SUCCESS";
export const SUPER_GIFT_FAILED_OVERBOOKED = "SUPER_GIFT_FAILED_OVERBOOKED";
export const SUPER_GIFT_FAILED = "SUPER_GIFT_FAILED";
export const SUPER_GIFT_PROCESS = "SUPER_GIFT_PROCESS";

export const CONFIRM_GIFT_PROCESS = "CONFIRM_GIFT_PROCESS";
export const CONFIRM_GIFT_FAILED = "CONFIRM_GIFT_FAILED";
export const CONFIRM_GIFT_SUCCESS = "CONFIRM_GIFT_SUCCESS";

export const UPDATE_ERROR = "UPDATE_ERROR";
