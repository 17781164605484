import React from 'react';
import 'semantic-ui-css/semantic.min.css'
//import * as taskActions from "../../store/task/actions"
import {connect} from "react-redux";
//import {bindActionCreators} from "redux";
//import {CamperTask, TaskStore} from "../../store/taskType";
import {CurrentConfig} from "../../api/config";
import {AuthStore} from "../../store/baseType";
import {Feedback} from "../../store/feedbackType";

import Handling from './../../images/Handling.png';
//let moment = require('moment');

type FeedbackRowState = {}

type FeedbackRowProp = {
    feedback: Feedback,
    onClick: Function
}


class FeedbackRow extends React.Component<FeedbackRowProp, FeedbackRowState> {
    basePath: string = CurrentConfig.get().FeedbackPhotoUrl;

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
    }

    handleClick() {
    }

    render() {
        return (
            <div id="feedback-row" onClick={(e) => {
                if (e.button === 0) {
                    this.props.onClick(this.props.feedback)
                }
            }}>
                <img src={Handling} className="task-photo" alt=""/>
                <div id={"feedback-row-body"}>
                    <div className={"task-name"}>
                        Ситуативное обращение
                    </div>
                    <span className={"task-date"}>Тема: <br/>{this.props.feedback.title?.title}</span>                    
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication
    return {
        counselor: authStore.counselor,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
//    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackRow);