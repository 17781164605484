import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Icon} from 'semantic-ui-react'
import * as notificationActions from "./../../store/notification/actions"
import {refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Feedback, FeedbackTitle, FeedbackStore} from "../../store/feedbackType";
import * as feedbackActions from "./../../store/feedback/actions"
import {CurrentConfig} from "../../api/config";
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import { AuthStore, User } from '../../store/baseType';


type FeedBackCardState = {
}

type FeedBackCardProp = {
    user: User,
    backToList: Function,
    selectedFeedbackItem: Feedback 
}

class FeedBackCard extends React.Component<FeedBackCardProp, FeedBackCardState> {
    basePath: string = CurrentConfig.get().FeedbackPhotoUrl;
    interval: any = undefined;
    
    constructor(props: any) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
    }
    render() {
        return (
            <div className={"segment ui notification-card"} style={{backgroundColor: JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor}}>
                <div onClick={() => 
                        { 
                            this.setState({ ...this.state });
                            this.props.backToList(); 
                        
                        }} className="back-btn task" style={{marginTop: '10px'}}><Icon name="arrow left" />
                назад  
                </div>
                <div className={"notification-card-description"}>
                    <div className={"notification-row-label"}>
                        Тема обращения:
                    </div>
                    <div className={"notification-name"}>
                        {this.props.selectedFeedbackItem?.title?.title}
                    </div>
                    <div className={"notification-row-label"}>
                        Сообщение:
                    </div>
                    <div className={"notification-name"}>
                        {this.props.selectedFeedbackItem?.description}
                    </div>
                    {this.props.selectedFeedbackItem.photo?.id ? 
                        <div>
                            <br/>
                             <FileViewer url = {this.basePath + '/downloadFeedbackFileById?id=' + this.props.selectedFeedbackItem.photo?.id}/>
                        </div>
                    : ""
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state: any) {
    let feedbackStore: FeedbackStore = state.feedback;
    let authStore: AuthStore = state.authentication
    return {
        selectedFeedbackItem: feedbackStore.selectedFeedbackItem, 
        user: authStore.user
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(feedbackActions, dispatch),
    ...bindActionCreators({refreshUserInfo}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FeedBackCard);