import { HttpTransportType } from '@microsoft/signalr';

interface Config {
  Api?: string;
  BasePath?: string;
  Chat?: string;
  FeedbackPhotoUrl?: string;
  DynamicUrl?: boolean;
  PublicUrl?: string;
  ChatTransport?: HttpTransportType;
}

const fetchConfig = async () => {
  const response = await fetch('/mobile-ui.config.json');
  const config: Config = await response.json();
  return config;
};
const config = await fetchConfig();

export class CurrentConfig {
  static currentConfig: Config = config ?? {
    Api: '',
    BasePath: '',
    Chat: '',
    FeedbackPhotoUrl: '',
    DynamicUrl: false,
    PublicUrl: '',
    ChatTransport: 4,
  };

  static init = async () => {
    CurrentConfig.set(config);
  };

  static set = (config: Config) => {
    CurrentConfig.currentConfig.Api = config.Api;
    CurrentConfig.currentConfig.BasePath = config.BasePath || '';
    CurrentConfig.currentConfig.Chat = config.Chat;
    CurrentConfig.currentConfig.FeedbackPhotoUrl = config.FeedbackPhotoUrl;
    CurrentConfig.currentConfig.DynamicUrl = config.DynamicUrl;
    CurrentConfig.currentConfig.PublicUrl = config.PublicUrl;
  };

  static get = () => {
    return {
      Api: CurrentConfig.currentConfig.Api ?? '',
      BasePath: CurrentConfig.currentConfig.BasePath ?? '',
      Chat: CurrentConfig.currentConfig.Chat ?? '',
      FeedbackPhotoUrl: CurrentConfig.currentConfig.FeedbackPhotoUrl ?? '',
      DynamicUrl: CurrentConfig.currentConfig.DynamicUrl || false,
      PublicUrl: CurrentConfig.currentConfig.PublicUrl ?? '',
      ChatTransport: CurrentConfig.currentConfig.ChatTransport ?? 4,
    };
  };
}
