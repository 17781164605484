import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Modal, Dropdown, TextArea, Input, Label, ModalActions, Form, Icon, Segment, Dimmer, Loader, Image, SemanticICONS, Header, ModalProps, InputProps } from 'semantic-ui-react';
import './SearchBar.css';
import QrScan from 'react-qr-reader'



export type SearchBarState = {
}

type SearchBarProp = {
}

export class SearchBar extends React.Component<SearchBarProp & InputProps, SearchBarState> {
    delayedVerification: any;


    constructor(props: SearchBarProp & InputProps) {
        super(props);
        this.state = {
            helpModalOpened: false,
        }

    }


    render() {
        return (
            <Input className="search-bar"
                size="large"
                iconPosition="left"
                icon="search"
                placeholder='Поиск'
                type="text"
                {...this.props}>
            </Input>
        );
    }
}