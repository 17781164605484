import {
    CHATTITLE_GET_SUCCESS,
    CHATTITLE_GET_FAILED,
    CHATTITLE_GET_PROCESS,
    CHATTITLEONE_GET_SUCCESS,

    SELECT_CONVERSATION_SUCCESS,
    CLEAR_CONVERSATION_SUCCESS,
    //Получить учасников
    MEMBERS_GET_SUCCESS,
    MEMBERS_GET_FAILED,
    MEMBERS_GET_PROCESS,

    //Типы для чат сервиса
    CONVERSATION_GET_SUCCESS,
    CONVERSATION_GET_FAILED,
    CONVERSATION_GET_PROCESS,
    //Создать комнату
    CONVERSATION_CREATE_SUCCESS,
    CONVERSATION_CREATE_FAILED,
    CONVERSATION_CREATE_PROCESS,
    //Закрыть комнату
    CONVERSATION_CLOSE_SUCCESS,
    //Типы для получения сообщений по чату
    MESSAGE_GET_SUCCESS,
    MESSAGE_GET_FAILED,
    MESSAGE_GET_PROCESS,
    MESSAGE_SET_SUCCESS,
    MESSAGE_SET_FAILED,
    MESSAGE_SET_PROCESS,
    MESSAGE_GET_UPLOAD_SUCCESS,
    MESSAGE_READED_SET_SUCCESS,
    MESSAGE_READED_SET_FAILED,
    //Типы для Hub сообщений
    HUB_CONNECTION_SUCCESS,
    HUB_CONNECTION_FAILED,
    HUB_CONNECTION_PROCESS,
    HUB_CONNECTION_MESSAGE_SUCCESS,
    HUB_DISCONNECT_SUCCESS,
    HUB_DISCONNECT_FAILED,
    HUB_DISCONNECT_PROCESS,

    //Загрузка файла
    FILE_SET_SUCCESS,
    FILE_SET_FAILED,
    FILE_SET_PROCESS,
    //Получение количества сообщений
    MSSAGE_GET_COUNT_UNREAD_SUCCESS

} from "./types";


//import {clearReduxStateInternal} from '../authentication/actions'
import {User,TokenInfo} from "../../store/baseType";
import ChatApi from '../../api/chat'
import _, { isEmpty } from "lodash";
import {ConversationMobileService,
        ChatStory,
        Member,
        ConversationChatService,
        FilterConversation,
        FilterMessages,
        MessageChatService,
        CreateConversation,
        SendMessage,
        SendMessageFile,
    } from "../../store/chatType"
import { FileItem } from "../feedbackType";
import { Item } from "semantic-ui-react";


/// Получить комнаты
export const getConversationMobile = (data: ConversationChatService[] ) => (dispatch: any) => {
    dispatch({ type: CHATTITLE_GET_PROCESS });
    return new Promise((resolve, reject) => {
        ChatApi.getConversationMobileService(data,(status: number, model: ConversationMobileService | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: CHATTITLE_GET_SUCCESS,
                convMobileService: model,
                convChatService: data
            });
            resolve(data);
        } else if (status === 401) {
            reject(new Error('Ошибка авторизации'));
            //dispatch(clearReduxStateInternal());
        } else {
            dispatch({
                type: CHATTITLE_GET_FAILED,
                errorMessage: data || 'Ошибка получения списка обращений'
            });
            reject(new Error('Ошибка'));
        }
        });
    });
}

/// Получить комнату
export const getConversationMobileOne = (model: ConversationChatService) => (dispatch: any) => {
    dispatch({ type: CHATTITLE_GET_PROCESS});
    let modelArray = {} as ConversationChatService[]
    modelArray = [model];
    return new Promise((resolve, reject) => {
       
        ChatApi.getConversationMobileService(modelArray,(status: number, data: ConversationMobileService | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: CHATTITLEONE_GET_SUCCESS,
                data,
            });
            resolve(data);
        } else if (status === 401) {
            //dispatch(clearReduxStateInternal());
            reject(new Error('Ошибка авторизации'));
        } else {
            dispatch({
                type: CHATTITLE_GET_FAILED,
                errorMessage: data || 'Ошибка получения комнаты'
            });
            reject(new Error('Ошибка'));
        }
    });
    });
}
/// Создать комнату
export const conversationCreate = (model: CreateConversation) => (dispatch: any) => {
    dispatch({ type: CONVERSATION_CREATE_PROCESS });
    ChatApi.createConversation(model,(status: number, data: any | string | undefined) => {
    if (status === 200) {
        dispatch({
            type: CONVERSATION_CREATE_SUCCESS,
            data
        });
        let model = data.payload as ConversationChatService;
        dispatch(getConversationMobileOne(model as ConversationChatService)).then(() => {
            console.log('Комната успешно создана');
        }).catch((error: Error) => {
            console.error(error.message);
        });
    } else if (status === 401) {
        //dispatch(clearReduxStateInternal());
    } else {
        dispatch({
            type: CONVERSATION_CREATE_FAILED,
            errorMessage: data || 'Ошибка создания комнаты'
        });
    }
});
}
/// Закрыть комнату
export const conversationClose = (model: String) => (dispatch: any) => {
    dispatch({type: CONVERSATION_CREATE_PROCESS});
    ChatApi.closeConversation(model,(status: number, data: any | string | undefined) => {
    
    if (status === 200) {
        dispatch({
            type: CONVERSATION_CLOSE_SUCCESS,
            data
        });
    } else if (status === 401) {
        //dispatch(clearReduxStateInternal());
    } else {
        return dispatch({
            type: CONVERSATION_CREATE_FAILED,
            errorMessage: data || 'Ошибка получения списка обращений'
        });
    }
});


}
/// выбрать чат
export const selectConversationMobileService = (data: ConversationMobileService) => (dispatch: any) => {
    return dispatch({
        type: SELECT_CONVERSATION_SUCCESS,
        data: data           
    });
}
//Очистить чат текущей
export const clearConversationMobileService =  (data: ConversationMobileService) => (dispatch: any) => {
    return dispatch({
        type: CLEAR_CONVERSATION_SUCCESS,
        data: data       
    });
}
/// Получить участников
export const getMembers = () => (dispatch: any) => {
    ChatApi.getMembers((status: number, data: Member[] | string | undefined) => {
    
        if (status === 200) {
            dispatch({
                type: MEMBERS_GET_SUCCESS,
                data
            });
        } else if (status === 401) {
            //dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: MEMBERS_GET_FAILED,
                errorMessage: data || 'Ошибка получения списка обращений'
            });
        }
    });
    return dispatch({
        type: MEMBERS_GET_PROCESS
    });

}
//Получить комнаты ChatService
export const getConversationChat = (filter: FilterConversation) => (dispatch: any) => {
    dispatch({ type: CONVERSATION_GET_PROCESS });
    ChatApi.getConversationChatService(filter,(status: number, data: any | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: CONVERSATION_GET_SUCCESS,
                data: data
            });
            let dataArray = [] as ConversationChatService[];
            if(typeof data === 'object' && data !== null){
                dataArray = Array.from(data.data) as ConversationChatService[];
            }
            if(Array.isArray(data)){
                dataArray = Array.from(data) as ConversationChatService[];
            }
            
            dispatch(getConversationMobile(dataArray as  ConversationChatService[] )).then(() => {     
            }).catch((error: Error) => {
                console.error(error.message);
            });
            
        } else if (status === 401) {
            //dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: CONVERSATION_GET_FAILED,
                errorMessage: data || 'Ошибка получения списка обращений'
            });
        }
    });
}
/// Получить сообщения комнаты
export const getConversationMessage = (model: FilterMessages) => (dispatch: any) => {
    ChatApi.getConversationMessage(model,(status: number, data: MessageChatService | string | undefined) => {
    
    if (status === 200) {
        dispatch({
            type: MESSAGE_GET_SUCCESS,
            data: data,
        });
    } else if (status === 401) {
        //dispatch(clearReduxStateInternal());
    } else {
        return dispatch({
            type: MESSAGE_GET_FAILED,
            errorMessage: data || 'Ошибка получения списка обращений'
        });
    }
});

return dispatch({
    type: MESSAGE_GET_PROCESS
});
}
/// Получить сообщения подгрузкой
export const getConversationMessageMove = (model: FilterMessages) => (dispatch: any) => {
    ChatApi.getConversationMessage(model,(status: number, data: MessageChatService | string | undefined) => {
    
    if (status === 200) {
        dispatch({
            type: MESSAGE_GET_UPLOAD_SUCCESS,
            data: data
        });
    } else if (status === 401) {
        //dispatch(clearReduxStateInternal());
    } else {
        return dispatch({
            type: MESSAGE_GET_FAILED,
            errorMessage: data || 'Ошибка получения списка обращений'
        });
    }
});

return dispatch({
    type: MESSAGE_GET_PROCESS
});
}
/// Отправить сообщение
export const sendMessage = (data: SendMessage) => (dispatch: any) => {
    ChatApi.sendMessage(data,(status: number, data: any | string | undefined) => {
    
    if (status === 200) {
        dispatch({
            type: MESSAGE_SET_SUCCESS,
            data
        });
    } else if (status === 401) {
        //dispatch(clearReduxStateInternal());
    } else {
        return dispatch({
            type: MESSAGE_SET_FAILED,
            errorMessage: data || 'Ошибка получения списка обращений'
        });
    }
});

return dispatch({
    type: MESSAGE_SET_PROCESS
});
}

export const sendMessageReaded = (data: string, callback: Function) => (dispatch: any) => {
    ChatApi.sendMessageReaded(data, (status: number, data: any | string | undefined) => {
        callback(status === 200);
        if (status === 200) {
            dispatch({
                type: MESSAGE_READED_SET_SUCCESS,
                data
            });
        } else if (status === 401) {
        } else {
            return dispatch({
                type: MESSAGE_READED_SET_FAILED,
                errorMessage: data || 'Ошибка отправки статуса сообщения'
            });
        }
    });

}

/// Отправить сообщение
export const sendMessageFile = (model: SendMessageFile) => (dispatch: any) => {
    dispatch({ type: MESSAGE_SET_PROCESS });
    return new Promise((resolve, reject) => {
      ChatApi.sendMessageFile(model, (status: number, data: any | string | undefined) => {
        if (status === 200) {
          dispatch({
            type: MESSAGE_SET_SUCCESS,
            data
          });
          resolve(data);
        } else if (status === 401) {
          //dispatch(clearReduxStateInternal());
          reject(new Error('Ошибка авторизации'));
        } else {
          dispatch({
            type: MESSAGE_SET_FAILED,
            errorMessage: data || 'Ошибка отправки сообщения'
          });
          reject(new Error(data || 'Ошибка отправки сообщения'));
        }
      });
    });
};
// загрузить фотографию
export const uploadFile = (data: any, conversationId: string) => (dispatch: any) => {
dispatch({ type: FILE_SET_PROCESS });
ChatApi.uploadFile(data, 2, (status: number, data: any | undefined) => {
    if (status === 200) {
        dispatch({
            type: FILE_SET_SUCCESS,
            data
        });
        let obj = data! as FileItem;
        let jsonUrl = {mediaUrl : obj.id.toString(), type: obj.fileUrl };
        let jsonModel = JSON.stringify(jsonUrl);
        let model: SendMessageFile = { conversationId: conversationId.toString(), mediaUrl: jsonModel };
        dispatch(sendMessageFile(model as SendMessageFile)).then(() => {
            console.log('Сообщение успешно отправлено');
        }).catch((error: Error) => {
            console.error(error.message);
        });
    } else if (status === 401) {
    // dispatch(clearReduxStateInternal());
    } else {
    dispatch({
        type: FILE_SET_FAILED,
        errorMessage: (data as String).length > 200
        ? 'Ошибка загрузки файла'
        : data || 
        (data === undefined)
        ?'Ошибка загрузки файла' 
        : data
    });
    }
});
};
export const connection = () => (dispatch: any) => {
    ChatApi.connection((value: string, data: any | string | undefined) => {
    if(value.toString() === '200'){
        dispatch({
            type: HUB_CONNECTION_MESSAGE_SUCCESS,
            data: true
        });
    }else if(value.toString() === '401') {
        dispatch({
            type: HUB_CONNECTION_MESSAGE_SUCCESS,
            data: false
        });
    } else if(!isEmpty(value)){
        dispatch({
            type: HUB_CONNECTION_SUCCESS,
            value
        });
    }else{

    }
});
return dispatch({
    type: HUB_CONNECTION_PROCESS
});
}
export const disconnect = () => (dispatch: any) => {
    ChatApi.disconnect((value: string, data: any | string | undefined) => {
    
    if (!isEmpty(value)) {
        dispatch({
            type: HUB_CONNECTION_MESSAGE_SUCCESS,
            data: false
        });
    } else if (value.toString() === '401') {
        //dispatch(clearReduxStateInternal());
    } 
});
return dispatch({
    type: HUB_CONNECTION_PROCESS
});
}
export const getCountUnreadChat = () => (dispatch: any) => {
    ChatApi.getCountUnreadChat((value: string, data: any | string | undefined) => {
        if (value !== undefined && value.toString() === '200') {
            dispatch({
                type: MSSAGE_GET_COUNT_UNREAD_SUCCESS,
                data
            });
        } else if (value !== undefined && value.toString() === '401') {
            dispatch({
                type: MSSAGE_GET_COUNT_UNREAD_SUCCESS,
                data
            });
        } else {

        }
    });
}