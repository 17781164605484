import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Input, Icon } from 'semantic-ui-react'
import * as approvingActions from "./../../store/approving/actions"
import { clearListState, clearListStateClear, refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { ApprovingModel, ApprovingStore, Camper, ListFilter } from "../../store/approvingType";
import _ from "lodash";
//import ApprovingLine from "./ApprovingLine";
import './approving.css';
import { AuthStore, RegisterChildInfo, User } from "../../store/baseType";
import QrScan from 'react-qr-reader'
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';
import { CamperRow } from './CamperRow';

type ApprovingState = {
    filter: string,
    showed: any
    qrReaderMode: boolean,
    regCardMode: boolean
}

type ApprovingProp = {
    user: User,
    approveChild: Function,
    registerChild: Function,
    listChildren: Function,
    refreshUserInfo: Function,
    listInProcess: boolean,
    clearCardState: boolean,
    clearListStateClear: Function,
    list: Camper[] | undefined,
    approving: boolean,
    errorInList: string | undefined,
    childId: number | undefined,

    selectChild: Function,
    selectItem: Camper,
    registerDone: boolean
}


class Approving extends React.Component<ApprovingProp, ApprovingState> {
    interval: any = undefined;
    qrReaderData: any = undefined;
    selectedChildIndex: any = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            filter: '',
            showed: {},
            qrReaderMode: false,
            regCardMode: false
        }
    }

    componentDidMount() {
        this.props.refreshUserInfo();
        let filter: ListFilter = { skip: 0, take: 100 };
        this.props.listChildren(filter);

        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
    }

    componentDidUpdate(prevProps: Readonly<ApprovingProp>, prevState: Readonly<ApprovingState>, snapshot?: any) {
        if (!prevProps.clearCardState && this.props.clearCardState) {
            let filter: ListFilter = { skip: 0, take: 100 };
            this.props.listChildren(filter);
        }

        if (this.props.clearCardState) {
            this.props.clearListStateClear();
        }
    }

    handleQrScan = (data: any) => {
        if (data) {
            this.qrReaderData = data;

            this.setState({
                ...this.state,
                qrReaderMode: false
            });
        }
    }

    handleQrError = (err: any) => {
        console.error(err)
    }

    render() {
        return (
            <div className="approvingBlock">
                <SearchBar value={this.state.filter} action={{
                                                icon: 'close',
                                                onClick: () => {
                                                    this.setState({
                                                        ...this.state,
                                                        filter:""
                                                    });
                                                    let filter: ListFilter = { t: "", skip: 0, take: 1000 };
                                                    this.props.listChildren(filter);
                                                }
                                            }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                    let text = data.value;
                    this.setState({
                        ...this.state,
                        filter: text
                    });

                    if (this.interval) {
                        clearInterval(this.interval);
                        this.interval = undefined;
                    }

                    this.interval = setInterval(() => {
                        let filter: ListFilter = { t: text, skip: 0, take: 1000 };
                        this.props.listChildren(filter);
                        clearInterval(this.interval);
                        this.interval = undefined;
                    }, 600);
                }} />
                <div className="campers">
                    {this.props.list?.map((camper: Camper) => {
                        if(camper.errorMessage){
                            console.log(camper.errorMessage)
                        }                            
                        return <CamperRow
                            regChildAcc={(data: RegisterChildInfo) => this.props.registerChild(data) }
                            camper={camper}
                            approveChild={(model:ApprovingModel) => {
                                this.props.approveChild(model);
                            }}
                        />;
                    }
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let approvingStore: ApprovingStore = state.approving;
    let authStore: AuthStore = state.authentication;
    return {
        listInProcess: approvingStore.listInProcess,
        list: approvingStore.list,
        errorInList: approvingStore.errorInList,
        childId: approvingStore.childId,
        clearCardState: authStore.clearCardState,
        user: authStore.user,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(approvingActions, dispatch),
    ...bindActionCreators({ refreshUserInfo, clearListStateClear }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Approving);