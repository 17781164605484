export const WEB_PUSH_REG_DEV_SUCCESS = "WEB_PUSH_REG_DEV_SUCCESS";
export const WEB_PUSH_REG_DEV_FAILED = "WEB_PUSH_REG_DEV_FAILED";
export const WEB_PUSH_REG_DEV_PROCESS = "WEB_PUSH_REG_DEV_PROCESS";
export const WEB_PUSH_GET_KEY_SUCCESS = "WEB_PUSH_GET_KEY_SUCCESS";
export const WEB_PUSH_GET_KEY_FAILED = "WEB_PUSH_GET_KEY_FAILED";
export const WEB_PUSH_GET_KEY_PROCESS = "WEB_PUSH_GET_KEY_PROCESS";
export const WEB_PUSH_REG_SW_SUCCESS = "WEB_PUSH_REG_SW_SUCCESS";
export const WEB_PUSH_REG_SW_FAILED = "WEB_PUSH_REG_SW_FAILED";
export const WEB_PUSH_DEL_DEV_SUCCESS = "WEB_PUSH_DEL_DEV_SUCCESS";
export const WEB_PUSH_DEL_DEV_FAILED = "WEB_PUSH_DEL_DEV_FAILED";
export const WEB_PUSH_DEL_DEV_PROCESS = "WEB_PUSH_DEL_DEV_PROCESS";

