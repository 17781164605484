import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as authActions from "./../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { AuthStore, User } from "../../store/baseType";
import '../../components/main.css';
import './Rating.css';
import {RatingStore} from "../../store/ratingType";
import {Redirect} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import RatingList from "./List";
import box from './../../images/box-rating.png';
import porf from './../../images/porf-rating.png';
import gold from './../../images/gold-rating.png';
import flag from './../../images/flag-rating.png';

type CamperState = {
    id?: number,
    file?: string,
    pass?: string,
    passConfirm?: string,
    needChangePassword?: boolean,
    errors?: any,
    redirect?: boolean
}

type CamperProp = {
    counselor?: boolean;
    userName?: string;
    photoUserUrl?: string;
    user: User;
    mainUser: User;
    changePassword: Function,
    uploadUserPhoto: Function,
    refreshUserInfo: Function
    errorMessage?: string
}


class Camper extends React.Component<CamperProp, CamperState> {
    upload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            file: undefined,
            redirect: false
        }
    }
   
    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.mainUser.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
    }

    render() {
        if (!this.props.user || !this.props.user.id){
            return <Redirect to={"/rating"} />
        }
        if (!this.state.redirect){
            return (
                <div style={{marginTop: '40px'}}>
                    <div className="task-card-back-btn" onClick={() => {
                        this.setState({  redirect: true });
                    }}><Icon name="arrow left" />назад</div>
                     
                     <div className='row-rating' style={{marginTop: '20px'}}>
                        <div className='column-rating'>
                            <img className='imgfon' src={box} />
                            <div>Заработано всего</div>
                            <div className='price-rating'>{this.props.user.totalPoints == null ? 0 : this.props.user.totalPoints}</div>
                        </div>
                        
                        <div className="rating-photo-area">
                            <img src={this.props.photoUserUrl} className="rating-photo" alt=""/>
                        </div>

                        <div className='column-rating'>
                            <img className='imgfon' src={porf} />
                            <div>Заработано за смену</div>
                            <div className='price-rating'>{this.props.user.totalPointsPerShift == null ? 0 : this.props.user.totalPointsPerShift}</div>
                        </div>
                    </div>
                    <div className='row-rating'>
                        <div className='column-rating'>
                            <img className='imgfon' src={flag} />
                            <div>Выполнено за смену</div>
                            <div className='price-rating'>{!(this.props.user.countTaskPerShift) || this.props.user.countTaskPerShift == null ? 0 : this.props.user.countTaskPerShift}</div>
                        </div>
                        <div className='column-rating'>
                            <img className='imgfon' src={gold} />
                            <div>Можно потратить</div>
                            <div className='price-rating'>{this.props.user.points == null ? 0 : this.props.user.points}</div>
                        </div>
                    </div>               
                    <div className={"survey-card-description2"}>
                    
                        {!this.props.counselor ? <div className="survey-card-description-title2">
                            {(this.props.user.nickName || '-')}
                        </div> : ""}
                        {
                            this.props.user.phone
                            ?   <div>
                                    <div className={"survey-card-label2"}>Телефон:</div>
                                    <div className={"task-card-value2"}>{this.props.user.phone}</div>
                                </div>
                            :''
                        }
                        {
                            this.props.user.email
                            ?   <div>
                                    <div className={"survey-card-label2"}>Электронная почта:</div>
                                    <div className={"task-card-value2"}>{this.props.user.email}</div>
                                </div>
                            :''
                        }
                        {
                            this.props.user.bout
                            ?   <div>
                                    <div className={"survey-card-label2"}>Лагерь:</div>
                                    <div className={"task-card-value2"}>{this.props.user.camp}</div>
                                </div>
                            :''
                        }
                        {
                            this.props.user.bout
                            ?   <div>
                                    <div className={"survey-card-label2"}>Смена:</div>
                                    <div className={"task-card-value2"}>{this.props.user.bout}</div>
                                </div>
                            :''
                        }
                        {
                            this.props.user.party
                            ?   <div>
                                    <div className={"survey-card-label2"}>Отряд:</div>
                                    <div className={"task-card-value2"}>{this.props.user.party}</div>
                                </div>
                            :''

                        }
                    </div>  
                </div>
            );
        }
        else{
            return <Redirect to={"/rating"} />
        }
    }
}

function mapStateToProps(state: any) {
    let ratingStore : RatingStore = state.rating;
    let authStore: AuthStore = state.authentication
    return {
        userName: ratingStore.selectedUser ? ratingStore.selectedUser.nickName || '' : '',
        user: ratingStore.selectedUser ?? {},
        photoUserUrl: ratingStore.selectedUser && ratingStore.selectedUser.photo ? ratingStore.selectedUser.photo : undefined,
        mainUser: authStore.user,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Camper)