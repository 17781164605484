import React from 'react';
import logo from './../../images/playhelp-logo.png';
import './login.css';
//import 'semantic-ui-css/semantic.min.css'
import {Button, Input} from 'semantic-ui-react'
import * as authActions from "./../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {Link} from "react-router-dom";
//import '../../components/main.css';
//import login_icon from './../../images/login-icon.svg';
//import login_password_icon from './../../images/login-password-icon.svg';
import login_icon from './../../images/login-icon.png';
import login_password_icon from './../../images/login-password-icon.png';
import login_password_show_icon from './../../images/login-password-show-icon.svg';
import notification_icon from './../../images/notification.svg';
import {ChangeInfo, AuthChat,TokenInfo} from "../../store/baseType";


type AuthStateType = {
    login: string,
    pass: string,
    error: string,
    showPass: boolean,
    authChat: AuthChat,
}

type LoginProps = {
    authorizeUser: Function
    auth?: boolean
    errorMessage?: boolean
    clearReduxState: Function,
    authIoProcess: boolean,
    isTokenInfoProcess: boolean,
    loginUser: Function,
    authorizeUserChat: Function,
    tokenInfo: TokenInfo
}

class Login extends React.Component<LoginProps, AuthStateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            login: '',
            pass: '',
            error: '',
            showPass: false,
            authChat:{} as AuthChat
        };

    }

    handleLogin = () => {
        this.state.authChat.grant_type = "password";
        this.state.authChat.scope = "openid offline_access chat";
        this.state.authChat.client_id = "spa";
        this.state.authChat.username = this.state.login;
        this.state.authChat.password = this.state.pass;
        
        //this.props.loginUser(this.state);
        this.props.authorizeUserChat(this.state.authChat);
        //this.props.authorizeUser(this.state);
        this.setState({...this.state, pass: ''})
    };

    render() {
        return (
            <div className="App" style={{'display': 'content'}}>
                <div className={this.props.authIoProcess || this.props.isTokenInfoProcess ? "ui active inverted dimmer" : "ui inverted dimmer"} >
                    <div className="ui text loader">Загрузка...</div>
                </div>
                <div className="login-screen-background">
                    <div className="login-screen-header">
                        <br/>
                        <img className="login-screen-header-logo" alt='ГУАК "Мосгортур"' src={logo} height="85px"/>
                        <br/>
                        <div className="login-screen-header-text1">Добро</div>
                        <div className="login-screen-header-text2">пожаловать</div>
                    </div>
                    <div className="login-screen-body">
                        <div className="login-screen-body-text1">Вход</div>
                        <br/>
                        <img id={"login-icon"} alt="" src={login_icon}/>
                        <div className="login-screen-body-text2">Электронная почта</div>
                        <Input placeholder='' fluid 
                            id="login-screen-email" value={this.state.login}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                ...this.state,
                                login: data.value
                            })}/>
                        <br/>
                        <img id={"login-password-icon"} alt="" src={login_password_icon}/>
                        <div className="login-screen-body-text3">Пароль</div>
                        <Input iconPosition='left' placeholder='' fluid
                            type={this.state.showPass ? 'text' : 'password'} 
                            id="login-screen-password" value={this.state.pass}
                            name="passInput"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                ...this.state,
                                pass: data.value
                            })}/>

                        <div id={"login-password-show-icon"}>
                            <img alt="" src={login_password_show_icon} onClick={() => {
                                this.setState({ showPass: !this.state.showPass });
                                }}/>
                        </div>
                        <a className="login-screen-body-text4" href='/restorePassword'>Забыли пароль ?</a>
                        {this.props.errorMessage ?
                            <div>
                                <div className={"login-screen-error-text5"}>{this.props.errorMessage}</div>
                                <div className={"login-screen-error-notification-icon"}><img alt="" src={notification_icon}/></div>
                            </div>
                            :
                            <div>&nbsp;</div>
                        }

                        <div className="ui container grid">
                                <Button id="login-screen-login-button" fluid={true} color={"violet"} onClick={this.handleLogin}
                                    type="button">Войти</Button>
                                <Button id="login-screen-to-main-button" fluid={true} to='/' as={Link} onClick={() => {
                                    this.props.clearReduxState();
                                }} >На главный экран</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        auth: state.authentication.isAuthUser,
        counselor: state.authentication.counselor,
        errorMessage: state.authentication.errorMessage,
        authIoProcess: state.authentication.authIoProcess,
        tokenInfo: state.authentication.tokenInfo,
        isTokenInfoProcess: state.authentication.isTokenInfoProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);