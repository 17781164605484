import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { GiftWishHeart } from './GiftWishHeart';
import noImg from "../../images/error.png"

type GiftPosterComponentProp = {
    giftWishOnClick?: Function,
    onClick?: Function,
    giftPhotoUrl: string,
    wished: boolean,
    //режим карточки в магазине
    shopCard: boolean,
    //подсветка картинки на доступность, и градация серого
    pointAvailabilityLighting?: boolean,
    //Баллы
    price?: number
}

type GiftPosterComponentState = {

}

export class GiftPosterComponent extends React.Component<GiftPosterComponentProp, GiftPosterComponentState> {
    constructor(props: GiftPosterComponentProp) {
        super(props);
    }

    getPointAvailabilityLighting() {
        let classResult: string = "";
        classResult += this.props.shopCard ? "gift-poster-rounded" : "";
        if (this.props.pointAvailabilityLighting != undefined)
            classResult += this.props.pointAvailabilityLighting ? " gift-lighting-point-avaliable" : " gift-lighting-point-not-avaliable";

        return <div className={classResult} onClick={(e) => { this.props.onClick?.(e); }} />
    }

    getImage() {
        let classResult: string = "gift-poster-photo";
        //classResult += this.props.shopCard ? " gift-poster-rounded" : "";
        classResult += " gift-poster-rounded";
        if (this.props.pointAvailabilityLighting != undefined)
            classResult += this.props.pointAvailabilityLighting ? "" : " gift-img-point-not-avaliable";

        return <div className={classResult} style={{ backgroundImage: "url(" + this.props.giftPhotoUrl + ")" }}></div>
    }

    render() {
        return (
            <main className={this.props.shopCard ? "gift-poster" : "gift-poster-in-gift-card"}>
                {this.getImage()}
                {this.getPointAvailabilityLighting()}
                <GiftWishHeart insidePoster={!this.props.shopCard} pressed={this.props.wished} onClick={() => { this.props.giftWishOnClick?.(); }} />
                <div className={(this.props.price !== undefined) ? 'gift-text' : ''}>
                    {this.props.price}
                </div>
            </main>
        );
    }
}