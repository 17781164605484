// получение списка заданий
import {ManualStore} from "../manualType";
import manualApi from "../../api/manual";
import {clearReduxStateInternal} from "../authentication/actions";
import {MANUAL_DOWNLOAD_FAILED, MANUAL_DOWNLOAD_PROCESS, MANUAL_DOWNLOAD_SUCCESS} from "./type";

export const download = (manualType: number) => (dispatch: any) => {
    manualApi.download(manualType, (status: number, data: string | undefined) => {
        if (status === 200) {
            dispatch({
                type: MANUAL_DOWNLOAD_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: MANUAL_DOWNLOAD_FAILED,
                errorMessage: data || 'Ошибка скачивания руководства пользователя'
            });
        }
    });

    return dispatch({
        type: MANUAL_DOWNLOAD_PROCESS,
    });
}