import {
    CHATTITLE_GET_SUCCESS,
    CHATTITLE_GET_FAILED,
    CHATTITLE_GET_PROCESS,
    CHATTITLEONE_GET_SUCCESS,

    SELECT_CONVERSATION_SUCCESS,
    CLEAR_CONVERSATION_SUCCESS,

    MEMBERS_GET_SUCCESS,
    MEMBERS_GET_FAILED,
    MEMBERS_GET_PROCESS,

     //Типы для чат сервиса
    CONVERSATION_GET_SUCCESS,
    CONVERSATION_GET_FAILED,
    CONVERSATION_GET_PROCESS,

    //Создать комнату
    CONVERSATION_CREATE_SUCCESS,
    CONVERSATION_CREATE_FAILED,
    CONVERSATION_CREATE_PROCESS,
    //Закрыть комнату
    CONVERSATION_CLOSE_SUCCESS,
    //Типы сообщений по чату
    MESSAGE_GET_SUCCESS,
    MESSAGE_GET_FAILED,
    MESSAGE_GET_PROCESS,
    MESSAGE_SET_SUCCESS,
    MESSAGE_SET_FAILED,
    MESSAGE_SET_PROCESS,
    MESSAGE_GET_UPLOAD_SUCCESS,
    //Hub connection
    HUB_CONNECTION_SUCCESS,
    HUB_CONNECTION_FAILED,
    HUB_CONNECTION_PROCESS,
    HUB_CONNECTION_MESSAGE_SUCCESS,
    HUB_DISCONNECT_SUCCESS,
    HUB_DISCONNECT_FAILED,
    HUB_DISCONNECT_PROCESS,
    //Загрузка файла
    FILE_SET_SUCCESS,
    FILE_SET_FAILED,
    FILE_SET_PROCESS,
    MSSAGE_GET_COUNT_UNREAD_SUCCESS



} from "./types";

import {Reducer} from "redux";
import {ChatStory, ConversationMobileService, Member, ConversationChatService,MessageChatService,FilterMessages} from "../chatType";

const initialState = () => {
    return {
        //Мобильный сервис
        conversationMobileService: [] as ConversationMobileService[],
        chatTitlesInProcess: false,
        chatTitlesError: '',

        //Выбранный чат
        selectConversationMobileService: {} as ConversationMobileService,
        chatMessageInProcess: false,
        chatMessageError: '',
        
        //Учасники для создания чата
        members: [] as Member[],
        chatMemberInProcess: false,
        chatMemberError: '',

        //Модели для чат сервиса
        conversationChatServices: [] as ConversationChatService[],
        conversationInProcess: false,
        conversatioError: '',
        
        //сообщения для чата
        selectMessages: [] as MessageChatService[],
        messageInProcess: false, //Подключение, Отключение, Получение сообщений, Отправка сообщений
        messageError: '',
        currentCreateLinkMessage: '',

        isChatConnected: false,
        countUnread: 0,
        filterMessages: {} as FilterMessages
    } as ChatStory;
};
/* Метод для добавления в одну из коллекции комнату полученной коллекции сообщений
function updateConversationMessages(conversations: ConversationMobileService[], messages: MessageChatService[]) {
    let conversationId = messages[0].id;

    const updatedConversations = conversations.map(conversation => {
      if (conversation.id === conversationId) {
        return { ...conversation, messages };
      }
      return conversation;
    });
    return updatedConversations;
}
*/

const chatStory: Reducer = (state: ChatStory = initialState(), action: any) => {
    switch (action.type) {
        case CHATTITLEONE_GET_SUCCESS : {
            return {
                ...state,
                conversationMobileService: [
                    ...action.data.map((convMobileService: ConversationMobileService) => {
                        const unread = 0;
                        return {
                            ...convMobileService,
                            unread
                        };
                    }),
                    ...state.conversationMobileService],
                chatTitlesInProcess: false
            }
        }
        
        case CHATTITLE_GET_SUCCESS: {
            return {
                ...state,
                conversationMobileService: action.convMobileService.map((convMobileService: ConversationMobileService) => 
                { 
                    const matchingChat = action.convChatService.find((convChatService: ConversationChatService) => convChatService.id === convMobileService.id);
                    if (matchingChat) {
                        const unread = matchingChat.unread;
                        return {
                            ...convMobileService,
                            unread
                        };
                    }else {
                        return convMobileService;
                    }
                
                }),
                chatTitlesInProcess: false       
            };
        }
        case CHATTITLE_GET_FAILED: {
            return {
                ...state,
                chatTitlesInProcess: false,
                chatTitlesError:  action.errorMessage    
            };
        }
        case CHATTITLE_GET_PROCESS: {
            return {
                ...state,
                chatTitlesInProcess: true,  
            };
        }

        case SELECT_CONVERSATION_SUCCESS: {
            return {
                ...state,
                selectConversationMobileService: action.data,      
            };
        }
        case CLEAR_CONVERSATION_SUCCESS: {
            return {
                ...state,
                selectConversationMobileService: action.data,      
            };
        }

        case HUB_CONNECTION_MESSAGE_SUCCESS:
        return {
            ...state,
            isChatConnected: action.data
        }
        case MEMBERS_GET_SUCCESS: {
            return {
                ...state,
                members: action.data, 
                messageInProcess: false,
                chatMemberError: '',     
            };
        }
        case HUB_CONNECTION_FAILED: {
            return {
                ...state,
                messageInProcess: false,
                messageError: '',     
            };
        }
        case HUB_CONNECTION_PROCESS: {
            return {
                ...state,
                messageInProcess: true,        
            };
        }

        case CONVERSATION_GET_SUCCESS: {
            return {
                ...state,
                conversationChatServices: action.data.data,  
                chatTitlesInProcess: false
            };
        }
        case CONVERSATION_GET_FAILED: {
            return {
                ...state,
                conversatioError: action.errorMessage,  
                chatTitlesInProcess: false
            };
        }
        case CONVERSATION_GET_PROCESS: {
            return {
                ...state,    
                chatTitlesInProcess: true
            };
        }
        case CONVERSATION_CREATE_SUCCESS: {
            return {
                ...state,
                conversationChatServices: [...state.conversationChatServices, action.data.payload],  
                chatTitlesInProcess: false
            };
        }
        case CONVERSATION_CREATE_FAILED: {
            return {
                ...state,
                conversatioError: action.errorMessage,  
                chatTitlesInProcess: false
            };
        }
        case CONVERSATION_CLOSE_SUCCESS: {
            return {
                ...state,  
                conversationChatServices: state.conversationChatServices.filter((convChatServ) => 
                    convChatServ.id !== action.data.payload.id
                ),  
                conversationMobileService: state.conversationMobileService.filter((convMobileServ) => 
                convMobileServ.id !== action.data.payload.id
            ),  
                chatTitlesInProcess: false
            };
        }
        case CONVERSATION_CREATE_PROCESS: {
            return {
                ...state,    
                chatTitlesInProcess: true
            };
        }
        case HUB_CONNECTION_SUCCESS: {
            return {
                ...state,
                selectMessages: [action.value, ...state.selectMessages ], 
                conversationMobileService:
               
                state.conversationMobileService.map(convMobileService => {
                    if(convMobileService.id === (action.value as MessageChatService).conversationId 
                    && state.selectConversationMobileService.id !==(action.value as MessageChatService).conversationId){
                        const unread = convMobileService.unread + 1;
                        return {
                            ...convMobileService,
                            unread
                        };
                    }else {
                        return convMobileService;
                    }
                }),
                countUnread: state.countUnread + 1,
                messageInProcess: false
            };
        }
        case MESSAGE_GET_SUCCESS: {
            return {
                ...state,
                conversationMobileService: state.conversationMobileService.map((convMobileService: ConversationMobileService) => {
                    if(convMobileService.id === action.data?.data[0]?.conversationId){
                        const unread = 0;
                        return {
                            ...convMobileService,
                            unread
                        };
                    }else {
                        return convMobileService;
                    }
                }),
                // conversationMobileService: updateConversationMessages(state.conversationMobileService ,action.data.data),   
                selectMessages: action.data.data,
                filterMessages: {conversationId: action.data?.data[0]?.conversationId, 
                                 filter: {
                                    skip: 20,
                                    take: 20,
                                }},
                messageError: '',
                messageInProcess: false
            };
        }
        case MESSAGE_GET_FAILED: {
            return {
                ...state,
                messageError: action.errorMessage,  
                messageInProcess: false
            };
        }
        case MESSAGE_GET_PROCESS: {
            return {
                ...state,    
                messageInProcess: true,
                messageError:''
            };
        }
        case MESSAGE_SET_SUCCESS: {
            return {
                ...state,
                selectMessages: [action.data.payload, ...state.selectMessages],  
                messageInProcess: false
            };
        }
        case MESSAGE_GET_UPLOAD_SUCCESS: {
            return {
                ...state,
                selectMessages: [...state.selectMessages, ...action.data.data],
                
                filterMessages: {conversationId: state.filterMessages.conversationId, 
                    filter: {
                       skip: state.filterMessages.filter.skip+20,
                       take: 20,
                   }},  
                messageInProcess: false
            };
        }
        case MESSAGE_SET_FAILED: {
            return {
                ...state,
                messageError: action.errorMessage,  
                messageInProcess: false
            };
        }
        case MESSAGE_SET_PROCESS: {
            return {
                ...state,    
                messageInProcess: true
            };
        }
        case FILE_SET_SUCCESS: {
            return {
                ...state,  
                messageInProcess: false,
                currentCreateLinkMessage: action.data.id,
                messageError: ''
            };
        }
        case FILE_SET_FAILED: {
            return {
                ...state,
                messageError: action.errorMessage,  
                messageInProcess: false
            };
        }
        case FILE_SET_PROCESS: {
            return {
                ...state,    
                messageInProcess: true,
                messageError:''
            };
        }
        case MSSAGE_GET_COUNT_UNREAD_SUCCESS:
            return {
                ...state,
                countUnread: action.data
            }
        default:
            return state;
    }
};

export default chatStory;