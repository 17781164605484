import axios from "axios";
import {CurrentConfig} from './config';
import {ProcessOperationDto} from "../store/taskType";
import {ListFilter} from "../store/approvingType";

const notificationApi = {
    list: (filter: ListFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/list`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    notificationCount: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/count`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },    
    answerNotification: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/answer`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    viewAnswer: (notificationId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/answerread?notificationId=${notificationId}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    newNotificationCount: (notificationType: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/newNotification/count?type=${notificationType}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    notificationSurveyCount: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/notification/surveycount`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },   
}

export default notificationApi;