import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Icon} from 'semantic-ui-react'
import * as notificationActions from "./../../store/notification/actions"
import {refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './Notification.css';

import {NotificationSend, NotificationStore} from "../../store/notificationType";
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import { CurrentConfig } from '../../api/config';

type NotificationSendState = {
}

type NotificationSendCardProp = {
    backToList: Function,
    selectedSendItem: NotificationSend | undefined, 
}

class NotificationSendCard extends React.Component<NotificationSendCardProp, NotificationSendState> {
    basePath: string = CurrentConfig.get().Api;
    interval: any = undefined;
    
    constructor(props: any) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className={"segment ui notification-card"}>
               
                <div className={"notification-card-description"}>
                    <div className={"notification-card-title"}>{this.props.selectedSendItem?.theme}</div>
                    <div className={"notification-back-button"} onClick={() => {
                        this.props.backToList();
                    }}>
                        <Icon name='arrow alternate circle left outline'/>
                    </div>
                    
                    <div className={"notification-row-label"}>
                        Описание
                    </div>
                    <div className={"notification-name"}>
                        {this.props.selectedSendItem?.text}
                    </div>
                    <div className={"notification-row-label"}>
                        Время
                    </div>
                    <div className={"notification-name"}>
                        {this.props.selectedSendItem?.sendDate}
                    </div>
                    {this.props.selectedSendItem?.fileItem &&
                        <div className={"notification-name"}>
                            <FileViewer url={this.basePath + '/downloadFileById?id=' + this.props.selectedSendItem.fileItem.id} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
function mapStateToProps(state: any) {
    let notificationStore: NotificationStore = state.notification;

    return {
        selectedSendItem: notificationStore.selectedItemSend, 
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(notificationActions, dispatch),
    ...bindActionCreators({refreshUserInfo}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSendCard);